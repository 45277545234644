
import React, { useRef, useState } from 'react'


import { AppDispatch, RootState } from 'src/configureStore'
import { connect, ConnectedProps } from 'react-redux'
import { useEffect } from 'react'
import {
  addQuestion,
  deleteQuestion,
  editQuestion,
  getActiveTeamUsers,
  getQuestionByCat,
  selectFeedbackSettings,
  updateTeamProfile,
} from 'src/redux/teams/actions'
import {
  AddQuestionVariables,
  GetQuestionsByCategoryVariables,
  QuestionByCategory,
  UpdateTeamProfileVariable,
  User,
} from 'src/apiTypes'
import AddNewQuestion from 'src/components/modals/addNewQuestion'


import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import CustomQuestion from 'src/components/team/customQuestion'

import { DragDropContext, Droppable, Draggable, DragDropContextProps } from "react-beautiful-dnd";
import { IMAGE_API_ROOT } from 'src/request'
import _ from 'lodash'


const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  selectedTeam: state.teamReducer.selectedTeam,
  selectedTeamData: state.teamReducer.selectedTeamData,
  questionsByCat: state.teamReducer.questionsByCat,
  feedbackSettingSelection: state.teamReducer.feedbackSettingSelection,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getQuestionByCat: (data: GetQuestionsByCategoryVariables) =>
    dispatch(getQuestionByCat(data)),
  addQuestion: (data: AddQuestionVariables) => dispatch(addQuestion(data)),
  editQuestion: (
    questionId: string,
    question?: string,
    questionOutput?: string,
    order?: number

  ) => dispatch(editQuestion(questionId, question, questionOutput, order)),
  deleteQuestion: (questionId: string) => dispatch(deleteQuestion(questionId)),
  updateTeamProfile: (data: UpdateTeamProfileVariable) =>
    dispatch(updateTeamProfile(data)),
  getActiveUsers: (teamId: string, memberType: string) =>
    dispatch(getActiveTeamUsers(teamId, memberType)),
  selectFeedbackSettings: (data: 'team' | 'p2p' | 'supervisor' | 'subordinate' | 'self') =>
    dispatch(selectFeedbackSettings(data)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  getTeamId(teamId: string): void
}

interface ParamTypes {
  teamId?: string
}



const Feedinput = (props: Props) => {
  const { t } = useTranslation(['feedbackInputPage', 'common'])
  const params = useParams<ParamTypes>()
  const [selectedCategory, selectCategory] = useState<'team' | 'p2p' | 'supervisor' | 'subordinate' | 'self'>('team')
  const {
    selectedTeam,
    questionsByCat,
    getQuestionByCat,
    addQuestion,
    editQuestion,
    deleteQuestion,
    getTeamId,
    feedbackSettingSelection,
    updateTeamProfile,
    getActiveUsers,
    selectedTeamData
  } = props

  const [sortedValueQuestion, setSortedValueQuestions] = useState<QuestionByCategory[]>([])
  const [sortedTextQuestion, setSortedTextQuestions] = useState<QuestionByCategory[]>([])
  const reorder = (list: QuestionByCategory[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }
  const onDragEnd = (result: {
    source: {
      index: number
    },
    destination?: {
      index: number
    },
  }) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    /*     const questionSource = sortedValueQuestion[result.source.index]
        const questionDestination = sortedValueQuestion[result.destination.index] */
    // swtichOrder(questionSource,questionDestination, sortedValueQuestion.length - result.source.index, sortedValueQuestion.length - result.destination.index)

    let items = reorder(
      sortedValueQuestion,
      result.source.index,
      result.destination.index
    )

    items.forEach((item, i) => {
      editQuestion(item._id, undefined, undefined, i + 1)
    })
    setSortedValueQuestions(items)


  }

  /* const swtichOrder = (source: QuestionByCategory, destination: QuestionByCategory, sourceIndex: number, destinationIndex: number) => {
    editQuestion(source._id,undefined,undefined, destinationIndex)
    editQuestion(destination._id,undefined,undefined, sourceIndex)
  } */

  const onTextDragEnd = (result: {
    source: {
      index: number
    },
    destination?: {
      index: number
    },
  }) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }



    let items = reorder(
      sortedTextQuestion,
      result.source.index,
      result.destination.index
    )
    items = items.map((item) => {
      return {
        ...item,
        _id: item._id,
      }
    })
    items.forEach((item, i) => {
      editQuestion(item._id, undefined, undefined, i + 1)
    })

    setSortedTextQuestions(items)
  }

  const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 ${1}em 0`,

    // change background colour if dragging
    //background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  useEffect(() => {
    if (selectedTeam) {
      getActiveUsers(selectedTeam._id, 'teamMembers')
      setTimeout(() => {
        getActiveUsers(selectedTeam._id, 'teamLeaders')
      }, 1000)
    }
  }, [selectedTeam])
  useEffect(() => {
    if (params.teamId) {
      getTeamId(params.teamId)
    }
  }, [params])
  const [openQuestion, setOpenQuestion] = useState<string | null>(null)

  useEffect(() => {
    if (questionsByCat) {
      setSortedTextQuestions(questionsByCat.filter(item => item.type === 'text'))
      setSortedValueQuestions(questionsByCat.filter(item => item.type === 'value'))

    }
  }, [questionsByCat])
  const returnFreqString = () => {
    const returnFreq = () => {
      if (!selectedTeam) {
        return 0
      }
      if (selectedCategory === 'team') {
        return selectedTeam.frequency || 2
      }
      if (selectedCategory === 'self') {
        return selectedTeam.selfFrequency || 2
      }
      if (selectedCategory === 'supervisor') {
        return selectedTeam.tlFrequency || 2
      }
      return 0
    }
    const freq = returnFreq()
    if (freq === 0) {
      return t('common:None')
    }
    if (freq === 1) {
      return t('common:Weekly')
    }
    if (freq === 2) {
      return t('common:Twiceamonth')
    }
    if (freq === 3) {
      return t('common:Everythirdweek')
    }
    if (freq === 4) {
      return t('common:Monthly')
    }
    if (freq < 1) {
      return t('common:Onemin')
    }
    if (freq > 5) {
      return 'None'
    }
  }

  const frequency = returnFreqString()
  const returnFreq = () => {
    if (selectedCategory === 'team') {
      return 'frequency'
    }
    if (selectedCategory === 'self') {
      return 'selfFrequency'
    }
    if (selectedCategory === 'supervisor') {
      return 'tlFrequency'
    }
    return 'frequency'
  }

  const updateFreqString = returnFreq()



  useEffect(() => {
    if (selectedTeam) {
      getQuestionByCat({
        teamId: selectedTeam._id,
        category: selectedCategory,
      })
    }
  }, [selectedTeam, selectedCategory])

  const [ fakeLoading, setFakeLoading ] = useState(false)


  useEffect(() => {
    if (fakeLoading) {
     // setTimeout(() => setFakeLoading(false), 1000 )
    }
  }, [fakeLoading])


  const CategoryReturn = () => {
    if (selectedCategory === 'team') {
      return (
        <>
          <div className="feedback-dropdown-item-icon">
            <i className="icon-users"></i>
          </div>
          <div className="feedback-dropdown-item-set">
            <p className="feedback-dropdown-item-set-title">{t('common:teamFeedback')}</p>
            <p className="feedback-dropdown-item-set-text">{t('common:Feedbackcollectedfromallteammembers')}</p>
          </div>
        </>
      )
    }
    if (selectedCategory === 'p2p') {
      return (
        <>
          <div className="feedback-dropdown-item-icon">
            <i className="icon-users-p2p2"></i>
          </div>
          <div className="feedback-dropdown-item-set">
            <p className="feedback-dropdown-item-set-title">{t('common:p2pFeedback')}</p>
            <p className="feedback-dropdown-item-set-text">{t('common:Feedbackgivenfromteammembertomember')}</p>
          </div>
        </>
      )
    }
    if (selectedCategory === 'self') {
      return (
        <>
          <div className="feedback-dropdown-item-icon">
            <i className="icon-users-self1"></i>
          </div>
          <div className="feedback-dropdown-item-set">
            <p className="feedback-dropdown-item-set-title">{t('common:selfEvaluations')}</p>
            <p className="feedback-dropdown-item-set-text">{t('common:Feedbackgivenfromteammembertohimself')}</p>
          </div>
        </>
      )
    }
    if (selectedCategory === 'subordinate') {
      return (
        <>
          <div className="feedback-dropdown-item-icon">
            <i className="icon-users-leader"></i>
          </div>
          <div className="feedback-dropdown-item-set">
            <p className="feedback-dropdown-item-set-title">{t('common:Feedbackabouttheteamleader')}</p>
            <p className="feedback-dropdown-item-set-text">{t('common:Feedbackfromtheteammemberstotheteamleader')}</p>
          </div>
        </>
      )

    }
    if (selectedCategory === 'supervisor') {
      return (
        <>
          <div className="feedback-dropdown-item-icon">
            <i className="icon-users-supervisor1"></i>
          </div>
          <div className="feedback-dropdown-item-set">
            <p className="feedback-dropdown-item-set-title">{t('common:Supervisorfeedback')}</p>
            <p className="feedback-dropdown-item-set-text">{t('common:Feedbackfromtheteamleadertotheteammembers')}</p>
          </div>
        </>
      )

    }
    return null
  }

  const [previewUser, setPreviewUser] = useState<User | null>(null)
  const { user } = props
  const previewUserLogic = () => {
    if (selectedCategory === 'self') {
      setPreviewUser(user)
    }
    if (/p2p|subordinate/.test(selectedCategory)) {
      if (!selectedTeamData) return
      const teamMembers = selectedTeamData.teamMembers?.sort((a) => {
        if (a.profilePic) {
          return 1
        }
        return 0
      })
      if (teamMembers?.length) {
        setPreviewUser(teamMembers[0])
      }
      return
    }
    if (/supervisor/.test(selectedCategory)) {
      if (!selectedTeamData) return
      const teamMembers = selectedTeamData.teamLeaders?.sort((a) => {
        if (a.profilePic) {
          return 1
        }
        return 0
      })
      if (teamMembers?.length) {
        setPreviewUser(teamMembers[0])
      }
      return
    }
  }

  useEffect(() => {
    previewUserLogic()
  }, [selectedCategory, selectedTeamData])


  function scrollOnFocus(item: any) {
    alert(item)
  }

  const [open, setOpen] = useState(false)
  const drowdownEl = useRef(null)

  useOnClickOutside(drowdownEl, () => setOpen(false))

  function handleToggle() {
    setOpen(true)
  }



  return (
    <>
      <div
        className="content-wrapper"
        onClick={() =>
          document.getElementById('mainDrawer')?.classList.remove('open')
        }
      >
        <div className="content">
          <div className="content-card">
            <div className="content-card-header-caption-wrapper">
              <div className="content-card-header-caption">
                <p className="content-card-header-caption-title">{t('common:Feedbackinput')}</p>
                <p className="content-card-header-caption-text">
                  {t('feedbackInputPage:Thistheplacetocreate')}.
                  </p>
              </div>
            </div>

            <div className="content-card-header">


              <p className="content-card-label" style={{ paddingLeft: "0", paddingRight: "0", marginBottom: ".8rem" }}>{t('feedbackInputPage:Feedbackcategory')}</p>
              <div className="content-card-header-bottom">
                <div className={`custom-dropdown feedback-dropdown ${open && 'open'}`} ref={drowdownEl} onClick={handleToggle}>
                  <div className="custom-dropdown-trigger" onClick={(e) => e.currentTarget.focus()} tabIndex={-1}>
                    <div className="feedback-dropdown-item">
                      <CategoryReturn />

                      <i className="icon-chevron-down"></i>
                    </div>
                  </div>

                  <div className="custom-dropdown-menu" >
                    <div className="feedback-dropdown-item" onClick={() => selectCategory('p2p')}>
                      <div className="feedback-dropdown-item-icon">
                        <i className="icon-users-p2p2"></i>
                      </div>
                      <div className="feedback-dropdown-item-set">
                        <p className="feedback-dropdown-item-set-title">{t('common:p2pFeedback')}</p>
                        <p className="feedback-dropdown-item-set-text">{t('common:Feedbackgivenfromteammembertomember')}</p>
                      </div>
                    </div>

                    <div className="feedback-dropdown-item" onClick={() => selectCategory('self')}>
                      <div className="feedback-dropdown-item-icon">
                        <i className="icon-users-self1"></i>
                      </div>
                      <div className="feedback-dropdown-item-set">
                        <p className="feedback-dropdown-item-set-title">{t('common:selfEvaluations')}</p>
                        <p className="feedback-dropdown-item-set-text">{t('common:Feedbackgivenfromteammembertohimself')}</p>
                      </div>
                    </div>

                    <div className="feedback-dropdown-item" onClick={() => selectCategory('subordinate')}>
                      <div className="feedback-dropdown-item-icon">
                        <i className="icon-users-leader"></i>
                      </div>
                      <div className="feedback-dropdown-item-set">
                        <p className="feedback-dropdown-item-set-title">{t('common:Feedbackabouttheteamleader')}</p>
                        <p className="feedback-dropdown-item-set-text">{t('common:Feedbackfromtheteammemberstotheteamleader')}</p>
                      </div>
                    </div>

                    <div className="feedback-dropdown-item" onClick={() => selectCategory('supervisor')}>
                      <div className="feedback-dropdown-item-icon">
                        <i className="icon-users-supervisor1"></i>
                      </div>
                      <div className="feedback-dropdown-item-set">
                        <p className="feedback-dropdown-item-set-title">{t('common:Supervisorfeedback')}</p>
                        <p className="feedback-dropdown-item-set-text">{t('common:Feedbackfromtheteamleadertotheteammembers')}</p>
                      </div>
                    </div>
                    <div className="feedback-dropdown-item" onClick={() => selectCategory('team')}>
                      <div className="feedback-dropdown-item-icon">
                        <i className="icon-users"></i>
                      </div>
                      <div className="feedback-dropdown-item-set">
                        <p className="feedback-dropdown-item-set-title">{t('common:teamFeedback')}</p>
                        <p className="feedback-dropdown-item-set-text">{t('common:Feedbackcollectedfromallteammembers')}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="custom-dropdown frequency-box-dropdown">
                  <button className="custom-dropdown-trigger" onClick={(e) => e.currentTarget.focus()} tabIndex={-1}>
                    <div className="custom-dropdown-trigger-icon"><i className="icon-calendar"></i></div>
                    <p className="custom-dropdown-trigger-value">{frequency}</p>
                  </button>

                  <div className="custom-dropdown-menu">
                    <div className="custom-dropdown-menu-item" onClick={(e) => {
                      if (selectedTeam) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          [updateFreqString]: 1,
                        })
                      }
                      e.currentTarget.focus()
                    }} >
                      <button className="custom-dropdown-menu-item-link">Weekly</button>
                    </div>

                    <div className="custom-dropdown-menu-item" onClick={(e) => {
                      if (selectedTeam) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          [updateFreqString]: 2
                        })
                      }
                      e.currentTarget.focus()
                    }} >
                      <button className="custom-dropdown-menu-item-link">2 weeks</button>
                    </div>

                    <div className="custom-dropdown-menu-item" onClick={(e) => {
                      if (selectedTeam) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          [updateFreqString]: 3
                        })
                      }
                      e.currentTarget.focus()
                    }}>
                      <button className="custom-dropdown-menu-item-link" >3 weeks</button>
                    </div>

                    <div className="custom-dropdown-menu-item" onClick={(e) => {
                      if (selectedTeam) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          [updateFreqString]: 4,
                        })
                      }
                      e.currentTarget.focus()
                    }} >
                      <button className="custom-dropdown-menu-item-link">Monthly</button>
                    </div>
                    <div className="custom-dropdown-menu-item" onClick={(e) => {
                      if (selectedTeam) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          [updateFreqString]: 1000,
                        })
                      }
                      e.currentTarget.focus()
                    }} >
                      <button className="custom-dropdown-menu-item-link">Off</button>
                    </div>
                  </div>
                </div>
                <button
                  style={{ textTransform: 'uppercase' }}
                  className={`button button-green-primary ${false && 'animate__animated animate__pulse animate__infinite	infinite'}`}
                  onClick={() => {
                    if (selectedTeam) {
                      document
                        .getElementById('newQuestionModal')
                        ?.classList.add('open')
                    }
                  }}
                >
                  {t('feedbackInputPage:ADDNEWQUESTION')}
                </button>
                {/*<div className="options">
                  <button
                    className={`options-link ${category === 'team' ? 'active' : ''
                      }`}
                    onClick={() => {
                      if (selectedTeam) {
                        getQuestionByCat({
                          teamId: selectedTeam._id,
                          category: 'team',
                        })
                        selectCategory('team')
                      }
                    }}
                  >
                    {t("common:teamFeedback")}
                  </button>
                  <span className="options-divider">I</span>
                  <button
                    className={`options-link ${category === 'p2p' ? 'active' : ''
                      }`}
                    onClick={() => {
                      if (selectedTeam) {
                        getQuestionByCat({
                          teamId: selectedTeam._id,
                          category: 'p2p',
                        })
                        selectCategory('p2p')
                      }
                    }}
                  >
                    {' '}
                    {t("common:p2pFeedback")}
                  </button>
                  <span className="options-divider">I</span>
                  <button
                    className={`options-link ${category === 'supervisor' ? 'active' : ''
                      }`}
                    onClick={() => {
                      if (selectedTeam) {
                        getQuestionByCat({
                          teamId: selectedTeam._id,
                          category: 'supervisor',
                        })
                        selectCategory('supervisor')
                      }
                    }}
                  >
                    {t("common:supervisoryFeedback")}
                  </button>
                  <span className="options-divider">I</span>
                  <button
                    onClick={() => {
                      if (selectedTeam) {
                        getQuestionByCat({
                          teamId: selectedTeam._id,
                          category: 'subordinate',
                        })
                        selectCategory('subordinate')
                      }
                    }}
                    className={`options-link ${category === 'subordinate' ? 'active' : ''
                      }`}
                  >
                    {t("common:subordinateFeedback")}
                  </button>
                  <span className="options-divider">I</span>
                  <button
                    className={`options-link ${category === 'self' ? 'active' : ''
                      }`}
                    onClick={() => {
                      if (selectedTeam) {
                        getQuestionByCat({
                          teamId: selectedTeam._id,
                          category: 'self',
                        })
                        selectCategory('self')
                      }
                    }}
                  >
                    {t("common:selfEvaluation")}
                  </button>
                </div>*/}
              </div>
            </div>
            <p className="content-card-label">{t('feedbackInputPage:Starquestions')}</p>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <div className="custom-questions custom-questions-stars custom-accordion" {...provided.droppableProps}
                    ref={provided.innerRef}>


                    {sortedValueQuestion.map((question, i) => (
                      <Draggable
                        key={String(question._id)}
                        draggableId={String(question._id)}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >

                            <CustomQuestion
                              scrollOnFocus={scrollOnFocus}
                              deleteQuestion={() => deleteQuestion(question._id)}
                              editQuestion={(q, qo) => {
                                if (question.level !== 'company') {

                                  editQuestion(question._id, q, qo)
                                  
    
                                  
                                }
                              }}
                              open={openQuestion === question._id}
                              question={question}
                              onOpen={() => {
                                if (openQuestion === question._id) {
                                  setOpenQuestion(null)
                                } else {
                                  setOpenQuestion(question._id)
                                }
                              }}
                            />
                          </div>)}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>)}
              </Droppable>
            </DragDropContext>
            <p className="content-card-label">{t('feedbackInputPage:Textquestions')}</p>



            <DragDropContext onDragEnd={onTextDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <div className="custom-questions custom-questions-text custom-accordion" {...provided.droppableProps}
                    ref={provided.innerRef}>


                    {sortedTextQuestion.map((question, i) => (
                      <Draggable
                        key={String(question._id)}
                        draggableId={String(question._id)}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >

                            <CustomQuestion
                              scrollOnFocus={scrollOnFocus}
                              deleteQuestion={() => deleteQuestion(question._id)}
                              editQuestion={(q, qo) => {
                                if (question.level !== 'company') {

                                  editQuestion(question._id, q, qo)
                                }
                              }}
                              open={openQuestion === question._id}
                              question={question}
                              onOpen={() => {
                                if (openQuestion === question._id) {
                                  setOpenQuestion(null)
                                } else {
                                  setOpenQuestion(question._id)
                                }
                              }}
                            />
                          </div>)}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>)}
              </Droppable>
            </DragDropContext>
            {/*<button
              className="button button-primary add-custom-question-button modal-trigger"
              target-modal="newQuestionModal"
              onClick={() => {
                if (selectedTeam) {
                  document
                    .getElementById('newQuestionModal')
                    ?.classList.add('open')
                }
              }}
            >
              {t("feedbackInputPage:Addanewquestion")}
            </button>*/}
            {/* {/team|self|supervisor/.test(feedbackSettingSelection) && (
              <>
                {selectedTeam && (selectedTeam[updateFreqString] || 1) < 5 && (
                  <div className="settings-section">
                    <div className="settings-section-set">
                      <p className="settings-section-set-title">{t('feedbackInputPage:Frequency')}</p>
                      <p className="settings-section-set-text">
                        {t('feedbackInputPage:Selecthowoftentheteamfeedback')}
                      </p>
                    </div>
                    <div className="custom-dropdown">
                      <div className="custom-dropdown-trigger" tabIndex={-1}>
                        <p className="value">{frequency}</p>
                        <i className="icon-caret-down"></i>

                        <div className="tutor-tooltip tutor-tooltip-frequency-dropdown absolute">
                          <div className="tutor-tooltip-arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                              <path
                                d="M9.821,1.5a1.5,1.5,0,0,1,2.359,0l7.914,10.072A1.5,1.5,0,0,1,18.914,14H3.086a1.5,1.5,0,0,1-1.179-2.427Z"
                                fill="#1177ef" />
                            </svg>
                          </div>
                          <div className="tutor-tooltip-main">
                            <p className="tutor-tooltip-main-title">Frequency</p>
                            <p className="tutor-tooltip-main-text">Here you can select how often this feedback should be collected
                      in your team.</p>
                          </div>
                        </div>
                      </div>
                      <div className="custom-dropdown-menu">
                        <div className="custom-dropdown-menu-item">
                          <button
                            onClick={() => {
                              if (selectedTeam) {
                                updateTeamProfile({
                                  teamId: selectedTeam._id,
                                  [updateFreqString]: 1,
                                })
                              }
                            }}
                          >
                            {t('common:Weekly')}
                          </button>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <button
                            onClick={() => {
                              if (selectedTeam) {
                                updateTeamProfile({
                                  teamId: selectedTeam._id,
                                  [updateFreqString]: 2,
                                })
                              }
                            }}
                          >
                            {t('common:Twiceamonth')}
                          </button>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <button
                            onClick={() => {
                              if (selectedTeam) {
                                updateTeamProfile({
                                  teamId: selectedTeam._id,
                                  [updateFreqString]: 3,
                                })
                              }
                            }}
                          >
                            {t('common:Everythirdweek')}
                          </button>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <button
                            onClick={() => {
                              if (selectedTeam) {
                                updateTeamProfile({
                                  teamId: selectedTeam._id,
                                  [updateFreqString]: 4,
                                })
                              }
                            }}
                          >
                            {t('common:Monthly')}
                          </button>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <button
                            onClick={() => {
                              if (selectedTeam) {
                                updateTeamProfile({
                                  teamId: selectedTeam._id,
                                  [updateFreqString]: 0.000099,
                                })
                              }
                            }}
                          >
                            {t('common:Onemin')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="settings-section">
                  <div className="settings-section-set">
                    <p className="settings-section-set-title">{t('common:Status')}</p>
                    <p className="settings-section-set-text">
                      {t('common:Hereyoucanactivateteamfeedback')}{' '}
                    </p>
                  </div>
                  <div className="option-buttons">
                    <button
                      onClick={() => {
                        if (selectedTeam) {
                          updateTeamProfile({
                            teamId: selectedTeam._id,
                            [updateFreqString]: 2,
                          })
                        }
                      }}
                      className={`button ${selectedTeam &&
                        (selectedTeam[updateFreqString] || 5) < 5
                        ? 'active'
                        : ''
                        }`}
                    >
                      {t('common:Active')}
                    </button>
                    <button
                      onClick={() => {
                        if (selectedTeam) {
                          updateTeamProfile({
                            teamId: selectedTeam._id,
                            [updateFreqString]: 9999,
                          })
                        }
                      }}
                      className={`button ${selectedTeam &&
                        (selectedTeam[updateFreqString] || 5) > 4
                        ? 'active'
                        : ''
                        }`}
                    >
                      {t('common:Disable')}
                    </button>
                  </div>
                </div>
              </>
            )} */}
          </div>

          <div className="preview">
            <div className="phone">

              <img src="/img/mobile-frame.png" alt="Mobile phone frame" className="phone-frame" />
              <div className="phone-content">
                <div className="phone-content-header">
                  {selectedCategory === 'team' ? <div className="phone-content-header-icon"><i className="icon-users"></i></div>
                    :
                    previewUser?.profilePic ?

                      <img src={IMAGE_API_ROOT + previewUser.profilePic} alt="User" className="phone-content-header-img" />
                      : <div className="phone-content-header-initials">
                        {previewUser?.firstname ? (previewUser?.firstname[0] + previewUser?.lastname[0]) : ''}
                      </div>
                  }

                  <div className="phone-content-header-set">
                    <p className="phone-content-header-set-subtitle">{t('common:Givefeedback')}{' '}{selectedCategory === 'team' && 'to'}</p>
                    <p className="phone-content-header-set-title">{selectedCategory === 'team' ? selectedTeam?.name : previewUser?.firstname + ' ' + previewUser?.lastname}</p>
                  </div>
                </div>

                <div className="phone-content-body">
                  <div className="ratings">
                    {sortedValueQuestion.map(item =>
                      <div className="rating" id={`${item._id}`} key={item._id}>
                        {<p className="rating-title">{item.question}</p>}
                        <div className="stars">
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                          <i className="icon-star"></i>
                        </div>
                      </div>
                    )}

                  </div>

                  <div className="text-inputs">
                    {sortedTextQuestion.map(item =>
                      <div className="phone-content-body-input" id={`${item._id}`} key={item._id}>
                        <p className="phone-content-body-input-text">{item.question}</p>
                      </div>
                    )}

                  </div>

                  <div className="phone-content-body-button">{t('common:Send')}</div>
                </div>

              </div>
            </div>
          </div>
        </div>
        {fakeLoading && <p style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}>loading</p>}
      </div>
      <AddNewQuestion
        category={selectedCategory}
        teamName={selectedTeam ? selectedTeam.name : ''}
        addQuestion={(type: 'value' | 'text', question: string) => {
          if (selectedTeam) {
            addQuestion({
              teamId: selectedTeam._id,
              category: selectedCategory,
              type,
              question,
            })
          }
        }}
      />
    </>
  )
}

// Hook
function useOnClickOutside(ref: any, handler: any) {
  useEffect(
    () => {
      //@ts-ignore
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export default connector(Feedinput)
