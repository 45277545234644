import React, { FC, useEffect, useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  CreateTeamVariables,
  InviteUserVariables,
  User,
  UserRights,
} from "src/apiTypes"
import { AppDispatch, RootState } from "src/configureStore"
import {
  addToTeam,
  createTeam,
  getMyTeams,
  getTeamById,
  getUserSuggestion,
  inviteUser,
} from "src/redux/teams/actions"
import { IMAGE_API_ROOT } from "src/request"
import {
  capitalize,
  colorByName,
  extractNameFromEmail,
  validateEmail,
} from "src/utitlity"
import Loader from 'src/components/loaders/signinLoader'
import ButtonLoader from 'src/components/loaders/blueButtonLoader'
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state: RootState) => ({
  createdTeam: state.teamReducer.createdTeam,
  loading: state.teamReducer.loading,
  userSuggestions: state.teamReducer.userSuggestions,
  selectedTeam: state.teamReducer.selectedTeam,
  user: state.authReducer.user,
  createTeamError: state.teamReducer.createTeamError,
  sigininin: state.authReducer.sigininin,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createTeam: (data: CreateTeamVariables) => dispatch(createTeam(data)),
  getMyTeams: () => dispatch(getMyTeams()),
  //clearTeamData: (what: string) => dispatch(clearTeamData(what)),
  addToTeam: (
    data: {
      teamId: string
      userId: string
      type: UserRights
    },
    user: User
  ) => dispatch(addToTeam(data, user)),
  getUserSuggestion: (data: { teamId: string; keyword: string }) =>
    dispatch(getUserSuggestion(data)),
  getTeamById: (teamId: string) => dispatch(getTeamById(teamId)),
  inviteUser: (data: InviteUserVariables) => dispatch(inviteUser(data)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {}

const CreateNewTeam: FC<Props> = (props) => {
  const { t } = useTranslation(['createJoinCompany', 'common']);
  const [activeCard, setActiveCard] = useState<
    "createTeam" | "addTeamMembers" | "addTeamLeaders" | "success"
  >("createTeam")

  const [checked, setChecked] = useState(true)

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [keyword, setKeyword] = useState("")
  const [selectedUsers, selectUser] = useState<User[]>([])
  const [emails, setEmails] = useState<string[]>([])
  const [leadrEmails, setLeaderEmails] = useState<string[]>([])
  const [selectedLeaders, selectLeaders] = useState<User[]>([])
  const { push } = useHistory()
  const [ showSuccess, setShowSuccess ] = useState(false)
  
  useEffect(() => {
    const href = window.location.href
    var url = new URL(href)
    if (url.searchParams.get('showSuccess')) {
      setShowSuccess(true)
    }
  })
  const {
    userSuggestions,
    getUserSuggestion,
    selectedTeam,
    createTeam,
    createdTeam,
    getMyTeams,
    inviteUser,
    addToTeam,
    user,
    sigininin,
  } = props
  function handleCardChange(
    card: "createTeam" | "addTeamMembers" | "addTeamLeaders" | "success"
  ) {
    setActiveCard(card)
  }

  const [addLoader, setAddLoader] = useState(false);


  useEffect(() => {
    if (createdTeam) {
      if (!createdTeam.demo) {
        setActiveCard("success")
        if (selectedUsers.length) {
          selectedUsers.forEach((member) => {
            addToTeam(
              {
                teamId: createdTeam._id,
                userId: member._id,
                type: "teamMembers",
              },
              member
            )
          })
          // document.getElementById("createNewTeamModal")?.classList.remove("open")
          selectUser([])
        }
      } else {
        push('/team/' + createdTeam._id + '/data/teamFeedback')
      }

      /* 
      if (!selectedUsers.length) {
        document.getElementById('createNewTeamModal')?.classList.remove('open')
      } */
      //   getMyTeams()
      /* setTimeout(
        () => push('/team/' + createdTeam._id + '/data/teamFeedback'),
        1000
      ) */
      emails.forEach((email) => {
        inviteUser({
          teamId: createdTeam._id,
          invitedUser: email,
          type: "Team member",
        })
      })
      leadrEmails.forEach((email) => {
        inviteUser({
          teamId: createdTeam._id,
          invitedUser: email,
          type: "Team leader",
        })
      })
      setName("")
      setEmails([])
      setKeyword("")
      setDescription("")
    }
  }, [createdTeam])


  const [inputFocused, setInputFocused] = useState(false)
  return (
    <div className={`content-wrapper`}>
      <div className="content">
        {activeCard === "createTeam" && (
          <div className="content-card card-06">
            <div className="content-card-header">
              <p className="content-card-header-title">{t('common:Createyourfirstteam')}</p>
              <p className="content-card-header-subtitle">
                {t('common:Pleasegiveyourteamanameandandadescription')}.
              </p>
            </div>

            <div className="content-card-main">
              <p className="content-card-main-label">{t('common:Teamname')}</p>
              <div className="input-container">
                <input
                  value={name}
                  type="text"
                  placeholder={`${t('common:Enterteamname')}`}
                  onChange={(e) => {
                    if (e.target.value.length <= 40) {
                      setName(capitalize(e.target.value))
                    }
                  }}
                />
                <span>
                  <p>{40 - name.length}</p>
                </span>
              </div>

              <p className="content-card-main-label">
                {t('common:Teamdescription')}{' '}<span>{`(${t('common:optional')})`}</span>
              </p>
              <div className="input-container">
                <input
                  value={description}
                  type="text"
                  placeholder={`${t('common:Enterteamdescription')}`}
                  onChange={(e) => {
                    if (e.target.value.length <= 60) {
                      setDescription(capitalize(e.target.value))
                    }
                  }}
                />
                <span>
                  <p>{60 - description.length}</p>
                </span>
              </div>

              {/* <div className="content-card-main-check">
                                <div className="content-card-main-check-set">
                                    <p className="content-card-main-check-set-title">Make me team leader of this team</p>
                                    <p className="content-card-main-check-set-text">
                                        Every team needs at least one team leader. Keep
                                        this button enabled if you want to be the leader.
                                                </p>
                                </div>

                                <div className="pretty p-switch p-fill" onClick={(e) => setChecked(!checked)}>
                                    <input type="checkbox" checked={checked} />
                                    <div className="state p-success">
                                        <label>&nbsp;</label>
                                    </div>
                                </div>
                            </div> */}

              <button
                className="button button-green-primary"
                onClick={() => {
                  if (name) {
                    handleCardChange("addTeamMembers")
                  } else {
                    window.alert("Please enter your team name.")
                  }
                }}
              >
                {t('common:Registernewteam')}{sigininin && (<span style={{ marginLeft: '9px' }}><Loader /></span>)}
              </button>
              <p className="content-card-main-text-small"><button className="regular">{t('common:Quitteamcreation')}</button></p>
            </div>
          </div>
        )}

        {/* CREATE YOUR FIRST TEAM CARD-07 */}
        {/* CURRENT 7 NEEDS 7 */}
        {activeCard === "addTeamMembers" && (
          <div className="content-card card-07">
            <div className="content-card-header">
              <p className="content-card-header-title">{t('common:Createyourfirstteam')}</p>
              <p className="content-card-header-subtitle">
                {t('createJoinCompany:TeamFeedbackisnothingwithoutateam')}.
              </p>
            </div>

            <div className="content-card-main">
              <div className="input-set">
                <p className="content-card-main-label">{t('common:Addteammembers')}</p>

                <div className="search-dropdown">
                  <div className="search-dropdown-input-container show-button">
                    <input
                      onFocus={() => setInputFocused(true)}
                      onBlur={() => {
                        setTimeout(() => setInputFocused(false), 1000)
                      }}
                      type="text"
                      value={keyword}
                      placeholder={`${t('common:Searchorenteremail')}`}
                      onChange={(e) => {
                        if (selectedTeam) {
                          getUserSuggestion({
                            teamId: selectedTeam._id,
                            keyword: e.target.value,
                          })
                        }
                        setKeyword(e.target.value)
                      }}
                    />
                    <i className="icon-search"></i>
                    <button
                      className="button button-add"
                      onClick={() => {
                        setAddLoader(true)
                        setTimeout(function () {
                          setAddLoader(false)
                        }, 500)
                        if (validateEmail(keyword)) {
                          const oldEmails = [...emails]
                          oldEmails.push(keyword)
                          setEmails(oldEmails)
                          setKeyword("")
                        } else {
                          window.alert("Invalid email")
                        }
                      }}
                    >
                      {addLoader ? <ButtonLoader /> : `${t('common:Add')}`}
                    </button>
                  </div>
                  {inputFocused && !!userSuggestions?.length && (
                    <div
                      className="search-dropdown-menu"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      {userSuggestions
                        .filter((item) => {
                          const leaderAdded = selectedUsers.find(
                            (lead) => lead._id === item._id
                          )
                          if (!leaderAdded) {
                            return true
                          }
                        })
                        .map((member) => (
                          <div
                            className="member"
                            key={member._id}
                            onClick={() => {
                              const oldUsers = [...selectedUsers]
                              oldUsers.push(member)
                              selectUser(oldUsers)
                              setKeyword("")
                            }}
                          >
                            {member.profilePic ? (
                              <img
                                src={IMAGE_API_ROOT + member.profilePic}
                                alt="User's profile image"
                                className="member-img"
                              />
                            ) : (
                              <div
                                className="member-initials"
                                style={{
                                  backgroundColor: colorByName(
                                    member.firstname + " " + member.lastname
                                  ),
                                }}
                              >
                                {member.firstname[0] + member.lastname[0]}
                              </div>
                            )}

                            <div className="member-set">
                              <p className="member-set-name">
                                {member.firstname + " " + member.lastname}
                              </p>
                              <p className="member-set-position">
                                {member.jobtitle || "Job title missing"}
                              </p>
                            </div>
                            <button className="button select">{t('common:Select')}</button>
                          </div>
                        ))}
                    </div>
                  )}
                  <div className="search-dropdown-menu">
                    <div className="member">
                      <img
                        src="/img/p-01.png"
                        alt="User's profile image"
                        className="member-img"
                      />
                      <div className="member-set">
                        <p className="member-set-name">Marko Rajkovic</p>
                        <p className="member-set-position">Example Job Title</p>
                      </div>
                      <button className="button select">{t('common:Select')}</button>
                    </div>
                    <div className="member">
                      <div className="member-initials">MR</div>
                      <div className="member-set">
                        <p className="member-set-name">Marko Rajkovic</p>
                        <p className="member-set-position">Example Job Title</p>
                      </div>
                      <button className="button select">{t('common:Select')}</button>
                    </div>
                  </div>
                </div>
              </div>

              {(!!selectedUsers?.length || !!emails.length) && (
                <div className="horizontal-divider"></div>
              )}

              <div className="scrollable">
                <div className="members">
                  {selectedUsers
                    ? selectedUsers.map((user) => (
                      <div className="member" key={user._id + "mem"}>
                        {/* <img src="/img/p-01.png" alt="User's profile image" className="member-img" />*/}
                        {user.profilePic ? (
                          <img
                            src={IMAGE_API_ROOT + user.profilePic}
                            alt="User's profile image"
                            className="member-img"
                          />
                        ) : (
                          <div
                            className="member-initials"
                            style={{
                              backgroundColor: colorByName(
                                user.firstname + " " + user.lastname
                              ),
                            }}
                          >
                            {user.firstname[0] + user.lastname[0]}
                          </div>
                        )}

                        <div className="member-set">
                          <p className="member-set-name">
                            {user.firstname + " " + user.lastname}
                          </p>
                          <p className="member-set-position">
                            {user.jobtitle || "Job title missing"}
                          </p>
                        </div>
                        <button
                          className="button delete"
                          onClick={() => {
                            const oldUsers = [...selectedUsers].filter(
                              (item) => String(item._id) !== String(user._id)
                            )

                            selectUser(oldUsers)
                          }}
                        >
                          {t('common:Delete')}
                        </button>
                      </div>
                    ))
                    : "test"}

                  {emails.map((email, i) => (
                    <div className="member" key={email + i}>
                      {/* <img src="/img/p-01.png" alt="User's profile image" className="member-img" />*/}

                      <div
                        className="member-initials"
                        style={{
                          backgroundColor: colorByName(email),
                        }}
                      >
                        {extractNameFromEmail(email).initial}
                      </div>

                      <div className="member-set">
                        <p className="member-set-name">
                          {extractNameFromEmail(email).name}
                        </p>
                        <p className="member-set-position">{email}</p>
                      </div>
                      <button
                        className="button delete"
                        onClick={() => {
                          const oldEmails = [...emails].filter(
                            (item) => item !== email
                          )

                          setEmails(oldEmails)
                        }}
                      >
                        {t('common:Delete')}
                      </button>
                    </div>
                  ))}
                  {/*< div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
 */}
                </div>

                <div className="content-card-main-buttons" style={{ marginBottom: "2em" }}>
                  <button
                    className="go-back"
                    onClick={() => handleCardChange("createTeam")}
                  >
                    <i className="icon-arrow-left"></i>{' '}{t('common:GoBack')}
                  </button>
                  <button
                    className="button button-green-primary"
                    onClick={() => {
                      createTeam({
                        name,
                        description,
                        // autoAddDisabled: true
                      })
                    }}
                  >
                    {t('common:Registernewteam')}{sigininin && (<span style={{ marginLeft: '9px' }}><Loader /></span>)}
                  </button>

                </div>
                <p className="content-card-main-text-small" style={{ textAlign: "center" }}><button className="regular">{t('common:Skipthisstepandinvitelater')}</button></p>
              </div>
            </div>
          </div>
        )}
        {activeCard === "addTeamLeaders" && (
          <div className="content-card card-07">
            <div className="content-card-header">
              <p className="content-card-header-title">
                C{t('common:Createyourfirstteam')}
              </p>
              <p className="content-card-header-subtitle">
                {t('createJoinCompany:TeamFeedbackisnothingwithoutateam')}.
              </p>
            </div>

            <div className="content-card-main">
              <div className="input-set">
                <p className="content-card-main-label">{t('common:Addteamleaders')}</p>

                <div className="search-dropdown">
                  <div className="search-dropdown-input-container show-button">
                    <input type="text" placeholder="Search or enter email" />
                    <i className="icon-search"></i>
                    <button className="button button-add">{t('common:Add')}</button>
                  </div>

                  <div className="search-dropdown-menu">
                    <div className="member">
                      <img
                        src="/img/p-01.png"
                        alt="User's profile image"
                        className="member-img"
                      />
                      <div className="member-set">
                        <p className="member-set-name">Marko Rajkovic</p>
                        <p className="member-set-position">Example Job Title</p>
                      </div>
                      <button className="button select">{t('common:Select')}</button>
                    </div>
                    <div className="member">
                      <div className="member-initials">MR</div>
                      <div className="member-set">
                        <p className="member-set-name">Marko Rajkovic</p>
                        <p className="member-set-position">Example Job Title</p>
                      </div>
                      <button className="button select">{t('common:Select')}</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="horizontal-divider"></div>

              <div className="scrollable">
                <div className="members">
                  <div className="member">
                    <img
                      src="/img/p-01.png"
                      alt="User's profile image"
                      className="member-img"
                    />
                    <div className="member-set">
                      <p className="member-set-name">Marko Rajkovic</p>
                      <p className="member-set-position">Example Job Title</p>
                    </div>
                    <button className="button delete">{t('common:Delete')}</button>
                  </div>
                  <div className="member">
                    <div className="member-initials">MR</div>
                    <div className="member-set">
                      <p className="member-set-name">Marko Rajkovic</p>
                      <p className="member-set-position">Example Job Title</p>
                    </div>
                    <button className="button delete">{t('common:Delete')}</button>
                  </div>
                  <div className="member">
                    <img
                      src="/img/p-01.png"
                      alt="User's profile image"
                      className="member-img"
                    />
                    <div className="member-set">
                      <p className="member-set-name">Marko Rajkovic</p>
                      <p className="member-set-position">Example Job Title</p>
                    </div>
                    <button className="button delete">Delete</button>
                  </div>
                  <div className="member">
                    <div className="member-initials">MR</div>
                    <div className="member-set">
                      <p className="member-set-name">Marko Rajkovic</p>
                      <p className="member-set-position">Example Job Title</p>
                    </div>
                    <button className="button delete">{t('common:Delete')}</button>
                  </div>
                  <div className="member">
                    <img
                      src="/img/p-01.png"
                      alt="User's profile image"
                      className="member-img"
                    />
                    <div className="member-set">
                      <p className="member-set-name">Marko Rajkovic</p>
                      <p className="member-set-position">Example Job Title</p>
                    </div>
                    <button className="button delete">{t('common:Delete')}</button>
                  </div>
                  <div className="member">
                    <div className="member-initials">MR</div>
                    <div className="member-set">
                      <p className="member-set-name">Marko Rajkovic</p>
                      <p className="member-set-position">Example Job Title</p>
                    </div>
                    <button className="button delete">{t('common:Delete')}</button>
                  </div>
                </div>

                <div className="content-card-main-buttons">
                  <button
                    className="go-back"
                    onClick={() => handleCardChange("createTeam")}
                  >
                    <i className="icon-arrow-left"></i>{' '}{t('common:GoBack')}
                  </button>
                  <button
                    className="button button-green-primary"
                    onClick={() => {
                      if (showSuccess) {
                        handleCardChange("success")
                      } else {
                        push('/')
                      }
                    } }
                  >
                    {t('common:Registernewteam')}{sigininin && (<span style={{ marginLeft: '9px' }}><Loader /></span>)}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* CREATE YOUR FIRST TEAM CARD-08 */}
        {/* CURRENT 8 NEEDS 8 */}
        {/* activeCard === 'success' && (
                    <div className="content-card card-08">
                        <div className="content-card-header">
                            <p className="content-card-header-title">Team Marketing</p>
                            <p className="content-card-header-subtitle">Congratulations your first team has been successfully registered.
                                </p>
                        </div>

                        <div className="content-card-main">

                            <div className="horizontal-divider"></div>

                            <div className="scrollable">
                                <div className="members">
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">Delete</button>
                                    </div>

                                </div>

                                <div className="content-card-main-buttons">
                                    <button className="button button-green-primary">To the dashboard <i className="icon-arrow-right"></i></button>
                                </div>
                            </div>

                        </div>

                    </div>
                ) */}
        {activeCard === 'success' && showSuccess && (
          <div className="content-card card-09">
            <div className="content-card-header">
              <p className="content-card-header-title">{t('common:Onemorething')}</p>
              <p className="content-card-header-subtitle">{t('createJoinCompany:Wouldyoulikeustocreate')}</p>
            </div>

            <div className="content-card-main">

              <button className="button button-green-primary" onClick={() => {
                createTeam({
                  name: user?.firstname + "'s Test team",
                  demo: true
                })
              }
              }
              >{t('createJoinCompany:Yescrateademoteamforme')}{sigininin && (<span style={{ marginLeft: '9px' }}><Loader /></span>)}</button>
              <p className="content-card-main-text-small"><button className="regular">{t('createJoinCompany:Idontneedademoteam')}</button></p>
            </div>

          </div>
        )}
      </div>
    </div>
  )
}

export default connector(CreateNewTeam)
