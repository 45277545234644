import React, { useEffect, useState } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
//import Signin from 'src/pages/SigninSingup/Signin'
import Signin from 'src/pages/SigninSingup/SigninNew'
import CreateAccount from 'src/pages/SigninSingup/CreateAccountNew'
import Home from 'src/pages/MainApp/GiveFeedbackNew'
import TeamMemberSettings from 'src/pages/TeamSettings/teamMemberSettingsNew'
import TeamLeaderSettings from 'src/pages/TeamSettings/teamLeadersSettings'
import ViewingRights from 'src/pages/TeamSettings/viewingRights'
import Admins from 'src/pages/TeamSettings/adminRights'
import FeedbackInput from 'src/pages/TeamSettings/feedinput'
import FeedbackInputNew from 'src/pages/TeamSettings/feedinputNew'
import GeneralSettings from 'src/pages/TeamSettings/generalSettings'
import AppUsage from 'src/pages/TeamSettings/appUsage'
import CreateJoinTeam from 'src/components/modals/createJoinCompany'
import { restoreSession } from './redux/auth/actions'
import { AppDispatch, RootState } from './configureStore'
import { connect, ConnectedProps } from 'react-redux'
import CreateCompany from './pages/company/createCompany'
import CompanyOverview from './pages/company/overview'
import Employees from './pages/company/employees'
import CompanyAdmins from './pages/company/admins'
import CompanyTeams from './pages/company/teams'
import TeamMembers from './pages/TeamMembers/teamMembers'
import TeamMembersTable from './pages/TeamMembers/teamMemberstable'
import ViewingRightsTable from './pages/TeamMembers/viewingRightsTable'
import FeedbackRequest from './pages/feedbackRequests/feedbackRequest'
//import OverallCharts from './pages/feedbackCharts/overallCharts'
//import OverallChartsTest from './pages/feedbackCharts/ResponsiveLocalStorageLayout'
import OverallChartsNew from './pages/feedbackCharts/testWidgets'
import MyFeedback from './pages/feedbackCharts/myFeedback'
import P2PFeedback from './pages/feedbackCharts/p2pFeedback'
import FeedbackTeamLeader from './pages/feedbackCharts/feedbackTeamLeader'
import SelfEvaluations from './pages/feedbackCharts/selfEvaluations'
import Supervisory from './pages/feedbackCharts/supervisory'
import GeneralCharts from './pages/feedbackCharts/generalCharts'
import TestWidgets from './pages/feedbackCharts/testWidgets'
import TeamFeedback from './pages/TeamFeedbackText/teamFeedbackTextNew'
import GeneralText from './pages/TeamFeedbackText/generalText'
import P2PFeedbackText from './pages/TeamFeedbackText/p2pFeedbackText'
import MyFeedbackText from './pages/TeamFeedbackText/myFeedbackText'
import SubordinateFeedbackText from './pages/TeamFeedbackText/subordinateFeedbackText'
import SelfFeedbackText from './pages/TeamFeedbackText/selfFeedbackText'
import SupervisroFeedbackText from './pages/TeamFeedbackText/supervisroFeedbackText'
import UserSettings from './pages/userSettings/userSettings'
import UserTeamSettings from './pages/userSettings/userTeamSettings'
import PageModal from './components/modals/pageModal'
import CreateTeam from './pages/MainApp/createTeamNewDesign'
import { ToastContainer, Slide } from 'react-toastify'
import MainComponent from 'src/components/header/mainComponent'
import Drawer from './components/sidebar/drawer'
import 'react-toastify/dist/ReactToastify.css'
import CreateTeamModal from './components/modals/createTeamModalNew'
import UserFeedbacks from 'src/components/modals/userFeedbacks'
import OpenUserProfile from './components/modals/openUserProfile'
import { getMyWidgets, addWidget, getMyToDo } from './redux/feedback/actions'
import { AddWidgetVariables } from './apiTypes'
import { getMyTeams, getTeamById } from './redux/teams/actions'
import ViewPort from './components/common/viewPort'
import InviteTeamMember from './components/modals/inviteTeamMember'
import ResetPassword from './pages/SigninSingup/ResetPasswordNew'
import i18n from './i18n'
import { checkIfLangExist } from './utitlity'
import moment from 'moment'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  restoreSession: () => dispatch(restoreSession()),
  getMyWidgets: (teamId: string) => dispatch(getMyWidgets(teamId, 'team')),
  addWidget: (data: AddWidgetVariables) => dispatch(addWidget(data)),
  getTeamById: (teamId: string) => dispatch(getTeamById(teamId)),
})

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  sessionRestored: state.authReducer.sessionRestored,
  toaster: state.teamReducer.toaster,
  selectedUser: state.authReducer.selectedUser,
  selectedTeam: state.teamReducer.selectedTeam,
  myWidgets: state.feedbackReducer.myWidgets,
})
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {}

if (i18n.language) {
  try {
    //const lang = require('moment/locale/' +i18n.language[0]+i18n.language[1])
    if (checkIfLangExist(i18n.language[0] + i18n.language[1])) {

      moment.locale(i18n.language[0] + i18n.language[1])
    }

  } catch (e) {

  }
}
function App(props: Props) {
  const {
    user,
    sessionRestored,
    toaster,
    selectedUser,
    getMyWidgets,
    selectedTeam,
    addWidget,
    myWidgets,
    getTeamById,
  } = props
  const { restoreSession } = props
  useEffect(() => {
    restoreSession()
  }, [])

  useEffect(() => {
    if (selectedUser) {
    }
  }, [selectedUser])
  useEffect(() => {
    if (selectedTeam) {
      //    getMyWidgets(selectedTeam._id)
    }
  }, [selectedTeam, user])

  useEffect(() => {
    if (selectedTeam) {
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '60054ef7dffb237f6516d3bc',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '600a8d5b74cd7e347f0ddece',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '6005a84202ff067bc32d412d',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '600ebbbc9a96002aa5b9245b',
      })

      addWidget({
        teamId: selectedTeam._id,
        widgetId: '600ef5929a96002aa5b9245c',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '600fea209a96002aa5b9245e',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '601016a89a96002aa5b9245f',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '6011564175330cbe96921eb6',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '6013fcf68c0a626b96668e35',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '6017dc428c0a626b96668e36',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '601abbf3330bee2bbd97e817',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '601aca5d330bee2bbd97e818',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '601bb67c330bee2bbd97e819',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '601be4ad2017908445fb9bd0',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '601d56042017908445fb9bd1',
      })
      addWidget({
        teamId: selectedTeam._id,
        widgetId: '604106306d1a4f45830b39b0',
      })
    }
  }, [selectedTeam])

  const [teamId, setTeamId] = useState<string | null>(null)
  useEffect(() => {
    if (teamId) {
      getTeamById(teamId)
    }
  }, [teamId])



  return (
    <Router>
      <MainComponent>
        <Switch>
          <Route exact path="/">
            <Signin />
          </Route>
          <Route exact path="/createAccount/:step">
            <CreateAccount />
          </Route>
          <Route exact path="/pageModal">
            <PageModal />
          </Route>
          <Route exact path="/createJoinTeam">
            <CreateJoinTeam open={true} handleClose={() => null} />
          </Route>
          <Route path="/createNewTeam">
            <CreateTeam />
          </Route>
          <Route exact path="/team/:teamId/giveFeedback">
            <Home getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/appUsage">
            <AppUsage getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/general">
            <GeneralSettings getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/members">
            {/* <TeamMemberSettings getTeamId={(teamId) => setTeamId(teamId)} /> */}
            <TeamMembersTable getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/teamLeaders">
            <TeamLeaderSettings getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/viewingRights">
            <ViewingRightsTable getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/admins">
            <Admins getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/feedbackInput">
            <FeedbackInput getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/settings/newSettingsPage">
            <FeedbackInputNew getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/company/:companyId/overview">
            <CompanyOverview />
          </Route>
          <Route exact path="/company/:companyId/employees">
            <Employees />
          </Route>
          <Route exact path="/company/:companyId/admins">
            <CompanyAdmins />
          </Route>
          <Route exact path="/company/:companyId/teams">
            <CompanyTeams />
          </Route>
          <Route exact path="/company/createCompany">
            <CreateCompany />
          </Route>
          <Route exact path="/team/:teamId/data/teamFeedback">
            <OverallChartsNew
              //@ts-ignore
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/team/:teamId/data/p2pFeedback">
            <P2PFeedback
              //@ts-ignore
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/team/:teamId/data/supervisoryFeedback">
            <Supervisory
              //@ts-ignore
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/team/:teamId/data/charts">
            <GeneralCharts
              //@ts-ignore
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/team/:teamId/data/selfFeedbackText">
            <SelfFeedbackText getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/data/supervisorTextFeedback">
            <SupervisroFeedbackText getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/data/subordinateFeedback">
            <FeedbackTeamLeader
              //@ts-ignore
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/team/:teamId/data/self">
            <SelfEvaluations
              //@ts-ignore
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/teamMembers">
            <TeamMembers />
          </Route>
          {/* <Route exact path="/teamMembersTable">
            <TeamMembersTable />
          </Route> */}
          <Route exact path="/tesWidgets">
            <TestWidgets />
          </Route>
          <Route exact path="/team/:teamId/settings/requests">
            <FeedbackRequest getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/data/teamFeedbackText">
            <TeamFeedback getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/data/text">
            <GeneralText getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/data/p2pFeedbackText">
            <P2PFeedbackText getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/team/:teamId/data/subordinateFeedbackText">
            <SubordinateFeedbackText
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/teamfeedback/myFeedback/charts">
            <MyFeedback
              //@ts-ignore
              getTeamId={(teamId) => setTeamId(teamId)}
            />
          </Route>
          <Route exact path="/teamfeedback/myFeedback/feedbackArchive">
            <MyFeedbackText getTeamId={(teamId) => setTeamId(teamId)} />
          </Route>
          <Route exact path="/user/generalSettings">
            <UserSettings />
          </Route>
          <Route exact path="/user/teamSettings">
            <UserTeamSettings />
          </Route>
          <Route exact path="/resetPassword">
            <ResetPassword />
          </Route>
        </Switch>
        <ToastContainer
          className="custom-toast-container"
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
          toastClassName={'toast-style'}
          transition={Slide}
        />

        <Drawer />
        <CreateTeamModal />
        <InviteTeamMember />
        <OpenUserProfile />
      </MainComponent>
      <ViewPort />
      {<UserFeedbacks user={selectedUser} open={!!selectedUser} />}
    </Router>
  )
}

export default connector(App)
