import React, { useState, useEffect, useRef } from "react"
import { connect, ConnectedProps } from "react-redux"
import { useHistory, useLocation, useParams } from "react-router-dom"
import {
  AddQuestionVariables,
  Comment,
  RelationalDataVariables,
  SendCommentVariables,
  User
} from "src/apiTypes"
import { AppDispatch, RootState } from "src/configureStore"
import {
  clearUserTextFeedback,
  getComments,
  getTargetHistory,
  getUserTextFeedback,
  likeUnlikeFeedback,
  markRead,
  sendComment,
  switchChartType,
} from "src/redux/feedback/actions"
import { colorByName, dateFromObjectId } from "src/utitlity"
import AddNewQuestion from "src/components/modals/addNewQuestion"
import _ from "lodash"
import moment from "moment"
import {
  addQuestion,
  getActiveTeamUsers,
  getRelationalData
} from "src/redux/teams/actions"
import { IMAGE_API_ROOT } from "src/request"
import { selectUser } from "src/redux/auth/actions"
import { useTranslation } from "react-i18next"
import SeeUsersModal from "src/components/modals/seeUsersModal"
import ReactTooltip from 'react-tooltip'

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  selectedTeam: state.teamReducer.selectedTeam,
  selectedTeamData: state.teamReducer.selectedTeamData,
  relationalData: state.teamReducer.relationalData,
  userTextFeedback: state.feedbackReducer.userTextFeedback,
  targetHistory: state.feedbackReducer.targetHistory,
  comments: state.feedbackReducer.comments,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addQuestion: (data: AddQuestionVariables) => dispatch(addQuestion(data)),
  likeUnlikeFeedback: (
    targetId: string,
    like: boolean,
    userData: {
      _id: string
      firstname: string
      lastname: string
    }
  ) => dispatch(likeUnlikeFeedback(targetId, like, userData)),
  sendComment: (data: SendCommentVariables) => dispatch(sendComment(data)),
  markRead: (targetId: string, questionId: string) =>
    dispatch(markRead(targetId, questionId)),
  selectUser: (user: User) => dispatch(selectUser(user)),
  getActiveUsers: (teamId: string, memberType: string) =>
    dispatch(getActiveTeamUsers(teamId, memberType)),
  getRelationalData: (data: RelationalDataVariables) =>
    dispatch(getRelationalData(data)),
  getUserTextFeedback: (userId: string) =>
    dispatch(getUserTextFeedback(userId, "p2p")),
  clear: () => dispatch(clearUserTextFeedback()),
  getTargetHistory: (targetId: string) => dispatch(getTargetHistory(targetId)),
  switchChartType: () => dispatch(switchChartType("text")),
  getComments: (category: string, feedbackType: string) =>
    dispatch(getComments(category, feedbackType)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  getTeamId(teamId: string): void
}

interface ParamTypes {
  teamId?: string
}
export type DropDownItem = {
  name: string
  category: string
  caption: string
}

const receivedDropDown: DropDownItem[] = [
  {
    name: "receivedFromColleages",
    category: "p2p",
    caption: 'Received from colleages'
  },
  {
    name: "receivedFromSubordinates",
    category: "supervisor",
    caption: 'Received from subordinates'
  },
  {
    name: "receivedFromSupervisors",
    category: "subordinate",
    caption: 'Received from supervisors'
  },
]
const sentDropDown: DropDownItem[] = [
  {
    name: "sentToColleagues",
    category: "p2p",
    caption: 'Sent to colleagues'
  },
  {
    name: "sentToSubordinates",
    category: "subordinate",
    caption: 'Sent to subOrdinates'
  },

  {
    name: "sentToSupervisors",
    category: "supervisor",
    caption: 'Sent to supervisors'
  },
  {
    name: "sentSelfEvaluations",
    category: "self",
    caption: 'Self Evaluations'
  },
  {
    name: "sentTeam",
    category: "team",
    caption: 'Sent to team'
  },
]

const MyFeedbackText = (props: Props) => {
  const { t } = useTranslation(["common", "textFeedbackPages"])
  const { push } = useHistory()

  const {
    selectedTeam,

    addQuestion,
    likeUnlikeFeedback,
    sendComment,
    user,
    markRead,
    selectUser,
    selectedTeamData,

    targetHistory,
    getTargetHistory,
    comments,
    getComments,
  } = props
  const [selectedFeedback, selectFeedback] = useState<Comment | null>(null)
  const [selectedUser, selectUserState] = useState<User | null>(null)
  const [mainSelection, selectMain] = useState<string>("Received feedbacks")
  const [selected, setSelected] = useState<DropDownItem>({
    name: "receivedFromColleages",
    category: "p2p",
    caption: 'Received from colleages'
  })

  console.log(comments);

  useEffect(() => {


    getComments(
      selected.category,
      !selected.name.includes("sent") ? "receiver" : "sender"
    )
    console.log('fired');

  }, [getComments, user, selected, mainSelection])

  useEffect(() => {
    const cardTriggers = document.querySelectorAll(".card-trigger")

    cardTriggers.forEach((el) => {
      el.addEventListener("click", openCard)
    })

    function openCard() {
      // @ts-ignore
      const parent = this.parentElement

      if (document.documentElement.clientWidth < 1100) {
        if (parent.classList.contains("closed")) {
          cardTriggers.forEach((el) => {
            //   @ts-ignore
            el.parentElement.classList.add("closed")
          })
        } else {
          cardTriggers.forEach((el) => {
            //   @ts-ignore
            el.parentElement.classList.remove("closed")
          })
        }
      }

      parent.classList.toggle("closed")
    }

    function closeProfileOnSmall() {
      if (document.documentElement.clientWidth < 1100) {
        //   @ts-ignore
        document.querySelector(".content-card.profile")?.classList.add("closed")
      } else {
        //   @ts-ignore
        document
          .querySelector(".content-card.profile")
          ?.classList.remove("closed")
        // @ts-ignore
        document
          .querySelector(".content-card.questions")
          ?.classList.remove("closed")
      }
    }

    closeProfileOnSmall()

    window.addEventListener("resize", closeProfileOnSmall)
  }, [])

  const [typedComment, setTypedComment] = useState("")
  const [liked, setLiked] = useState(false)

  useEffect(() => {
    if (
      selectedTeamData?.teamMembers &&
      selectedTeamData?.teamMembers[0] &&
      !selectedUser
    ) {
      selectUserState(selectedTeamData?.teamMembers[0])
    }
  }, [selectedTeamData?.teamMembers && selectedTeamData?.teamMembers[0]])

  useEffect(() => {
    if (comments && !comments.length) {
      selectFeedback(null)
    }
  }, [comments])

  useEffect(() => {
    if (selectedFeedback) {
      getTargetHistory(selectedFeedback._id)
      markRead(selectedFeedback._id, selectedFeedback._id)
    }
  }, [selectedFeedback])

  const [commentButtonClass, setCommentButtonClass] = useState(
    "button-green-primary"
  )
  const [commentButtonCaption, setCommentButtonCaption] = useState("Add")
  const [selectedFeedbackIndex, setSelectedFeedbackIndex] = useState<
    number | null
  >(null)

  useEffect(() => {
    if (commentButtonCaption === "Sending") {
      setTimeout(() => {
        setCommentButtonClass("sent")
        setCommentButtonCaption(t("common:Sent"))
      }, 1000)
    }
    if (commentButtonCaption === t("common:Sent")) {
      setTimeout(() => {
        setCommentButtonClass("button-green-primary")
        setCommentButtonCaption("Add")
        setTypedComment("")
      }, 1000)
    }
  }, [commentButtonCaption])

  const changeSelection = () => {

    if (!selectedFeedback) {
      if (comments?.length) {
        selectFeedback(comments[0])
      }
    }
    if (selectedFeedback) {
      comments?.find((item, i) => {
        if (item._id === selectedFeedback._id) {
          if (comments[i + 1]) {
            selectFeedback(comments[i + 1])
          }
        }
      })
    }
  }
  useEffect(() => {
    const listen = window.addEventListener("keydown", (e) => {
      if (e.code === "ArrowDown") {
        changeSelection()
      }
    })
  }, [changeSelection])


  const [selectedLocalUser, selectLocalUser] = useState<User | null>(null)

  useEffect(() => {
    if (selectedFeedback) {
      setLiked(selectedFeedback.like || false)
    }
  }, [selectedFeedback])
  return (
    <div className="content-wrapper">
      <div className="content">
        <div className="widgets-top">
          <div className="option-buttons">
            <button
              className="button"
              onClick={() => {

                //@ts-ignore
                push("/teamfeedback/myFeedback/charts")

              }}
            >
              {t("common:Charts")}
            </button>
            <button className="button active">{t("common:Text")}</button>
          </div>
        </div>

        <div className="text-feedback-content">
          <div className="content-card questions">
            <i className="icon-chevron-left card-trigger"></i>
            <div className="questions-header">
              <p className="questions-header-title">
                {t("common:Receivedfeedback")}
              </p>
            </div>
            <div className="questions-main">
              <div
                className={`question ${selected.name === "receivedFromColleages" ? "active" : ""
                  }`}
                onClick={() =>
                  setSelected({
                    name: "receivedFromColleages",
                    category: "p2p",
                    caption: 'Received from colleages'
                  })
                }
              >
                <p className="question-text">
                  {t("common:Receivedfromteammembers")}
                </p>
              </div>
              <div
                className={`question ${selected.name === "receivedFromSubordinates" ? "active" : ""
                  }`}
                onClick={() =>
                  setSelected({
                    name: "receivedFromSubordinates",
                    category: "subordinate",
                    caption: 'Received from subordinates'
                  })
                }
              >
                <p className="question-text">
                  {t("common:Receivedfromteamleader")}
                </p>
              </div>
              {/* <div className="question" onClick={() => setSelected({
                                name: 'receivedFromSupervisors',
                                category: 'subordinate',
                                })}>
                                <p className="question-text">Received from subordinates</p>
                            </div> */}

              {/*  */}
              <div className="questions-main-section">
                <div className="questions-header">
                  <p className="questions-header-title">
                    {t("common:Sendfeedback")}
                  </p>
                </div>
                <div
                  className={`question ${selected.name === "sentTeam" ? "active" : ""
                    }`}
                  onClick={() => {
                    setSelected({
                      name: "sentTeam",
                      category: "team",
                      caption: t('common:Sentteamfeedback')
                    })
                  }}
                >
                  <p className="question-text">
                    {t("common:Sentteamfeedback")}
                  </p>
                </div>
                <div
                  className={`question ${selected.name === "sentToColleagues" ? "active" : ""
                    }`}
                  onClick={() => {
                    setSelected({
                      name: "sentToColleagues",
                      category: "p2p",
                      caption: t('common:Senttocolleagues')
                    })
                  }}
                >
                  <p className="question-text">
                    {t("common:Senttocolleagues")}
                  </p>
                </div>
                <div
                  className={`question ${selected.name === "sentToSupervisors" ? "active" : ""
                    }`}
                  onClick={() => {
                    setSelected({
                      name: "sentToSupervisors",
                      category: "supervisor",
                      caption: t('common:Senttomyteamleader')
                    })
                  }}
                >
                  <p className="question-text">
                    {t("common:Senttoteamleader")}
                  </p>
                </div>
                <div
                  className={`question ${selected.name === "sentSelfEvaluations" ? "active" : ""
                    }`}
                  onClick={() => {
                    setSelected({
                      name: "sentSelfEvaluations",
                      category: "self",
                      caption: t("common:selfEvaluations")
                    })
                  }}
                >
                  <p className="question-text">
                    {t("common:Sentselfevaluation")}
                  </p>
                </div>
              </div>
              {/*  */}
            </div>
          </div>

          <div className="content-card answers">
            <div className="answers-header">
              <p className="answers-header-title">
                {/* t("common:Whatwecandobetterinthisteam") */}
                {selected.caption}
              </p>
              {/* <p className="answers-header-amount">{t("common:p2pfeedbackthat")}{' '}{selectedUser?.firstname + ' ' + selectedUser?.lastname}{' '}{t("common:hasreceived")}.</p> */}
              <p className="answers-header-options">
                <button className="answers-header-options-link active">
                  All teams
                </button>
                <span className="answers-header-options-divider">I</span>
                <button className="answers-header-options-link">
                  Team marketing
                </button>
                <span className="answers-header-options-divider">I</span>
                <button className="answers-header-options-link">
                  Team sales
                </button>
              </p>
              <svg data-tip={`${t('common:Markallread')}`} xmlns="http://www.w3.org/2000/svg" width="17.01" height="16.763" viewBox="0 0 17.01 16.763">
                <g transform="translate(-2.051 -2.081)" opacity="0.327">
                  <path d="M18,9.805v.69A7.5,7.5,0,1,1,13.552,3.64" fill="none" stroke="#000" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" />
                  <path d="M23.25,6l-7.5,7.507-2.25-2.25" transform="translate(-5.25 -1.505)" fill="none" stroke="#000"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                </g>
              </svg>
            </div>
            {!selected.name.includes("sent") ? (
              <div className="answers-main">
                {!!comments?.length ? (
                  comments.map((feed, i) => (
                    <div
                      className={`answer ${String(selectedFeedback?._id) === String(feed._id)
                        ? "active"
                        : ""
                        }`}
                      key={feed._id}
                      onClick={() => {
                        selectFeedback(feed)
                        setSelectedFeedbackIndex(i)
                      }}
                    >
                      {feed.sender.profilePic ? (
                        <img
                          src={
                            IMAGE_API_ROOT +
                            feed.sender.profilePic.replace("resized", "thumb")
                          }
                          alt="User"
                          className="answer-img"
                          onClick={() => {
                            selectUser(feed.sender)
                            document
                              .getElementById("usersModal")
                              ?.classList.add("open")
                          }}
                        ></img>
                      ) : (
                        <div
                          onClick={() => {
                            selectUser(feed.sender)
                            document
                              .getElementById("usersModal")
                              ?.classList.add("open")
                          }}
                          className="answer-initials"
                          style={{
                            backgroundColor: colorByName(
                              feed.sender.firstname + " " + feed.sender.lastname
                            ),
                          }}
                        >
                          {feed.sender.firstname[0] + feed.sender.lastname[0]}
                        </div>
                      )}
                      <div className="answer-set">
                        <div className="answer-set-header">
                          <p className="answer-set-header-name">
                            {feed.sender.firstname + " " + feed.sender.lastname}
                          </p>
                          <div className="answer-set-header-info">
                            {/* !feed.read && <span className="answer-set-header-info-circle"></span> */}
                            {
                              <p className="answer-set-header-info-text">
                                {moment(dateFromObjectId(feed._id)).fromNow()}
                              </p>
                            }
                          </div>
                        </div>
                        <p className="answer-set-text">
                          {feed.textData.map(
                            (v, i) =>
                              v.value +
                              (i < feed.textData.length - 1 ? " — " : "")
                          )}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="answers-placeholder">
                    <img
                      src="/img/table-placeholder-img-04.png"
                      alt="Clip art"
                      className="answers-placeholder-img"
                    ></img>
                    <p className="answers-placeholder-title">
                      {t("common:Nodatayet")}
                    </p>
                    <p
                      className="answers-placeholder-text"
                      style={{ maxWidth: "26rem" }}
                    >
                      {t("common:Theteammember")}{" "}
                      <span>
                        {selectedUser?.firstname + " " + selectedUser?.lastname}
                      </span>{" "}
                      {t("common:hasnotreceivedanyp2pfeedbackyet")}.
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="answers-main">
                {!!comments?.length ? (
                  comments.map((feed, i) => (
                    <div
                      className={`answer ${String(selectedFeedback?._id) === String(feed._id)
                        ? "active"
                        : ""
                        }`}
                      key={feed._id}
                      onClick={() => {
                        selectFeedback(feed)
                        setSelectedFeedbackIndex(i)
                      }}
                    >
                      {feed?.receiver?.profilePic ? (
                        <img
                          src={
                            IMAGE_API_ROOT +
                            feed.receiver.profilePic.replace("resized", "thumb")
                          }
                          alt="User"
                          className="answer-img"
                          onClick={() => {
                            selectUser(feed.receiver)
                            document
                              .getElementById("usersModal")
                              ?.classList.add("open")
                          }}
                        ></img>
                      ) : (
                        <div
                          onClick={() => {
                            selectUser(feed.receiver)
                            document
                              .getElementById("usersModal")
                              ?.classList.add("open")
                          }}
                          className="answer-initials"
                          style={{
                            backgroundColor: colorByName(
                              feed.receiver?.firstname +
                              " " +
                              feed.receiver?.lastname
                            ),
                          }}
                        >
                          {feed.receiver?.firstname[0] +
                            feed.receiver?.lastname[0]}
                        </div>
                      )}
                      <div className="answer-set">
                        <div className="answer-set-header">
                          <p className="answer-set-header-name">
                            {feed.receiver?.firstname +
                              " " +
                              feed.receiver?.lastname}
                          </p>
                          <div className="answer-set-header-info">
                            {/* !feed.read && <span className="answer-set-header-info-circle"></span> */}
                            {
                              <p className="answer-set-header-info-text">
                                {moment(dateFromObjectId(feed._id)).fromNow()}
                              </p>
                            }
                          </div>
                        </div>
                        <p className="answer-set-text">
                          {feed.textData.map(
                            (v, i) =>
                              v.value +
                              (i < feed.textData.length - 1 ? " — " : "")
                          )}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="answers-placeholder">
                    <img
                      src="/img/table-placeholder-img-04.png"
                      alt="Clip art"
                      className="answers-placeholder-img"
                    ></img>
                    <p className="answers-placeholder-title">
                      {t("common:Nodatayet")}
                    </p>
                    <p
                      className="answers-placeholder-text"
                      style={{ maxWidth: "26rem" }}
                    >
                      {t("common:Theteammember")}{" "}
                      <span>
                        {selectedUser?.firstname + " " + selectedUser?.lastname}
                      </span>{" "}
                      {t("common:hasnotreceivedanyp2pfeedbackyet")}.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
          {!selected.name.includes("sent") ? (
            <div className="content-card profile">
              <i className="icon-chevron-right card-trigger"></i>

              <div className="profile-header">
                <div className="profile-header-set">
                  {!!selectedFeedback ? (
                    <>
                      {selectedFeedback.sender.profilePic ? (
                        <img
                          src={
                            IMAGE_API_ROOT +
                            selectedFeedback.sender.profilePic.replace(
                              "resized",
                              "thumb"
                            )
                          }
                          alt="User"
                          className="profile-header-set-img"
                          onClick={() => {
                            selectLocalUser(selectedFeedback.sender)
                          }}
                        ></img>
                      ) : (
                        <div
                          onClick={() => {
                            selectUser(selectedFeedback.sender)
                            /*                             document
                                                          .getElementById("usersModal")
                                                          ?.classList.add("open") */
                          }}
                          className="profile-header-set-initials"
                          style={{
                            backgroundColor: colorByName(
                              selectedFeedback.sender.firstname +
                              " " +
                              selectedFeedback.sender.lastname
                            ),
                          }}
                        >
                          {selectedFeedback.sender.firstname[0] +
                            selectedFeedback.sender.lastname[0]}
                        </div>
                      )}

                      <p className="profile-header-set-name" onClick={() => {
                        selectLocalUser(selectedFeedback.sender)
                      }}>
                        {selectedFeedback.sender.firstname +
                          " " +
                          selectedFeedback.sender.lastname}
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="profile-header-set-icon">
                        <i className="icon-user"></i>
                      </div>
                      <p className="profile-header-set-name" style={{ textDecoration: "none" }}>
                        {t("common:Nofeedbackselected")}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {!!selectedFeedback ? (
                <>
                  <div className="profile-info">
                    <div className="profile-info-item position">
                      <i className="icon-feather-briefcase"></i>
                      <p className="profile-info-item-text">
                        {selectedFeedback.sender.jobtitle ||
                          t("common:Nojobtitle")}
                      </p>
                    </div>
                    <div className="profile-info-item email">
                      <i className="icon-feather-mail"></i>
                      <p className="profile-info-item-text">
                        {selectedFeedback.sender.email}
                      </p>
                    </div>
                  </div>

                  <div className="profile-feedback open">
                    <div className="profile-feedback-header">
                      <div
                        className="profile-feedback-header-set"
                        onClick={() => {
                          if (user && !selectedFeedback.like) {
                            likeUnlikeFeedback(
                              selectedFeedback._id,
                              selectedFeedback.like || false,
                              {
                                _id: user._id,
                                firstname: user.firstname,
                                lastname: user.lastname,
                              }
                            )
                            setTimeout(
                              () => getTargetHistory(selectedFeedback._id),
                              500
                            )
                            setLiked(!liked)
                          }

                          
                        }}
                      >
                        <i
                          className={`icon-feather-thumbs-up ${liked && "liked"
                            }`}
                        ></i>

                        <p
                          className="profile-feedback-header-set-text"
                          /* @ts-ignore */
                          style={{ fontWeight: liked ? "500" : "400" }}
                        >
                          {liked
                            ? t("common:Liked")
                            : t("common:Likethisfeedback")}{" "}
                        </p>
                      </div>
                      <div
                        className="profile-feedback-header-set toggle-profile-comment"
                        onClick={(e) =>
                          e.currentTarget?.parentElement?.parentElement?.classList.toggle(
                            "open"
                          )
                        }
                      >
                        <i className="icon-feather-edit"></i>
                        <p className="profile-feedback-header-set-text">
                          {t("common:Comment")}
                        </p>
                      </div>
                      <i className="icon-chevron-down"></i>
                    </div>
                    <div className="profile-feedback-main">
                      <i className="icon-feather-edit"></i>
                      <div className="profile-feedback-main-set">
                        <textarea
                          placeholder={`${t("common:Commentthisfeedback")}`}
                          value={typedComment}
                          onChange={(e) => setTypedComment(e.target.value)}
                        ></textarea>
                        <div className="profile-feedback-main-set-footer">
                          <button
                            className={`button ${commentButtonClass}`}
                            onClick={() => {
                              if (typedComment && user) {
                                sendComment({
                                  feedbackId: selectedFeedback._id,
                                  comment: typedComment,
                                  sender: user._id,
                                  firstname: user.firstname,
                                  lastname: user.lastname,
                                })
                                setTimeout(
                                  () => getTargetHistory(selectedFeedback._id),
                                  500
                                )
                                setCommentButtonClass("sent")
                                setCommentButtonCaption("Sending")
                              }
                            }}
                          >
                            {commentButtonCaption}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <div className="profile-footer-wrapper">
                      <div className="profile-footer">
                        <div className="profile-footer-item">
                          <div className="profile-footer-item-main">
                            <div
                              className="profile-footer-item-icon"
                              style={{ backgroundColor: "#ffb100" }}
                            >
                              <i className="icon-feather-chart"></i>
                            </div>
                            <div className="profile-footer-item-set">
                              <p className="profile-footer-item-set-title">
                                {t("common:Feedbackgiven")}
                              </p>
                              <p className="profile-footer-item-set-text">
                                {moment(
                                  dateFromObjectId(selectedFeedback._id)
                                ).fromNow()}
                              </p>
                            </div>
                          </div>
                        </div>

                        {targetHistory?.map((item, i) => (
                          <div
                            className="profile-footer-item"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              e.currentTarget.classList.toggle("open")
                            }
                          >
                            <div className="profile-footer-item-main">
                              <div
                                className="profile-footer-item-icon"
                                style={{
                                  backgroundColor:
                                    item.type === "like"
                                      ? "#12a952"
                                      : "#457cb9",
                                }}
                              >
                                <i
                                  className={
                                    item.type === "like"
                                      ? "icon-feather-thumbs-up"
                                      : "icon-feather-pencil"
                                  }
                                  style={{ marginTop: "-2px" }}
                                ></i>
                              </div>
                              <div className="profile-footer-item-set">
                                <p className="profile-footer-item-set-title">
                                  {item.type === t("common:like")
                                    ? t("common:Liked")
                                    : t("common:CommentedBy")}{" "}
                                  {item.sender.firstname +
                                    " " +
                                    item.sender.lastname}
                                </p>
                                <p className="profile-footer-item-set-text">
                                  {moment(dateFromObjectId(item._id)).fromNow()}
                                </p>
                              </div>
                            </div>
                            <div
                              className="profile-footer-item-comment"
                              id={"comment" + i}
                            >
                              {!!item.comment && (
                                <p className="profile-footer-item-comment-text">
                                  {item.comment}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="profile-placeholder">
                  <p className="profile-placeholder-title">
                    {t("common:Nofeedbackselected")}
                  </p>
                  <p className="profile-placeholder-text">
                    {t("textFeedbackPages:Pleaseselectafeedback")}.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="content-card profile">
              <i className="icon-chevron-right card-trigger"></i>

              <div className="profile-header">
                <div className="profile-header-set">
                  {!!selectedFeedback ? (
                    <>
                      {selectedFeedback.receiver.profilePic ? (
                        <img
                          src={
                            IMAGE_API_ROOT +
                            selectedFeedback.receiver.profilePic.replace(
                              "resized",
                              "thumb"
                            )
                          }
                          alt="User"
                          className="profile-header-set-img"
                          onClick={() => {
                            selectLocalUser(selectedFeedback.receiver)
                          }}
                        ></img>
                      ) : (
                        <div
                          onClick={() => {
                            selectLocalUser(selectedFeedback.receiver)
                          }}
                          className="profile-header-set-initials"
                          style={{
                            backgroundColor: colorByName(
                              selectedFeedback.receiver.firstname +
                              " " +
                              selectedFeedback.receiver.lastname
                            ),
                          }}
                        >
                          {selectedFeedback.receiver.firstname[0] +
                            selectedFeedback.receiver.lastname[0]}
                        </div>
                      )}

                      <p className="profile-header-set-name" onClick={() => {
                        selectUser(selectedFeedback.receiver)
                        document
                          .getElementById("usersModal")
                          ?.classList.add("open")
                      }}>
                        {selectedFeedback.receiver.firstname +
                          " " +
                          selectedFeedback.receiver.lastname}
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="profile-header-set-icon">
                        <i className="icon-user"></i>
                      </div>
                      <p className="profile-header-set-name">
                        {t("common:Nofeedbackselected")}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {!!selectedFeedback ? (
                <>
                  <div className="profile-info">
                    <div className="profile-info-item position">
                      <i className="icon-feather-briefcase"></i>
                      <p className="profile-info-item-text">
                        {selectedFeedback.receiver.jobtitle ||
                          t("common:Nojobtitle")}
                      </p>
                    </div>
                    <div className="profile-info-item email">
                      <i className="icon-feather-mail"></i>
                      <p className="profile-info-item-text">
                        {selectedFeedback.receiver.email}
                      </p>
                    </div>
                  </div>

                  <div className="profile-feedback open">
                    <div className="profile-feedback-header">
                      <div
                        className="profile-feedback-header-set"
                        onClick={() => {
                          if (user) {
                            likeUnlikeFeedback(
                              selectedFeedback._id,
                              selectedFeedback.like || false,
                              {
                                _id: user._id,
                                firstname: user.firstname,
                                lastname: user.lastname,
                              }
                            )
                            setTimeout(
                              () => getTargetHistory(selectedFeedback._id),
                              500
                            )
                          }

                          setLiked(!liked)
                        }}
                      >
                        <i
                          className={`icon-feather-thumbs-up ${liked && "liked"
                            }`}
                        ></i>

                        <p
                          className="profile-feedback-header-set-text"
                          /* @ts-ignore */
                          style={{ fontWeight: liked ? "500" : "400" }}
                        >
                          {liked
                            ? t("common:Liked")
                            : t("common:Likethisfeedback")}{" "}
                        </p>
                      </div>
                      <div
                        className="profile-feedback-header-set toggle-profile-comment"
                        onClick={(e) =>
                          e.currentTarget?.parentElement?.parentElement?.classList.toggle(
                            "open"
                          )
                        }
                      >
                        <i className="icon-feather-edit"></i>
                        <p className="profile-feedback-header-set-text">
                          {t("common:Comment")}
                        </p>
                      </div>
                      <i className="icon-chevron-down"></i>
                    </div>
                    <div className="profile-feedback-main">
                      <i className="icon-feather-edit"></i>
                      <div className="profile-feedback-main-set">
                        <textarea
                          placeholder={`${t("common:Commentthisfeedback")}`}
                          value={typedComment}
                          onChange={(e) => setTypedComment(e.target.value)}
                        ></textarea>
                        <div className="profile-feedback-main-set-footer">
                          <button
                            className={`button ${commentButtonClass}`}
                            onClick={() => {
                              if (typedComment && user) {
                                sendComment({
                                  feedbackId: selectedFeedback._id,
                                  comment: typedComment,
                                  sender: user._id,
                                  firstname: user.firstname,
                                  lastname: user.lastname,
                                })
                                setTimeout(
                                  () => getTargetHistory(selectedFeedback._id),
                                  500
                                )
                                setCommentButtonClass("sent")
                                setCommentButtonCaption("Sending")
                              }
                            }}
                          >
                            {commentButtonCaption}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile">
                    <div className="profile-footer-wrapper">
                      <div className="profile-footer">
                        <div className="profile-footer-item">
                          <div className="profile-footer-item-main">
                            <div
                              className="profile-footer-item-icon"
                              style={{ backgroundColor: "#ffb100" }}
                            >
                              <i className="icon-feather-chart"></i>
                            </div>
                            <div className="profile-footer-item-set">
                              <p className="profile-footer-item-set-title">
                                {t("common:Feedbackgiven")}
                              </p>
                              <p className="profile-footer-item-set-text">
                                {moment(
                                  dateFromObjectId(selectedFeedback._id)
                                ).fromNow()}
                              </p>
                            </div>
                          </div>
                        </div>

                        {targetHistory?.map((item, i) => (
                          <div
                            className="profile-footer-item"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              e.currentTarget.classList.toggle("open")
                            }
                          >
                            <div className="profile-footer-item-main">
                              <div
                                className="profile-footer-item-icon"
                                style={{
                                  backgroundColor:
                                    item.type === "like"
                                      ? "#12a952"
                                      : "#457cb9",
                                }}
                              >
                                <i
                                  className={
                                    item.type === "like"
                                      ? "icon-feather-thumbs-up"
                                      : "icon-feather-pencil"
                                  }
                                  style={{ marginTop: "-2px" }}
                                ></i>
                              </div>
                              <div className="profile-footer-item-set">
                                <p className="profile-footer-item-set-title">
                                  {item.type === t("common:like")
                                    ? t("common:Liked")
                                    : t("common:CommentedBy")}{" "}
                                  {item.sender.firstname +
                                    " " +
                                    item.sender.lastname}
                                </p>
                                <p className="profile-footer-item-set-text">
                                  {moment(dateFromObjectId(item._id)).fromNow()}
                                </p>
                              </div>
                            </div>
                            <div
                              className="profile-footer-item-comment"
                              id={"comment" + i}
                            >
                              {!!item.comment && (
                                <p className="profile-footer-item-comment-text">
                                  {item.comment}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="profile-placeholder">
                  <p className="profile-placeholder-title">
                    {t("common:Nofeedbackselected")}
                  </p>
                  <p className="profile-placeholder-text">
                    {t("textFeedbackPages:Pleaseselectafeedback")}.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <ReactTooltip
        effect="float"
        place={'bottom'}
        delayShow={250}
        className="custom-tooltip"
      />
      <AddNewQuestion
        category="team"
        teamName={selectedTeam ? selectedTeam.name : ""}
        addQuestion={(type: "value" | "text", question: string) => {
          if (selectedTeam) {
            addQuestion({
              teamId: selectedTeam._id,
              category: "team",
              type,
              question,
            })
          }
        }}
      />
      { !!selectedLocalUser ? <SeeUsersModal profile={selectedLocalUser} onClose={() => selectLocalUser(null)} /> : null}

    </div >
  )
}

export default connector(MyFeedbackText)
