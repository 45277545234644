import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    show: boolean
    onClose(): void
    onAccept(): void
    caption: string
}

const ComfirmationModal: FC<Props> = (props: Props) => {
    const { t } = useTranslation('common');
    const { show, onClose, onAccept, caption } = props
    if (!show) return null
    return (
        <div className="custom-modal-wrapper open" id="confirmationModal">
            <div className="custom-modal">
                <div className="custom-modal-body">
                    <div className="custom-modal-close" close-modal="confirmationModal">
                        <i className="icon-close"></i>
                    </div>
                    <p className="custom-modal-body-title">{t('common:Confirmation')}</p>
                    <p className="custom-modal-body-text">{caption}</p>
                    <div className="custom-modal-body-buttons">
                        <button className="button button-primary" onClick={onAccept}>{t('common:Confirm')}</button>
                        <button className="button button-light-gradient" close-modal="confirmationModal" onClick={onClose}>{t('common:Cancel')}</button>
                    </div>
                </div>
            </div>
            <div className="custom-modal-backdrop"></div>
        </div>
    )
}

export default ComfirmationModal
