import React, { useEffect, useState, useRef } from 'react'
import { Team, User } from 'src/apiTypes'
import { useHistory, useLocation } from 'react-router-dom'
import DropdownTeam from './dropDownTeam'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

interface Props {
  selectedTeam: Team | null
  teams: Team[]
  onQuit(teamId: string): void
  companyId?: string
  user: User | null
}



const HeaderDropdown: React.FC<Props> = (props) => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const drowdownEl = useRef(null)
  const { selectedTeam, teams, onQuit, companyId, user } = props
  useOnClickOutside(drowdownEl, () => setOpen(false))
  const [filteredTeams, setFilteredTeams] = useState<Team[]>([])
  const { t } = useTranslation('common')

  useEffect(() => {
    setFilteredTeams(teams)
  }, [teams])
  function handleToggle() {
    setOpen(!open)
  }

  const filterTeams = (keywords: string) => {
    const newTeams = teams.filter((item) =>
      item.name.toLowerCase().includes(keywords.toLowerCase())
    )
    setFilteredTeams(newTeams)
  }
  const [keyword, setKeyword] = useState('')
  const { push } = useHistory()


  // const toolTipRef = useRef(null);
  // useEffect(() => {
  //   // @ts-ignore
  //   toolTipRef?.current.click()
  //   //ReactTooltip.show((toolTipRef?.current as unknown) as Element);
  // });
  return (
    <>
        <div className={`header-dropdown ${open && 'open'}`} ref={drowdownEl} onClick={() => {
      setKeyword('')
    }}>
                  <div className="tutor-tooltip tutor-tooltip-header-dropdown absolute">
              <div className="tutor-tooltip-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="14" viewBox="0 0 22 14">
                  <path
                    d="M9.821,1.5a1.5,1.5,0,0,1,2.359,0l7.914,10.072A1.5,1.5,0,0,1,18.914,14H3.086a1.5,1.5,0,0,1-1.179-2.427Z"
                    fill="#1177ef" />
                </svg>
              </div>
              <div className="tutor-tooltip-main">
                <p className="tutor-tooltip-main-title">If you want to know more</p>
                <p className="tutor-tooltip-main-text">If you want to know more about a
                  team member just click on the name.</p>
              </div>
            </div>
      <button className="header-dropdown-trigger" onClick={() => setOpen(true)}>
        {/* <h1 data-tip data-for='tutor-tooltip-header-dropdown' data-event="click" ref={toolTipRef}>{ pathname.includes(`/teamfeedback/myFeedback/`) ? 'My feedback' : selectedTeam?.name}</h1> */}
        <h1 >{ pathname.includes(`/teamfeedback/myFeedback/`) ? t('common:Myfeedback') : selectedTeam?.name}</h1>
        <i className="icon-chevron-down"></i>
      </button>

      <div className="header-dropdown-menu">
        <div className="header-dropdown-menu-header">
          <div className="input-container">
            <input
              type="text"
              placeholder={`${t('common:Searchforateam')}`}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value)
                filterTeams(e.target.value)
              }}
            />
            <i className="icon-search"></i>
          </div>
        </div>
        <div className="header-dropdown-menu-main">
          {filteredTeams.map((team) => (
            <DropdownTeam key={team._id} team={team} onClick={() => {
              setOpen(false)
              push(`/team/${team._id}/data/text`)
            }}
            onQuit={() => {
              onQuit(team._id)
            }}
            active={!pathname.includes('/teamfeedback/myFeedback') && String(team._id) === String(selectedTeam?._id)}
            isAdmin={selectedTeam && user ? selectedTeam.admins.includes(user._id) : false}
            />  
          ))}
          
<div
              
              onClick={() => {
                setOpen(false)
                push(`/teamfeedback/myFeedback/feedbackArchive`)
              }}
              className={`header-dropdown-menu-main-item ${pathname.includes('/teamfeedback/myFeedback') ? 'active' : ''
                }`}
            >
              <p className="header-dropdown-menu-main-item-text"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >My feedback</p>
              {/* <span>({t('common:selected')})</span> */}
              {/* <button
                className="header-dropdown-menu-main-item-close"
                onClick={() => onQuit(team._id)}
              >
                <i className="icon-close"></i>
              </button> */}
            </div>

        </div>
        <div className="header-dropdown-menu-footer">
          <button
            className="button button-light-gradient"
            onClick={() =>
              push('/createNewTeam')
            }
          >
            {t('common:Createanewteam')}
          </button>
          {companyId && <button
            className="button button-light-gradient"
            onClick={() =>
              push('/company/overview')
            }
          >
            Company admin area
          </button>}
        </div>
      </div>
    </div>

    <ReactTooltip id="tutor-tooltip-header-dropdown" className='tutor-tooltip' aria-haspopup='true' place="right" effect="solid">
      <p className="tutor-tooltip-title">If you want to know more</p>
      <p className="tutor-tooltip-text">If you want to know more about a team member just click on the name.</p>
    </ReactTooltip>

    </>
  )
}

// Hook
function useOnClickOutside(ref: any, handler: any) {
  useEffect(
    () => {
      //@ts-ignore
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}

export default HeaderDropdown


            /* <div
              key={team._id}
              onClick={() => {
                setOpen(false)
                push(`/team/${team._id}/data/teamFeedback`)
              }}
              className={`header-dropdown-menu-main-item ${ pathname !== '/teamfeedback/myFeedback/feedbackArchive' && String(team._id) === String(selectedTeam?._id) ? 'active' : ''
                }`}
            >
              <p className="header-dropdown-menu-main-item-text"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >{team.name}</p>
              
              <button
                className="header-dropdown-menu-main-item-close"
                onClick={() => onQuit(team._id)}
              >
                <i className="icon-close"></i>
              </button>
            </div> */