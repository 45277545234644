import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { TeamUserRank, User, UserRights } from 'src/apiTypes'
import { IMAGE_API_ROOT } from 'src/request'
import { backgroundCardHeight } from 'src/tables'
import { colorByName, ratingColor, ratingString } from 'src/utitlity'
import { useTranslation } from 'react-i18next'
import SeeUsersModal from '../modals/seeUsersModal'

interface Props {
  data?: User & {
    request?: number
  }
  onClick(): void
  updateJobTitle?(jobtitle: string): void
  removeMember?(type: UserRights): void
  isLeader: boolean
  status: 'Active' | 'Invited',
  addToTeam?(): void
  initials?: string
  deleteInvite?(): void
}

const TeamMemberProfile = (props: Props) => {
  const { t } = useTranslation(['teamMembersPage', 'common'])
  const { data, onClick, updateJobTitle, removeMember, isLeader, status, addToTeam, initials, deleteInvite } = props
  useEffect(() => {
    backgroundCardHeight()
  }, [])

  const [showModal, setShowModal] = useState(false)
  const jobRef = useRef<any>()
  if (!data) return null
  return (
    <tr className="custom-table-row">
      <td className="column-01" onClick={onClick}>
        <div className="table-set modal-trigger" target-modal="modalCard">
          {data?.profilePic ?
            <img onClick={() => setShowModal(true)} src={IMAGE_API_ROOT + data.profilePic} alt={data.firstname + ' ' + data.lastname} className="table-set-initials" />
            : <div
              onClick={() => setShowModal(true)}
              className="table-set-initials"
              style={{ backgroundColor: colorByName(data?.firstname + ' ' + data?.lastname) }}
            >
              {initials ||  (data!.firstname[0] + data!.lastname[0] )}
            
            
            </div>
          }
          <div className="table-set-info">
            <p className="table-set-info-name lead">
              {data?.firstname + ' ' + data?.lastname}{' '}
              <span className="table-set-info-name-icon">
                {data?.isLeader && <i className="icon-crown"></i>}
              </span>
            </p>
            <p className="table-set-info-email">{data?.email}</p>
          </div>
        </div>
      </td>
      <td className="column-02">
        <input
          className="td-input"
          type="text"
          ref={jobRef}
          placeholder={`${t('common:Addjobtitle')}`}
          defaultValue={data?.jobtitle}
          onChange={(e) => {
            if (updateJobTitle) {
              updateJobTitle(e.target.value)
            }
          }}
        />
      </td>
      {data?.isLeader ? <td className="column-03">
        <div className="span leader"></div>
        <p>{t('common:Teamleader')}</p>
      </td> :
        <td className="column-03">
          <div className="span member"></div>
          <p>{t('common:Teammember')}</p>
        </td>
      }

      <td className="column-04">
        <button
          className={`button button-green`}
          style={status === 'Invited' ? {
            backgroundColor: 'orange',
            borderColor: 'orange'
          } : {}}
        >
          {status}
        </button>
      </td>
      <td className="column-05">
        <div className="exp-dropdown">
          <button className="exp-dropdown-trigger" onClick={(e) => e.currentTarget.focus()}>
            <i className="icon-ellipsis"></i>
          </button>
          {status === 'Invited' 
          ? <div className="exp-dropdown-menu">
            <div className="exp-dropdown-menu-item" onClick={deleteInvite}>
            <p className="exp-dropdown-menu-item-text">Delete invite</p>
              <i className="icon-check"></i>
            </div>

            </div>
          :
          <div className="exp-dropdown-menu">
            {data?.isLeader ? <div className="exp-dropdown-menu-item" onClick={() => {
              if (removeMember) {
                removeMember('teamLeaders')
              }
            }}>
              <p className="exp-dropdown-menu-item-text">{t('common:Removeteamleader')}</p>
              <i className="icon-check"></i>
            </div> : <div className="exp-dropdown-menu-item" onClick={addToTeam}>
              <p className="exp-dropdown-menu-item-text">{t('common:Maketeamleader')}</p>
              <i className="icon-check"></i>
            </div>}


            <div className="secondary-dropdown">
              <div className="exp-dropdown-menu-item secondary-dropdown-trigger">
                <i className="icon-chevron-left"></i>
                <p className="exp-dropdown-menu-item-text">{t('common:Giverights')}</p>
                <i className="icon-check"></i>
              </div>

              <div className="secondary-dropdown-menu"
                onMouseOver={(e) => e.currentTarget.parentElement?.classList.add('open')}
                onMouseOut={(e) => e.currentTarget.parentElement?.classList.remove('open')}>
                <div className="secondary-dropdown-menu-item active">
                  <p className="secondary-dropdown-menu-item-text">{t('common:Adminrights')}</p>
                  <i className="icon-check"></i>
                </div>
                <div className="secondary-dropdown-menu-item">
                  <p className="secondary-dropdown-menu-item-text">{t('common:Viewingrights')}</p>
                  <i className="icon-check"></i>
                </div>
              </div>
            </div>
            <div className="exp-dropdown-menu-item" onClick={() => jobRef.current?.focus()}>
              <p className="exp-dropdown-menu-item-text">{t('common:Editjobtitle')}</p>
              <i className="icon-check"></i>
            </div>
            <div className="exp-dropdown-menu-item delete" onClick={() => {
              if (removeMember) {
                removeMember('teamMembers')
              }
            }} >
              <p className="exp-dropdown-menu-item-text">{t('common:Deleteteammember')}</p>
              <i className="icon-check"></i>
            </div>
          </div>}
        </div>
      </td>
      {showModal && <SeeUsersModal profile={data as User} onClose={() => setShowModal(false)} />}
    </tr>
  )
}

export default TeamMemberProfile
