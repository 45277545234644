import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  AddQuestionVariables,
  GetFeedbacksByQuestionIdVariables,
  Question,
  SendCommentVariables,
  TexyAnswerByQuestionId,
  User,
} from 'src/apiTypes'
import { AppDispatch, RootState } from 'src/configureStore'
import {
  getFeedbacksByQuestionId,
  getQuestions,
  getTargetHistory,
  likeUnlikeFeedback,
  markRead,
  sendComment,
  switchChartType,
} from 'src/redux/feedback/actions'
import { colorByName, dateFromObjectId } from 'src/utitlity'
import AddNewQuestion from 'src/components/modals/addNewQuestion'

import moment from 'moment'
import { addQuestion, selectFeedbackSettings } from 'src/redux/teams/actions'
import { IMAGE_API_ROOT } from 'src/request'
import { selectUser } from 'src/redux/auth/actions'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  selectedTeam: state.teamReducer.selectedTeam,
  questions: state.feedbackReducer.questions,
  feedbacks: state.feedbackReducer.feedbackByQuestionId,
  targetHistory: state.feedbackReducer.targetHistory,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getQuestions: (teamId: string, feedbackType: string) =>
    dispatch(getQuestions(teamId, feedbackType)),
  getFeebacks: (data: GetFeedbacksByQuestionIdVariables) =>
    dispatch(getFeedbacksByQuestionId(data)),
  addQuestion: (data: AddQuestionVariables) => dispatch(addQuestion(data)),
  likeUnlikeFeedback: (targetId: string, like: boolean) =>
    dispatch(likeUnlikeFeedback(targetId, like)),
  sendComment: (data: SendCommentVariables) => dispatch(sendComment(data)),
  markRead: (targetId: string, questionId: string) =>
    dispatch(markRead(targetId, questionId)),
  selectUser: (user: User) => dispatch(selectUser(user)),
  getTargetHistory: (targetId: string) => dispatch(getTargetHistory(targetId)),
  switchChartType: () => dispatch(switchChartType('text'))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  getTeamId(teamId: string): void
}

interface ParamTypes {
  teamId?: string
}

const GeneralSettings = (props: Props) => {
  const { t } = useTranslation(["common", 'textFeedbackPages'])
  const { push } = useHistory()
  const { pathname } = useLocation()
  const {
    selectedTeam,
    getTeamId,
    questions,
    getQuestions,
    addQuestion,
    feedbacks,
    getFeebacks,
    likeUnlikeFeedback,
    sendComment,
    user,
    markRead,
    selectUser,
    targetHistory,
    getTargetHistory,
    switchChartType
  } = props
  const [
    selectedFeedback,
    selectFeedback,
  ] = useState<TexyAnswerByQuestionId | null>(null)

  const params = useParams<ParamTypes>()
  useEffect(() => {
    if (params.teamId) {
      getTeamId(params.teamId)
    }
  }, [params])
  useEffect(() => {
    // backgroundCardHeight()
    if (selectedTeam) {
      getQuestions(selectedTeam._id, 'team')
    }
  }, [selectedTeam])
  useEffect(() => {
    if (selectedFeedback) {
      getTargetHistory(selectedFeedback._id)
      markRead(selectedFeedback._id, selectedFeedback._id)
    }
  }, [selectedFeedback])

  useEffect(() => {
    const cardTriggers = document.querySelectorAll('.card-trigger')

    cardTriggers.forEach((el) => {
      el.addEventListener('click', openCard)
    })

    function openCard() {
      // @ts-ignore
      const parent = this.parentElement

      if (document.documentElement.clientWidth < 1100 && document.documentElement.clientWidth > 768) {
        if (parent.classList.contains('closed')) {
          cardTriggers.forEach((el) => {
            //   @ts-ignore
            el.parentElement.classList.add('closed')
          })
        } else {
          cardTriggers.forEach((el) => {
            //   @ts-ignore
            el.parentElement.classList.remove('closed')
          })
        }
      }

      parent.classList.toggle('closed')
    }

    function closeProfileOnSmall() {
      if (document.documentElement.clientWidth < 1100) {
        //   @ts-ignore
        document.querySelector('.content-card.profile')?.classList.add('closed')
      } else {
        //   @ts-ignore
        document
          .querySelector('.content-card.profile')
          ?.classList.remove('closed')
        // @ts-ignore
        document
          .querySelector('.content-card.questions')
          ?.classList.remove('closed')
      }
    }

    closeProfileOnSmall()

    window.addEventListener('resize', closeProfileOnSmall)
  }, [])

  const [selectedQuestion, selectQuestion] = useState<Question | null>(null)
  const [typedComment, setTypedComment] = useState('')
  const [liked, setLiked] = useState(false)
  useEffect(() => {
    if (questions && questions.textQuestions[0] && !selectedQuestion) {
      selectQuestion(questions.textQuestions[0])
    }
    if (feedbacks && feedbacks[0]) {
      //    selectFeedback(feedbacks[0])
      //   setLiked(feedbacks[0].like || false)
    }
  }, [questions])

  useEffect(() => {
    if (feedbacks && questions) {
      if (!feedbacks.length) {
        selectFeedback(null)
      }
    }
  }, [feedbacks, questions])

  useEffect(() => {
    if (selectedQuestion && selectedTeam) {
      getFeebacks({
        teamId: selectedTeam._id,
        questionId: selectedQuestion.questionId,
      })
    }
  }, [selectedTeam, selectedQuestion])

  useEffect(() => {
    if (feedbacks && !feedbacks.length) {
      // selectFeedback(null)
    }
  }, [feedbacks])

  useEffect(() => {
    ReactTooltip.rebuild()
    switchChartType()
  }, [])

  useEffect(() => {
    if (selectedFeedback && selectedQuestion) {
      if (!selectedFeedback.read) {
        markRead(selectedFeedback._id, selectedQuestion?.questionId)
      }
      ReactTooltip.rebuild()
    }
  }, [selectedFeedback])

  const [commentButtonClass, setCommentButtonClass] = useState(
    'button-green-primary'
  )
  const [commentButtonCaption, setCommentButtonCaption] = useState('Add')
  useEffect(() => {
    if (commentButtonCaption === 'Sending') {
      setTimeout(() => {
        setCommentButtonClass('sent')
        setCommentButtonCaption(t("common:Sent"))

      }, 1000)
    }
    if (commentButtonCaption === t("common:Sent")) {
      setTimeout(() => {
        setCommentButtonClass('button-green-primary')
        setCommentButtonCaption('Add')
        setTypedComment('')

      }, 1000)
    }


  }, [commentButtonCaption])
  useEffect(() => {
    if (selectedFeedback) {
      setLiked(selectedFeedback.like || false)
    }
  }, [selectedFeedback])
  return (
    <>
      <div className="content-wrapper">
        <div className="content">
          <div className="widgets-top">
            <div className="option-buttons">
              <button
                className="button"
                onClick={() => {
                  if (selectedTeam) {
                    //@ts-ignore
                    push('/team/' + selectedTeam._id + '/data/teamFeedback')
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <g id="Group_11407" data-name="Group 11407" transform="translate(-41 -87)">
                    <rect id="Rectangle_4145" data-name="Rectangle 4145" width="18" height="18" transform="translate(41 87)" fill="#c3c3c3" opacity="0" />
                    <g id="Icon_feather-bar-chart-2" data-name="Icon feather-bar-chart-2" transform="translate(36.5 84)">
                      <path id="Path_9462" data-name="Path 9462" d="M27,22.5V15" transform="translate(-9 -4.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                      <path id="Path_9463" data-name="Path 9463" d="M18,18V6" transform="translate(-4.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                      <path id="Path_9464" data-name="Path 9464" d="M9,25.5V21" transform="translate(0 -7.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </g>
                  </g>
                </svg>
                {t("common:Charts")}
              </button>
              <button className="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="13.5" viewBox="0 0 16.5 13.5">
                  <g id="Icon_feather-archive" data-name="Icon feather-archive" transform="translate(-0.75 -3.75)">
                    <path id="Path_9459" data-name="Path 9459" d="M16.773,12v8.667H4.5V12" transform="translate(-1.636 -4.167)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Path_9460" data-name="Path 9460" d="M1.5,4.5h15V7.833H1.5Z" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Path_9461" data-name="Path 9461" d="M15,18h2.727" transform="translate(-7.364 -7.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  </g>
                </svg>

              Explorer</button>
              <button className="button active">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <g id="Group_11409" data-name="Group 11409" transform="translate(-228 -86)">
                    <rect id="Rectangle_4143" data-name="Rectangle 4143" width="18" height="18" transform="translate(228 86)" fill="#c3c3c3" opacity="0" />
                    <g id="Icon_feather-settings" data-name="Icon feather-settings" transform="translate(229 87)">
                      <path id="Path_9465" data-name="Path 9465" d="M17.864,15.682A2.182,2.182,0,1,1,15.682,13.5,2.182,2.182,0,0,1,17.864,15.682Z" transform="translate(-7.682 -7.682)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                      <path id="Path_9466" data-name="Path 9466" d="M14.882,11.682a1.2,1.2,0,0,0,.24,1.324l.044.044a1.455,1.455,0,1,1-2.058,2.058l-.044-.044a1.21,1.21,0,0,0-2.051.858v.124a1.455,1.455,0,0,1-2.909,0V15.98a1.2,1.2,0,0,0-.785-1.1,1.2,1.2,0,0,0-1.324.24l-.044.044a1.455,1.455,0,1,1-2.058-2.058l.044-.044a1.21,1.21,0,0,0-.858-2.051H2.955a1.455,1.455,0,0,1,0-2.909H3.02a1.2,1.2,0,0,0,1.1-.785,1.2,1.2,0,0,0-.24-1.324l-.044-.044A1.455,1.455,0,1,1,5.893,3.893l.044.044a1.2,1.2,0,0,0,1.324.24h.058a1.2,1.2,0,0,0,.727-1.1V2.955a1.455,1.455,0,1,1,2.909,0V3.02a1.21,1.21,0,0,0,2.051.858l.044-.044a1.455,1.455,0,1,1,2.058,2.058l-.044.044a1.2,1.2,0,0,0-.24,1.324v.058a1.2,1.2,0,0,0,1.1.727h.124a1.455,1.455,0,0,1,0,2.909H15.98a1.2,1.2,0,0,0-1.1.727Z" transform="translate(-1.5 -1.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    </g>
                  </g>
                </svg>
                {t("common:Text")}</button>
            </div>
          </div>

          <div className="text-feedback-content">
            <div className="content-card questions">
              <i className="icon-chevron-left card-trigger"></i>
              <div className="questions-header">
                <div className="custom-dropdown">
                  <button className="custom-dropdown-trigger questions-header-title">Team Feedback <i className="icon-chevron-down"></i></button>
                  <div className="custom-dropdown-menu">
                    <button className="custom-dropdown-menu-item checked">
                      <i className="icon-check"></i>
                      <p className="custom-dropdown-menu-item-text">Team Feedback</p>
                    </button>
                    <button className="custom-dropdown-menu-item">
                      <i className="icon-check"></i>
                      <p className="custom-dropdown-menu-item-text">P2P Feedback</p>
                    </button>
                    <button className="custom-dropdown-menu-item">
                      <i className="icon-check"></i>
                      <p className="custom-dropdown-menu-item-text">Self evaluation</p>
                    </button>
                    <button className="custom-dropdown-menu-item">
                      <i className="icon-check"></i>
                      <p className="custom-dropdown-menu-item-text">Feedback about the team lead</p>
                    </button>
                    <button className="custom-dropdown-menu-item">
                      <i className="icon-check"></i>
                      <p className="custom-dropdown-menu-item-text">Subordinate feedback</p>
                    </button>
                  </div>
                </div>
                <p className="questions-header-text">3 Text feedback questions</p>
              </div>
              <div className="questions-main">
                {questions?.textQuestions?.map((item) => (
                  <div
                    className={`question ${selectedQuestion?.questionId === item.questionId
                      ? 'active'
                      : ''
                      }`}
                    onClick={() => selectQuestion(item)}
                  >
                    <p className="question-text">{item.question}</p>
                    <span className="question-amount">
                      {!!item.unread && item.unread}
                    </span>
                  </div>
                ))}
                <button
                  className="add-new-question"
                  onClick={() => {
                    if (selectedTeam) {
                      document
                        .getElementById('newQuestionModal')
                        ?.classList.add('open')
                    }
                  }}
                >
                  +{' '}{t("textFeedbackPages:Addnewquestion")}
                </button>
              </div>
            </div>

            <div className="content-card answers">
              <div className="answers-header">
                <p className="answers-header-title">
                  {selectedQuestion?.question}
                </p>
                <p className="answers-header-amount">
                  {selectedQuestion?.unread
                    ? selectedQuestion.unread + ` ${t('common:newfeedback')}`
                    : t('common:Nonewfeedback')}
                </p>
                <svg data-tip={`${t('common:Markallread')}`} xmlns="http://www.w3.org/2000/svg" width="17.01" height="16.763" viewBox="0 0 17.01 16.763">
                  <g transform="translate(-2.051 -2.081)" opacity="0.327">
                    <path d="M18,9.805v.69A7.5,7.5,0,1,1,13.552,3.64" fill="none" stroke="#000" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5" />
                    <path d="M23.25,6l-7.5,7.507-2.25-2.25" transform="translate(-5.25 -1.505)" fill="none" stroke="#000"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                  </g>
                </svg>
              </div>

              <div className="answers-main">
                {feedbacks?.length ? (
                  feedbacks?.map((feed) => (
                    <div className="answer-wrapper">
                      <div
                        className={`answer ${selectedFeedback?._id === feed._id ? 'active' : ''
                          }`}
                        onClick={() => selectFeedback(feed)}
                      >
                        {feed.sender.profilePic ? (
                          <img
                            src={
                              IMAGE_API_ROOT +
                              feed.sender.profilePic.replace('resized', 'thumb')
                            }
                            alt="User"
                            className="answer-img"
                            onClick={() => {
                              selectUser(feed.sender)
                              document
                                .getElementById('usersModal')
                                ?.classList.add('open')
                            }}
                          ></img>
                        ) : (
                          <div
                            onClick={() => {
                              selectUser(feed.sender)
                              document
                                .getElementById('usersModal')
                                ?.classList.add('open')
                            }}
                            className="answer-initials"
                            style={{
                              backgroundColor: colorByName(
                                feed.sender.firstname + ' ' + feed.sender.lastname
                              ),
                            }}
                          >
                            {feed.sender.firstname[0] + feed.sender.lastname[0]}
                          </div>
                        )}
                        <div className="answer-set">
                          <div className="answer-set-header">
                            <span className="answer-set-header-circle"></span>
                            <p className="answer-set-header-name">
                              {feed.sender.firstname + ' ' + feed.sender.lastname}
                            </p>
                            <div className="answer-set-header-info">
                              <div className="answer-set-header-info-item">
                                <img src="/img/clock-icon.png" alt="Clock" className="answer-set-header-info-item-img" />
                                <p className="answer-set-header-info-item-text">{moment(dateFromObjectId(feed._id)).fromNow()}</p>
                              </div>

                              <div className="answer-set-header-info-item">
                                <img src="/img/like-icon.png" alt="Like" className="answer-set-header-info-item-img" />
                                <p className="answer-set-header-info-item-text">7</p>
                              </div>

                              <div className="answer-set-header-info-item">
                                <img src="/img/edit-icon.png" alt="Edit" className="answer-set-header-info-item-img" />
                                <p className="answer-set-header-info-item-text">5</p>
                              </div>
                            </div>
                          </div>
                          <p className="answer-set-text">{feed.value}</p>
                        </div>
                      </div>
                      <div className="comments">
                        <div className="answer">
                          <img src="/img/p-01.png" alt="User" className="answer-img" />
                          <div className="answer-set">
                            <div className="answer-set-header">
                              <span className="answer-set-header-circle"></span>
                              <p className="answer-set-header-name">Julia Martinez</p>
                              <div className="answer-set-header-info">
                                <div className="answer-set-header-info-item">
                                  <img src="/img/clock-icon.png" alt="Clock" className="answer-set-header-info-item-img" />
                                  <p className="answer-set-header-info-item-text">2 hours ago</p>
                                </div>

                                <div className="answer-set-header-info-item">
                                  <img src="/img/like-icon.png" alt="Like" className="answer-set-header-info-item-img" />
                                  <p className="answer-set-header-info-item-text">7</p>
                                </div>

                                <div className="answer-set-header-info-item">
                                  <img src="/img/edit-icon.png" alt="Edit" className="answer-set-header-info-item-img" />
                                  <p className="answer-set-header-info-item-text">5</p>
                                </div>
                              </div>
                            </div>
                            <p className="answer-set-text">
                              <i className="icon-feather-thumbs-up"></i>
                        This is your first ticket. Ta-da! Any customer request sent to your supported channels
                        (email,chat, voicemail,web form,and tweet).
                      </p>
                          </div>
                        </div>
                      </div>
                    </div>

                  ))
                ) : (
                  <div className="answers-placeholder">
                    <img
                      src="/img/table-placeholder-img-04.png"
                      alt="Clip art"
                      className="answers-placeholder-img"
                    ></img>
                    <p className="answers-placeholder-title">{t('common:Nodatayet')}</p>
                    <p className="answers-placeholder-text" style={{ maxWidth: '30rem' }}>
                      {t("common:Thequestion")}{' '}<span>’{selectedQuestion?.question}’</span>{' '}{t("textFeedbackPages:hasnotyetbeenansweredbyanyteammember")}.
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="content-card profile">
              <i className="icon-chevron-right card-trigger"></i>

              <div className="profile-header">
                <div className="profile-header-set">
                  {!!selectedFeedback ? (
                    <>
                      {selectedFeedback.sender.profilePic ? (
                        <img
                          src={
                            IMAGE_API_ROOT +
                            selectedFeedback.sender.profilePic.replace(
                              'resized',
                              'thumb'
                            )
                          }
                          alt="User"
                          className="profile-header-set-img"
                          onClick={() => {
                            selectUser(selectedFeedback.sender)
                            document
                              .getElementById('usersModal')
                              ?.classList.add('open')
                          }}
                        ></img>
                      ) : (
                        <div
                          onClick={() => {
                            selectUser(selectedFeedback.sender)
                            document
                              .getElementById('usersModal')
                              ?.classList.add('open')
                          }}
                          className="profile-header-set-initials"
                          style={{
                            backgroundColor: colorByName(
                              selectedFeedback.sender.firstname +
                              ' ' +
                              selectedFeedback.sender.lastname
                            ),
                          }}
                        >
                          {selectedFeedback.sender.firstname[0] +
                            selectedFeedback.sender.lastname[0]}
                        </div>
                      )}

                      <div className="profile-header-set-info">
                        <p className="profile-header-set-info-name">
                          {selectedFeedback.sender.firstname +
                            ' ' +
                            selectedFeedback.sender.lastname}
                        </p>
                        <p className="profile-header-set-info-position"> <i className="icon-feather-briefcase"></i> {selectedFeedback.sender.jobtitle || t('common:Nojobtitle')}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="profile-header-set-icon"><i className="icon-user"></i></div>
                      <p className="profile-header-set-name" style={{ textDecoration: "none" }}>{t("common:Nofeedbackselected")}</p>
                    </>
                  )}

                </div>
              </div>
              {!!selectedFeedback ? (
                <>

                  <div className="profile-feedback open">
                    <div className="profile-feedback-header">
                      <div
                        className="profile-feedback-header-set"
                        onClick={() => {
                          if (user && !selectedFeedback.like) {
                            likeUnlikeFeedback(
                              selectedFeedback._id,
                              selectedFeedback.like || false
                            )
                            setTimeout(
                              () => getTargetHistory(selectedFeedback._id),
                              500
                            )
                            setLiked(!liked)
                          }


                        }}
                      >
                        <i className={`icon-feather-thumbs-up ${liked && "liked"}`}></i>
                        {/* @ts-ignore */}
                        <p className="profile-feedback-header-set-text" style={{ fontWeight: liked ? "500" : "400" }}>{liked ? t("common:Liked") : t("common:Likethisfeedback")} </p>
                      </div>
                      <i className="icon-chevron-down"></i>
                    </div>
                    <div className="profile-feedback-main">
                      <i className="icon-feather-edit"></i>
                      <div className="profile-feedback-main-set">
                        <textarea
                          value={typedComment}
                          placeholder={`${t('common:Commentthisfeedback')}`}
                          onChange={(e) => setTypedComment(e.target.value)}
                        ></textarea>
                        <div className="profile-feedback-main-set-footer">
                          <button
                            className={`button ${commentButtonClass}`}
                            onClick={() => {
                              if (typedComment && user) {
                                sendComment({
                                  feedbackId: selectedFeedback._id,
                                  comment: typedComment,
                                  sender: user._id,
                                  firstname: user.firstname,
                                  lastname: user.lastname,
                                })
                                setTimeout(
                                  () => getTargetHistory(selectedFeedback._id),
                                  500
                                )
                                setCommentButtonClass('sent')
                                setCommentButtonCaption('Sending')
                              }
                            }}
                          >
                            {commentButtonCaption}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile-footer-wrapper">
                    <div className="profile-footer">
                      <p className="profile-footer-title">LIKED BY</p>
                      <div className="profile-footer-person">
                        <div className="profile-footer-person-initials">WW</div>
                        <div className="profile-footer-person-set">
                          <p className="profile-footer-person-set-title">Feedback given</p>
                          <p className="profile-footer-person-set-text">2 hours ago</p>
                        </div>
                      </div>
                      <div className="profile-footer-person">
                        <img src="/img/p-01.png" alt="" className="profile-footer-person-img" />
                        <div className="profile-footer-person-set">
                          <p className="profile-footer-person-set-title">Feedback given</p>
                          <p className="profile-footer-person-set-text">2 hours ago</p>
                        </div>
                      </div>
                      {/* <div className="profile-footer-item">
                        <div className="profile-footer-item-main">
                          <div
                            className="profile-footer-item-icon"
                            style={{ backgroundColor: '#ffb100' }}
                          >
                            <i className="icon-feather-chart"></i>
                          </div>
                          <div className="profile-footer-item-set">
                            <p className="profile-footer-item-set-title">
                              {t("common:Feedbackgiven")}
                            </p>
                            <p className="profile-footer-item-set-text">
                              {moment(
                                dateFromObjectId(selectedFeedback._id)
                              ).fromNow()}
                            </p>
                          </div>
                        </div>
                      </div> */}

                      {/* {targetHistory?.map((item) => (
                        <div className="profile-footer-item" style={{ cursor: 'pointer' }} onClick={(e) => e.currentTarget.classList.toggle('open')}>
                          <div className="profile-footer-item-main">
                            <div
                              className="profile-footer-item-icon"
                              style={{
                                backgroundColor:
                                  item.type === 'like' ? '#12a952' : '#457cb9',
                              }}
                            >
                              <i
                                className={
                                  item.type === 'like'
                                    ? 'icon-feather-thumbs-up'
                                    : 'icon-feather-pencil'
                                }
                                style={{ marginTop: '-2px' }}
                              ></i>
                            </div>
                            <div className="profile-footer-item-set">
                              <p className="profile-footer-item-set-title">
                                {item.type === t("common:like") ? t("common:Liked") : t("common:CommentedBy")}{' '}{' '}
                                {item.sender.firstname +
                                  ' ' +
                                  item.sender.lastname}
                              </p>
                              <p className="profile-footer-item-set-text">
                                {moment(dateFromObjectId(item._id)).fromNow()}
                              </p>
                            </div>

                          </div>
                          <div className="profile-footer-item-comment" id={'comment'} >
                            {!!item.comment && <p className="profile-footer-item-comment-text">
                              {item.comment}
                            </p>}
                          </div>
                        </div>
                      ))} */}
                      {/* <div className="profile-footer-item active">
                                        <div className="profile-footer-item-icon" style={{ backgroundColor: "#12a952" }}>
                                            <i className="icon-feather-thumbs-up" style={{ marginTop: '-2px' }}></i>
                                        </div>
                                        <div className="profile-footer-item-set">
                                            <p className="profile-footer-item-set-title">Liked by Johan Smith</p>
                                            <p className="profile-footer-item-set-text">2 hours ago</p>
                                        </div>
                                    </div>
                                    <div className="profile-footer-item">
                                        <div className="profile-footer-item-icon" style={{ backgroundColor: "#457cb9" }}>
                                            <i className="icon-feather-pencil"></i>
                                        </div>
                                        <div className="profile-footer-item-set">
                                            <p className="profile-footer-item-set-title">Commented by Viljami Lahtinen</p>
                                            <p className="profile-footer-item-set-text">2 hours ago</p>
                                        </div>
                                    </div> */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="profile-placeholder">
                  <p className="profile-placeholder-title">
                    {t("common:Nofeedbackselected")}
                  </p>
                  <p className="profile-placeholder-text">
                    {t("textFeedbackPages:Pleaseselectafeedback")}.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddNewQuestion
          category="team"
          teamName={selectedTeam ? selectedTeam.name : ''}
          addQuestion={(type: 'value' | 'text', question: string) => {
            if (selectedTeam) {
              addQuestion({
                teamId: selectedTeam._id,
                category: 'team',
                type,
                question,
              })
            }
          }}
        />
      </div>
      <ReactTooltip
        effect="float"
        place={'bottom'}
        delayShow={250}
        className="custom-tooltip"
      />
      <ReactTooltip
        globalEventOff="click"
        id="commentTooltip"
        effect="solid"
        place={'left'}
        delayShow={250}
        className="custom-tooltip"
        aria-haspopup="true"
      >
        <p className="comment-tooltip-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
          vitae lectus vel lorem sollicitudin fringilla quis eu massa. Mauris
          imperdiet ipsum justo, in sagittis nulla rutrum ac. Quisque at tempus
          dolor, ut varius leo. Suspendisse lobortis lorem ante, quis accumsan
          tellus dictum in.
        </p>
      </ReactTooltip>
    </>
  )
}

export default connector(GeneralSettings)