import React, { FC, useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { InviteUserVariables, User, UserRights } from "src/apiTypes"
import { AppDispatch, RootState } from "src/configureStore"
import { addToTeam, getUserSuggestion, inviteUser } from "src/redux/teams/actions"
import { IMAGE_API_ROOT } from "src/request"
import { validateEmail } from "src/utitlity"
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state: RootState) => ({
  userSuggestions: state.teamReducer.userSuggestions,
  selectedTeam: state.teamReducer.selectedTeam,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUserSuggestion: (data: { teamId: string; keyword: string }) =>
    dispatch(getUserSuggestion(data)),
  addToTeam: (
    data: {
      teamId: string
      userId: string
      type: UserRights
    },
    user: User
  ) => dispatch(addToTeam(data, user)),
  inviteUser: (data: InviteUserVariables) => dispatch(inviteUser(data)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  show: boolean
  onClose(): void
}

const AddNewRight: FC<Props> = (props) => {
  const { t } = useTranslation(['common', 'addRightsModal'])
  const {
    show,
    onClose,
    getUserSuggestion,
    userSuggestions,
    selectedTeam,
    addToTeam,
    inviteUser
  } = props
  const [keyword, setKeyword] = useState("")
  const [selectedUser, selectUser] = useState<User | null>(null)
  const [rightsType, setRightsType] = useState<'admin' | 'viewing'>('viewing')
  return (
    <div
      className={`custom-modal-wrapper ${show ? "open" : ""}`}
      id="addNewRightModal"
    >
      <div className="custom-modal">
        <div className="custom-modal-body">
          <div className="custom-modal-close" close-modal="addNewRightModal" onClick={onClose} >
            <i className="icon-close"></i>
          </div>

          <p className="custom-modal-body-title">{t('common:Addnewrights')}</p>

          {!selectedUser && <p className="custom-modal-body-label">{t('common:Searchorenteremail')}</p>}
          {!selectedUser && (
            <div className="input-container">
              <input
                type="text"
                placeholder={`${t('common:Enteremail')}`}
                onChange={(e) => {
                  if (selectedTeam) {
                    getUserSuggestion({
                      teamId: selectedTeam._id,
                      keyword: e.target.value,
                    })
                  }
                  setKeyword(e.target.value)
                }}
              />

              {keyword && !!userSuggestions?.length && (
                <div className="dropdown-menu">
                  {userSuggestions?.map((item) => (
                    <div className="dropdown-menu-item" key={item._id}>
                      {item.profilePic ? (
                        <img
                          src={IMAGE_API_ROOT + item.profilePic}
                          alt="User"
                          className="dropdown-menu-item-img"
                        />
                      ) : (
                        <div className="dropdown-menu-item-initials">
                          {item.firstname[0] + item.lastname[0]}
                        </div>
                      )}

                      <div className="dropdown-menu-item-set">
                        <p className="dropdown-menu-item-set-title">
                          {item.firstname + " " + item.lastname}
                        </p>
                        <p className="dropdown-menu-item-set-text">
                          {item.jobtitle || "Job title missing"}
                        </p>
                      </div>
                      <button
                        className="button link"
                        onClick={() => selectUser(item)}
                      >
                        {t('common:Add')}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {!!selectedUser && (
            <div className="dropdown-menu-item single">
              {selectedUser?.profilePic ? (
                <img
                  src={IMAGE_API_ROOT + selectedUser.profilePic}
                  alt="User"
                  className="dropdown-menu-item-img"
                />
              ) : (
                <div className="dropdown-menu-item-initials">
                  {selectedUser.firstname[0] + selectedUser.lastname[0]}
                </div>
              )}

              <div className="dropdown-menu-item-set">
                <p className="dropdown-menu-item-set-title">
                  {selectedUser.firstname + " " + selectedUser.lastname}
                </p>
                <p className="dropdown-menu-item-set-text">
                  {selectedUser.jobtitle || "Job title missing"}
                </p>
              </div>
              <button
                className="button link"
                onClick={() => selectUser(null)}
              >
                {t('common:Remove')}
              </button>
            </div>
          )}

          <p className="custom-modal-body-label">{t('addRightsModal:Selecttypeofrights')}</p>

          <div className="rights-type">
            <button className={`button ${rightsType === 'viewing' ? 'active' : ''}`} onClick={() => setRightsType('viewing')}>
              <i className="icon-eye-open"></i>{t('common:Viewingrights')}
            </button>
            <button className={`button ${rightsType === 'admin' ? 'active' : ''}`} onClick={() => setRightsType('admin')}>
              <i className="icon-tools"></i>{t('common:Adminrights')}
            </button>
          </div>

          <p className="custom-modal-body-text">
            {t('addRightsModal:Userswithviewingrights')}.
          </p>

          <div className="custom-modal-body-buttons">
            <button className="button button-green-primary" onClick={() => {
              if (selectedUser) {
                if (selectedTeam) {
                  addToTeam({
                    teamId: selectedTeam._id,
                    userId: selectedUser._id,
                    type: rightsType === 'admin' ? 'admins' : 'viewingRights'
                  }, selectedUser)
                }
                if (onClose) {
                  onClose()
                  setKeyword('')
                  selectUser(null)
                }

              } else {
                if (validateEmail(keyword)) {
                  if (selectedTeam) {
                    inviteUser({
                      type: rightsType === 'admin' ? 'Admin rights' : 'Viewing rights',
                      teamId: selectedTeam._id,
                      invitedUser: keyword
                    })
                  }
                  if (onClose) {
                    onClose()
                    setKeyword('')
                    selectUser(null)
                  }

                } else {
                  window.alert('Invalid email')
                }
              }
            }}>{t('common:Confirm')}</button>
            <button className="button button-light-gradient" onClick={onClose}>
              {t('common:Cancel')}
            </button>
          </div>
        </div>
      </div>
      <div className="custom-modal-backdrop"></div>
    </div>
  )
}

export default connector(AddNewRight)
