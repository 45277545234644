import React, { useEffect, useState } from 'react'
import { QuestionByCategory } from 'src/apiTypes'
import { capitalize } from 'src/utitlity'
import { useTranslation } from 'react-i18next'
import TooltipIcon from '../common/tooltipIcon'
import ReactTooltip from 'react-tooltip'

interface Props {
  open: boolean
  question: QuestionByCategory,
  scrollOnFocus(item?: any): void,
  onOpen(): void,
  editQuestion(question?: string, questionData?: string): void,
  deleteQuestion(): void,
}

const CustomQuestion = (props: Props) => {
  const { t } = useTranslation(['feedbackInputPage', 'common'])
  const { open, question, onOpen, editQuestion, deleteQuestion, scrollOnFocus } = props

  console.log(question)

  return (
    <>
      <div
        className={`custom-question-wrapper custom-accordion-card ${open ? 'open' : ''
          } card-01`}
      >
        <div
          className="custom-question "
          accordion-card-trigger
          accordion-trigger-target="card-01"
          onClick={onOpen}
        >
          <div className="custom-question-icon">
            <i
              className={
                question.type === 'value' ? 'icon-star' : 'icon-chat'
              }
            ></i>
          </div>
          <p className="custom-question-text">{capitalize(question.question)}</p>
          <div className="custom-question-buttons">
            <svg
              className="chevron-down-circle"
              style={{ marginRight: '4px' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g transform="translate(-815.784 -315.402)">
                <g
                  transform="translate(815.784 315.402)"
                  fill="none"
                  stroke="#0d1826"
                  stroke-width="1.5"
                  opacity="0.798"
                >
                  <circle cx="12" cy="12" r="12" stroke="none" />
                  <circle cx="12" cy="12" r="11.25" fill="none" />
                </g>
                <path
                  d="M12.44,16.239l4.726-4.73a.89.89,0,0,1,1.262,0,.9.9,0,0,1,0,1.265l-5.355,5.359a.892.892,0,0,1-1.232.026L6.448,12.778A.893.893,0,1,1,7.71,11.513Z"
                  transform="translate(815.293 313.754)"
                  fill="#0d1826"
                  opacity="0.797"
                />
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
              <g transform="translate(-815.784 -317.402)">
                <g transform="translate(815.784 317.402)" fill="none" stroke="#0d1826" stroke-width="1.5"
                  opacity="0.803">
                  <circle cx="13" cy="13" r="13" stroke="none" />
                  <circle cx="13" cy="13" r="12.25" fill="none" />
                </g>
                <g transform="translate(823.115 323.665)">
                  <path
                    d="M5.767,16.5h8.868A1.267,1.267,0,0,1,15.9,17.767V22.2a1.267,1.267,0,0,1-1.267,1.267H5.767A1.267,1.267,0,0,1,4.5,22.2V17.767A1.267,1.267,0,0,1,5.767,16.5Z"
                    transform="translate(-4.5 -10.8)" fill="none" stroke="#0d1826" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="1.5" opacity="0.803" />
                  <path d="M10.5,8.7V6.167a3.167,3.167,0,1,1,6.334,0V8.7" transform="translate(-7.966 -3)"
                    fill="none" stroke="#0d1826" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    opacity="0.803" />
                </g>
              </g>
            </svg>

            <svg data-tip data-for='tutor-tooltip-lock-icon' opacity="0.803" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26"><g transform="translate(-815.784 -317.402)"><g transform="translate(815.784 317.402)" fill="none" stroke="#0d1826" stroke-width="1.5"><circle cx="13" cy="13" r="13" stroke="none" /><circle cx="13" cy="13" r="12.25" fill="none" /></g><g transform="translate(818.584 320.673)"><path d="M5.767,16.5h8.867A1.267,1.267,0,0,1,15.9,17.767V22.2a1.267,1.267,0,0,1-1.267,1.267H5.767A1.267,1.267,0,0,1,4.5,22.2V17.767A1.267,1.267,0,0,1,5.767,16.5Z" transform="translate(0 -7.805)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" /><path d="M10.5,8.7V6.162a3.167,3.167,0,0,1,6.27-.633" transform="translate(-3.467 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" /></g></g></svg>

            <svg
              className="menu-cicle"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g transform="translate(-844.984 -317.402)">
                <g
                  transform="translate(851.106 325.693)"
                  fill="#fff"
                  stroke="#0d1826"
                  stroke-width="1"
                  opacity="0.803"
                >
                  <rect width="11.962" height="1.553" rx="0.777" stroke="none" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="10.962"
                    height="0.553"
                    rx="0.277"
                    fill="none"
                  />
                </g>
                <g
                  transform="translate(851.106 329.238)"
                  fill="#fff"
                  stroke="#0d1826"
                  stroke-width="1"
                  opacity="0.803"
                >
                  <rect width="11.962" height="1.553" rx="0.777" stroke="none" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="10.962"
                    height="0.553"
                    rx="0.277"
                    fill="none"
                  />
                </g>
                <g
                  transform="translate(851.106 332.783)"
                  fill="#fff"
                  stroke="#0d1826"
                  stroke-width="1"
                  opacity="0.803"
                >
                  <rect width="11.962" height="1.553" rx="0.777" stroke="none" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="10.962"
                    height="0.553"
                    rx="0.277"
                    fill="none"
                  />
                </g>
                <g
                  transform="translate(844.984 317.402)"
                  fill="none"
                  stroke="#0d1826"
                  stroke-width="1.5"
                  opacity="0.803"
                >
                  <circle cx="12" cy="12" r="12" stroke="none" />
                  <circle cx="12" cy="12" r="11.25" fill="none" />
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className={`custom-question-body custom-accordion-card-body ${question.level === 'company' ? 'disabled' : question.level === 'startQuestion' ? 'disabled-delete' : ''}`}>
          <div className="translation">
            <p className="custom-question-body-label">{t('common:Question')}</p>
            <div className="custom-input-group">
              {/* <div className="custom-dropdown">
                      <button className="custom-dropdown-trigger" tabIndex={-1}
                        //onfocus="this.parentElement.parentElement.parentElement.parentElement.classList.add('no-overflow')"
                        //onfocusout="this.parentElement.parentElement.parentElement.parentElement.classList.remove('no-overflow')"
                        >
                        <p className="custom-dropdown-trigger-value">EN</p>
                        <i className="icon-caret-down"></i>
                      </button>
                      <div className="custom-dropdown-menu">
                        <div className="custom-dropdown-menu-item active">
                          <img src="../public//img/usa-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">English</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/fin-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Finnish</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/srb-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Serbian</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/ind-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Hindu</p>
                        </div>
                      </div>
                    </div> */}
              <input
                type="text"
                onChange={(e) => editQuestion(e.target.value)}
                value={question.question}
                disabled={/startQuestion|company/.test(question.level)}
                placeholder={`${t('common:Enterquestion')}...`}
                style={{ borderRadius: '4px' }}
                onClick={(e) => scrollOnFocus(e.currentTarget)}
              />
              <span>60</span>
            </div>
            {/* <div className="custom-input-group">
            <div className="custom-dropdown">
                      <button className="custom-dropdown-trigger"  tabIndex={-1}
                       // onfocus="this.parentElement.parentElement.parentElement.parentElement.classList.add('no-overflow')"
                       // onfocusout="this.parentElement.parentElement.parentElement.parentElement.classList.remove('no-overflow')"
                        >
                        <p className="custom-dropdown-trigger-value">EN</p>
                        <i className="icon-caret-down"></i>
                      </button>
                      <div className="custom-dropdown-menu">
                        <div className="custom-dropdown-menu-item active">
                          <img src="../public//img/usa-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">English</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/fin-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Finnish</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/srb-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Serbian</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/ind-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Hindu</p>
                        </div>
                      </div>
                    </div>
            <input type="text" value="How do people feel working in this team?" placeholder="Enter question..." />
          </div> */}
          </div>

          {/* <button className="custom-question-body-info">+ Add a translation</button> */}

          <div className="short">
            <p className="custom-question-body-label">{t('common:Shortform')} <TooltipIcon text="Short form" /></p>
            <div className="custom-input-group">
              {/* <div className="custom-dropdown">
                      <button className="custom-dropdown-trigger"  tabIndex={-1}
                        //onFocus={ (e) => e?.parentElement?.parentElement.parentElement.parentElement.classList.add('no-overflow')}
                        //onFocusOut="this.parentElement.parentElement.parentElement.parentElement.classList.remove('no-overflow')"
                        >
                        <p className="custom-dropdown-trigger-value">EN</p>
                        <i className="icon-caret-down"></i>
                      </button>
                      <div className="custom-dropdown-menu">
                        <div className="custom-dropdown-menu-item active">
                          <img src="../public//img/usa-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">English</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/fin-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Finnish</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/srb-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Serbian</p>
                        </div>
                        <div className="custom-dropdown-menu-item">
                          <img src="../public/img/ind-flag.png" alt="Flag" className="custom-dropdown-menu-item-img" />
                          <p className="custom-dropdown-menu-item-text">Hindu</p>
                        </div>
                      </div>
                    </div> */}
              <input
                type="text"
                onChange={(e) => editQuestion(undefined, e.target.value)}
                defaultValue={question.questionOutput}
                placeholder={`${t('common:Enterquestion')}...`}
                disabled={/startQuestion|company/.test(question.level)}
                style={{ borderRadius: '3px' }}
                onClick={(e) => scrollOnFocus(e.currentTarget)}
              />
              <span>60</span>
            </div>
          </div>

          <div className="custom-question-body-footer">
            {question.level !== 'company' ? (
              <>
                <button
                  className="button button-light-gray"
                  onClick={deleteQuestion}
                >
                  {t('common:Deletequestion')}
                </button>
                <button className="button button-green-primary" onClick={onOpen}>
                  {t('common:Savechanges')}
                </button>
              </>
            ) : (
              <p className="custom-question-body-footer-text">This is a company level question which can not be edited.</p>
            )}

          </div>
        </div>
      </div>

      <ReactTooltip id="tutor-tooltip-lock-icon" className='custom-tooltip' aria-haspopup='true' place="bottom" effect="solid">
        <p className="custom-tooltip-title">What does this lock mean</p>
        <p className="custom-tooltip-text">If your team is linked to a company,
        and the company defines question
        which  can't be edited they are
        marked with the lock symbol.</p>
      </ReactTooltip>
    </>
  )
}

export default CustomQuestion