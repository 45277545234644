import React, { useEffect, useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { AppDispatch, RootState } from "src/configureStore"
import { requestPasswordResetCode, clearSingnUp, verifyPasswordResetCode, passwordReset } from 'src/redux/auth/actions'
import Loader from 'src/components/loaders'
import { CheckVerificationCodeVariables } from '../../apiTypes'
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  resetCodeSent: state.authReducer.resetCodeSent,
  error: state.authReducer.requestPasswordError,
  loading: state.authReducer.sigininin,
  verifyCodeError: state.authReducer.verifyCodeError,
  emailVerified: state.authReducer.emailVerified,
  passwordReseted: state.authReducer.passwordReseted,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  requestCode: (email: string) => dispatch(requestPasswordResetCode(email)),
  clearSignup: () => dispatch(clearSingnUp()),
  verifyCode: (data: CheckVerificationCodeVariables) =>
    dispatch(verifyPasswordResetCode(data)),
  resetPassword: (
    data: CheckVerificationCodeVariables & { password: string }
  ) => dispatch(passwordReset(data)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

const ResetPassword = (props: PropsFromRedux) => {
  const { t } = useTranslation(['authPages', 'changePasswordModal', 'common']);
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { requestCode, clearSignup, resetCodeSent, error, loading, verifyCode, verifyCodeError, emailVerified, resetPassword, passwordReseted } = props
  const [showPassword01, setShowPassword01] = useState(false)
  const [showPassword02, setShowPassword02] = useState(false)
  const { push } = useHistory()

  useEffect(() => {
    if (passwordReseted) {
      push('/')
    }
  }, [passwordReseted])
  useEffect(() => {
    if (error) {
      if (window.confirm(error)) {
        clearSignup()
      }
    }
  }, [error])
  useEffect(() => {
    if (verifyCodeError) {
      if (window.confirm(verifyCodeError)) {
        clearSignup()
      }
    }
  }, [verifyCodeError])
  useEffect(() => {
    if (resetCodeSent) {
      setStep(2)
    }
  }, [resetCodeSent])

  useEffect(() => {
    if (code.length === 4) {
      verifyCode({
        email,
        code
      })
    }
  }, [code])

  useEffect(() => {
    if (emailVerified) {
      setStep(3)
    }
  }, [emailVerified])


  if (step === 1) {
    return (
      <>
        <img
          src="/img/logo-only.png"
          alt="Team Feedback logo"
          className="auth-logo"
        />
        <div className="content-wrapper" style={{ maxHeight: "100%" }}>
          <div className="content">
            <div className="content-card card-11">
              <div className="content-card-header">
                <button className="arrow-back" onClick={() => push('/')}><i className="icon-arrow-left"></i></button>
                <p className="content-card-header-title">{t('common:Forgotpassword')}</p>
                <p className="content-card-header-subtitle">{t('authPages:Startbyenteringyouremailinthefieldbelow')}.</p>
              </div>

              <div className="content-card-main">
                <p className="content-card-main-label">Email</p>
                <div className={`input-container ${false && 'pass'}`}>
                  <input type="text" value={email} placeholder={`${t('common:Pleaseenteryouremail')}`} onChange={(e) => setEmail(e.target.value)} />
                  <span>
                    <i className="icon-feather-mail"></i>
                  </span>
                </div>

                <button className="button button-green-primary"
                  onClick={() => {
                    if (email) {
                      requestCode(email)
                    }
                  }}
                >{t('common:Confirm')}</button>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
  if (step === 2) {
    return (
      <>
        <img
          src="/img/logo-only.png"
          alt="Team Feedback logo"
          className="auth-logo"
        />
        <div className="content-wrapper" style={{ maxHeight: "100%" }}>
          <div className="content">
            <div className="content-card card-11">
              <div className="content-card-header">
                <button className="arrow-back" onClick={() => setStep(1)}><i className="icon-arrow-left"></i></button>
                <p className="content-card-header-title">{t('common:Forgotpassword')}</p>
                <p className="content-card-header-subtitle">{t('authPages:Wehavesentyouverificationcode')}{' '}
                  <button>{email}</button></p>
              </div>

              <div className="content-card-main">

                <p className="content-card-main-label">{t('common:Confirmationcode')}</p>
                <div className="input-container">
                  <input type="number" value={code} onChange={(e) => {
                    if ((/^[0-9]*$/).test(e.target.value) && e.target.value.length < 5) {
                      setCode(e.target.value)
                    }
                  }

                  } placeholder="Confirmation code" />
                  <span>
                    <i className="icon-material-lock"></i>
                  </span>
                </div>

                <button className="button button-green-primary" onClick={() => setStep(3)}>{t('common:Confirm')}</button>

                <button className="content-card-main-text-small">{t('common:Didntreceivecode')}</button>
              </div>

            </div>

          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <img
        src="/img/logo-only.png"
        alt="Team Feedback logo"
        className="auth-logo"
      />
      <div className="content-wrapper" style={{ maxHeight: "100%" }}>
        <div className="content">
          <div className="content-card card-12">
            <div className="content-card-header">
              <p className="content-card-header-title">{t('authPages:Setupnewpassword')}</p>
              <p className="content-card-header-subtitle">{t('authPages:Newpasswordmustcontain')}</p>
            </div>

            <div className="content-card-main">

              <p className="content-card-main-label">{t('common:Newpassword')}</p>
              <div className="input-container">
                <input type="password" placeholder={`${t('authPages:Choosenewpassword')}...`} onChange={(e) => setPassword(e.target.value)} />
                <span>
                  <i className="icon-material-lock"></i>
                </span>
              </div>

              <p className="content-card-main-label">{t('authPages:Confirmnewpassword')}</p>
              <div className="input-container">
                <input type="password" placeholder={`${t('authPages:Confirmnewpassword')}`} onChange={(e) => setConfirmPassword(e.target.value)} />
                <span>
                  <i className="icon-material-lock"></i>
                </span>
              </div>

              <button className="button button-green-primary"
                onClick={() => {
                  if (password && confirmPassword) {
                    if (password.length < 8) {
                      return window.alert('password should be atleast 8 characters')
                    }
                    resetPassword({
                      email,
                      code,
                      password
                    })
                  } else {
                    window.alert('password & confirm password does not match')
                  }
                }}
              >{t('authPages:Updatepassword')}</button>
            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default connector(ResetPassword)
