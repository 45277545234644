import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { AppDispatch, RootState } from 'src/configureStore'

const mapStateToProps = (state: RootState) => ({
    user: state.authReducer.user,
    company: state.companyReducer.company,

  })

  const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {}


const CreateCompany = (props: Props) => {
    
    const { user } = props

    useEffect(() => {
        if (user) {
            document
            .getElementById('regCoStep02')
            ?.classList.add('open')
        }
    }, [user])
    return(
        <div>

        </div>
    )
}

export default connector(CreateCompany)