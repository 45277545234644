import React, { useState } from 'react'
import { Team } from 'src/apiTypes'
import ComfirmationModal from '../modals/comfirmationModal'
import { useTranslation } from 'react-i18next'

type Props = {
  team: Team
  onClick(): void
  onQuit(): void
  active: boolean
  isAdmin: boolean
}

const DropdownTeam = (props: Props) => {
  const { t } = useTranslation('common');
  const { team, onClick, active, onQuit, isAdmin } = props
  const [showConfirm, setShowConfirm] = useState(false)

  return (
    <>
      <div
        className={`header-dropdown-menu-main-item ${active ? 'active' : ''
          }`}
      >
        <p className="header-dropdown-menu-main-item-text"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
          }}
          onClick={onClick}
        >{team.name}</p>
        {/* <span>({t('common:selected')})</span> */}
        {isAdmin && <button
          className="header-dropdown-menu-main-item-close"
          onClick={() => setShowConfirm(true)}
        >
          <i className="icon-close"></i>
        </button>}
      </div>

      <ComfirmationModal show={showConfirm} onClose={() => setShowConfirm(false)} onAccept={onQuit} caption={`${t('common:Areyousuretoremovethisteam')}`} />
    </>
  )

}

export default DropdownTeam