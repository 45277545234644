import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from 'src/components/loaders/signinLoader'

interface Props {

}

const pageModal: FC<Props> = (props: Props) => {
    const { t } = useTranslation(['createJoinCompany', 'common']);
    const [activeCard, setActiveCard] = useState('card-01')

    const [checked, setChecked] = useState(true);

    function handleCardChange(card: string) {
        setActiveCard(card)
    }

    return (
        <div className="content-wrapper">
            <div className="content">

                {/* CREATE YOUR FIRST TEAM CARD-01 */}
                {/* CURRENT 1 NEEDS 1 */}
                {activeCard === 'card-01' && (
                    <div className="content-card card-01">
                        <div className="content-card-main">
                            <img src="/img/logo-only-large.png" alt="Team feedback logo" className="content-card-main-logo" />

                            <p className="content-card-main-title">{t('createJoinCompany:WelcometoTeamFeedback')}</p>
                            <p className="content-card-main-text">{t('createJoinCompany:Averypowerfultool')}.</p>

                            <button className="button button-green-primary" onClick={(e) => handleCardChange('card-02')}>{t('common:Letsstart')}</button>
                        </div>
                    </div>
                )}


                {/* CREATE YOUR FIRST TEAM CARD-02 */}
                {/* CURRENT 2 NEEDS 2 */}
                {activeCard === 'card-02' && (
                    <div className="content-card card-02">
                        <div className="content-card-main">
                            <p className="content-card-main-title">{t('createJoinCompany:Howwouldyouliketogetstarted')}</p>
                            <p className="content-card-main-text">{t('createJoinCompany:Getstartedwiththreesimplesteps')}.</p>

                            <div className="cards">
                                <div className="card">
                                    <img src="/img/company-choose-01.png" alt="Clip art" className="card-img" />
                                    <p className="card-title">{t('createJoinCompany:Joinaregisteredcompany')}</p>
                                    <p className="card-text">{t('createJoinCompany:Invitationrequired')}</p>

                                    <button className="button button-primary" onClick={(e) => handleCardChange('card-03')}>{t('common:Continue')}</button>
                                </div>

                                <div className="divider"></div>

                                <div className="card">
                                    <img src="/img/company-choose-02.png" alt="Clip art" className="card-img" />
                                    <p className="card-title">{t('createJoinCompany:Registeranewteam')}</p>
                                    <p className="card-text">{t('createJoinCompany:Basicfeaturesfreeofcharge')}</p>

                                    <button className="button button-primary" onClick={(e) => handleCardChange('card-06')}>{t('common:Continue')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {/* CREATE YOUR FIRST TEAM CARD-03 */}
                {/* CURRENT 3 NEEDS 3 */}
                {activeCard === 'card-03' && (
                    <div className="content-card card-03">
                        <div className="content-card-header">
                            <button className="arrow-back"><i className="icon-arrow-left"></i></button>
                            <p className="content-card-header-title">{t('createJoinCompany:Joinaregisteredteam')}</p>
                            <p className="content-card-header-subtitle">{t('createJoinCompany:Getstartedwiththreesimplesteps')}.</p>
                        </div>
                        <div className="content-card-main">
                            {/* <p className="content-card-main-title">{t('createJoinCompany:Joinaregisteredteam')}</p>
                            <p className="content-card-main-text">{t('createJoinCompany:Getstartedwiththreesimplesteps')}.</p> */}

                            <img src="/img/join-company-img.png" alt="Clip art" className="content-card-main-img" />

                            <button className="button button-primary modal-trigger" onClick={(e) => handleCardChange('card-04')}>{t('createJoinCompany:Recheckinvitationstatus')}</button>
                            <p className="content-card-main-text-bottom">Last check 1 minute ago</p>
                        </div>
                    </div>
                )}

                {/* CREATE YOUR FIRST TEAM CARD-04 */}
                {/* CURRENT 4 NEEDS 4 */}
                {activeCard === 'card-04' && (
                    <div className="content-card card-04">
                        <div className="content-card-main">

                            <p className="content-card-main-title">{t('createJoinCompany:Joinaregisteredteam')}</p>
                            <p className="content-card-main-text">{t('createJoinCompany:Youhavebeeninvitedtoteam')}</p>

                            <div className="content-card-main-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="72.737" height="75.245" viewBox="0 0 72.737 75.245">
                                    <path d="M43.065,8H23v5.016H43.065V65.688H23V70.7H48.082V13.016A5.016,5.016,0,0,0,43.065,8Z"
                                        transform="translate(24.655 4.541)" fill="#fff" />
                                    <path
                                        d="M43.83,3H9.317A5.317,5.317,0,0,0,4,8.317V78.245H49.147V8.317A5.317,5.317,0,0,0,43.83,3Zm.3,70.229H36.606V65.7H16.541v7.524H9.016V8.317a.3.3,0,0,1,.3-.3H43.83a.3.3,0,0,1,.3.3Z"
                                        transform="translate(-4 -3)" fill="#fff" />
                                    <path d="M8,8h5.016v5.016H8Z" transform="translate(2.033 4.541)" fill="#fff" />
                                    <path d="M12,8h5.016v5.016H12Z" transform="translate(8.065 4.541)" fill="#fff" />
                                    <path d="M16,8h5.016v5.016H16Z" transform="translate(14.098 4.541)" fill="#fff" />
                                    <path d="M8,13h5.016v5.016H8Z" transform="translate(2.033 12.082)" fill="#fff" />
                                    <path d="M12,13h5.016v5.016H12Z" transform="translate(8.065 12.082)" fill="#fff" />
                                    <path d="M16,13h5.016v5.016H16Z" transform="translate(14.098 12.082)" fill="#fff" />
                                    <path d="M8,18h5.016v5.016H8Z" transform="translate(2.033 19.622)" fill="#fff" />
                                    <path d="M12,18h5.016v5.016H12Z" transform="translate(8.065 19.622)" fill="#fff" />
                                    <path d="M16,18h5.016v5.016H16Z" transform="translate(14.098 19.622)" fill="#fff" />
                                    <path d="M8,23h5.016v5.016H8Z" transform="translate(2.033 27.163)" fill="#fff" />
                                    <path d="M12,23h5.016v5.016H12Z" transform="translate(8.065 27.163)" fill="#fff" />
                                    <path d="M16,23h5.016v5.016H16Z" transform="translate(14.098 27.163)" fill="#fff" />
                                    <path d="M23,13h5.016v5.016H23Z" transform="translate(24.655 12.082)" fill="#fff" />
                                    <path d="M27,13h5.016v5.016H27Z" transform="translate(30.688 12.082)" fill="#fff" />
                                    <path d="M23,18h5.016v5.016H23Z" transform="translate(24.655 19.622)" fill="#fff" />
                                    <path d="M27,18h5.016v5.016H27Z" transform="translate(30.688 19.622)" fill="#fff" />
                                    <path d="M23,23h5.016v5.016H23Z" transform="translate(24.655 27.163)" fill="#fff" />
                                    <path d="M27,23h5.016v5.016H27Z" transform="translate(30.688 27.163)" fill="#fff" />
                                </svg>
                            </div>

                            <p className="content-card-main-company">Kone Oyj</p>
                            <p className="content-card-main-inviter">{t('createJoinCompany:Inviter')}: Pekka Jokinen</p>

                            <button className="button button-primary modal-trigger" onClick={(e) => handleCardChange('card-05')}>{t('createJoinCompany:Jointeam')}</button>
                            <button className="content-card-main-text-bottom">{t('createJoinCompany:Denyinvitation')}</button>
                        </div>
                    </div>
                )}

                {/* CREATE YOUR FIRST TEAM CARD-05 */}
                {/* CURRENT 5 NEEDS 5 */}
                {activeCard === 'card-05' && (
                    <div className="content-card card-05">
                        <div className="content-card-header">
                            <button className="arrow-back"><i className="icon-arrow-left"></i></button>
                            <p className="content-card-header-title">{t('common:Pleaseverifyyouremail')}</p>
                            <p className="content-card-header-subtitle">{t('common:Weeseeyouhaveaninvitation')}.</p>
                        </div>

                        <div className="content-card-main">
                            <p className="content-card-main-label">{t('common:Confirmationcode')}</p>
                            <div className="input-container">
                                <input type="text" placeholder="Enter verification code" />
                                <span>
                                    <i className="icon-material-lock"></i>
                                </span>
                            </div>

                            <button className="button button-green-primary">{t('common:Confirm')}</button>
                            <p className="content-card-main-text-small"><button>{t('common:Resendcode')}</button></p>
                        </div>
                    </div>
                )}

                {/* CREATE YOUR FIRST TEAM CARD-06 */}
                {/* CURRENT 6 NEEDS 6 */}
                {activeCard === 'card-06' && (
                    <div className="content-card card-06">
                        <div className="content-card-header">
                            <button className="arrow-back"><i className="icon-arrow-left"></i></button>
                            <p className="content-card-header-title">{t('common:Createyourfirstteam')}</p>
                            <p className="content-card-header-subtitle">{t('common:Pleasegiveyourteamanameandandadescription')}.</p>
                        </div>

                        <div className="content-card-main">
                            <p className="content-card-main-label">{t('common:Teamname')}</p>
                            <div className="input-container">
                                <input type="text" placeholder={`${t('common:Enterteamname')}`} />
                                <span>
                                    <p>40</p>
                                </span>
                            </div>

                            <p className="content-card-main-label">{t('common:Teamdescription')}{' '}<span>(optional)</span></p>
                            <div className="input-container">
                                <input type="text" placeholder={`${t('common:Enterteamdescription')}`} />
                                <span>
                                    <p>60</p>
                                </span>
                            </div>

                            <div className="content-card-main-check">
                                <div className="content-card-main-check-set">
                                    <p className="content-card-main-check-set-title">{t('createJoinCompany:Makemeteamleaderofthisteam')}</p>
                                    <p className="content-card-main-check-set-text">
                                        {t('createJoinCompany:Everyteamneedsatleastoneteamleader')}.
                                                </p>
                                </div>

                                <div className="pretty p-switch p-fill" onClick={(e) => setChecked(!checked)}>
                                    <input type="checkbox" checked={checked} />
                                    <div className="state p-success">
                                        <label>&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <button className="button button-green-primary" onClick={() => handleCardChange('card-07')}>{t('common:Registernewteam')}</button>

                            <p className="content-card-main-text-small"><button className="regular">{t('common:Quitteamcreation')}</button></p>
                        </div>
                    </div>
                )}

                {/* CREATE YOUR FIRST TEAM CARD-07 */}
                {/* CURRENT 7 NEEDS 7 */}
                {activeCard === 'card-07' && (
                    <div className="content-card card-07">
                        <div className="content-card-header">
                            <button className="arrow-back"><i className="icon-arrow-left"></i></button>
                            <p className="content-card-header-title">{t('common:Createyourfirstteam')}</p>
                            <p className="content-card-header-subtitle">{t('createJoinCompany:TeamFeedbackisnothingwithoutateam')}.</p>
                        </div>

                        <div className="content-card-main">
                            <div className="input-set">
                                <p className="content-card-main-label">{t('common:Addteammembers')}</p>

                                <div className="search-dropdown">
                                    <div className="search-dropdown-input-container show-button">
                                        <input type="text" placeholder="Search or enter email" />
                                        <i className="icon-search"></i>
                                        <button className="button button-add">{t('common:Add')}</button>
                                    </div>

                                    <div className="search-dropdown-menu">
                                        <div className="member">
                                            <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                            <div className="member-set">
                                                <p className="member-set-name">Marko Rajkovic</p>
                                                <p className="member-set-position">Example Job Title</p>
                                            </div>
                                            <button className="button select">Select</button>
                                        </div>
                                        <div className="member">
                                            <div className="member-initials">MR</div>
                                            <div className="member-set">
                                                <p className="member-set-name">Marko Rajkovic</p>
                                                <p className="member-set-position">Example Job Title</p>
                                            </div>
                                            <button className="button select">{t('common:Select')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="horizontal-divider"></div>

                            <div className="scrollable">
                                <div className="members">
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>

                                </div>

                                <div className="content-card-main-buttons">
                                    <button className="go-back" onClick={() => handleCardChange('card-01')} ><i className="icon-arrow-left"></i>{' '}{t('common:GoBack')}</button>
                                    <button className="button button-green-primary" onClick={() => handleCardChange('card-08')}>{t('common:Registernewteam')}</button>
                                    <p className="content-card-main-text-small"><button className="regular">Skip this step and invite later</button></p>
                                </div>
                            </div>

                        </div>

                    </div>
                )}


                {/* CREATE YOUR FIRST TEAM CARD-08 */}
                {/* CURRENT 8 NEEDS 8 */}
                {activeCard === 'card-08' && (
                    <div className="content-card card-08">
                        <div className="content-card-header">
                            <p className="content-card-header-title">Team Marketing</p>
                            <p className="content-card-header-subtitle">{t('createJoinCompany:Congratulationsyourfirstteam')}.
                                </p>
                        </div>

                        <div className="content-card-main">

                            <div className="horizontal-divider"></div>

                            <div className="scrollable">
                                <div className="members">
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <img src="/img/p-01.png" alt="User's profile image" className="member-img" />
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>
                                    <div className="member">
                                        <div className="member-initials">MR</div>
                                        <div className="member-set">
                                            <p className="member-set-name">Marko Rajkovic</p>
                                            <p className="member-set-position">Example Job Title</p>
                                        </div>
                                        <button className="button delete">{t('common:Delete')}</button>
                                    </div>

                                </div>

                                <div className="content-card-main-buttons">
                                    <button className="button button-green-primary" onClick={() => handleCardChange('card-09')}>{t('common:Tothedashboard')}{' '}<i className="icon-arrow-right"></i></button>
                                </div>
                            </div>

                        </div>

                    </div>
                )}


                {/* CREATE YOUR FIRST TEAM CARD-09 */}
                {/* CURRENT 9 NEEDS 9 */}
                {activeCard === 'card-09' && (
                    <div className="content-card card-09">
                        <div className="content-card-header">
                            <p className="content-card-header-title">{t('createJoinCompany:Onemorething')}</p>
                            <p className="content-card-header-subtitle">{t('createJoinCompany:Wouldyoulikeustocreate')}</p>
                        </div>

                        <div className="content-card-main">
                            <button className="button button-green-primary">{t('createJoinCompany:Yescrateademoteamforme')}</button>
                            <button className="content-card-main-text-small">{t('createJoinCompany:Idontneedademoteam')}</button>
                        </div>
                    </div>
                )}


            </div>
        </div>
    )
}

export default pageModal
