import React, { FC, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AppDispatch, RootState } from 'src/configureStore'
import { getTeamGiven } from 'src/redux/teams/actions'
import { useTranslation } from 'react-i18next'



const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTeamGiven: () => dispatch(getTeamGiven())
})

const mapStateToProps = (state: RootState) => ({
  teamGiven: state.teamReducer.teamGiven,
  selectedTeam: state.teamReducer.selectedTeam,
  user: state.authReducer.user
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  getTeamId(teamId: string): void
}

interface ParamTypes {
  teamId?: string
}

const AppUsage = (props: Props) => {
  const { t } = useTranslation(['common', 'appUsage'])
  const { getTeamGiven, teamGiven, user, getTeamId, selectedTeam } = props
  const params = useParams<ParamTypes>()
  useEffect(() => {
    if (selectedTeam) {
      getTeamGiven()
    }
  }, [selectedTeam])
  useEffect(() => {
    if (params.teamId) {
      getTeamId(params.teamId)
    }
  }, [params])

  const todoCalc = () => {
    let num = 0
    let other = 0
    if (teamGiven) {
      if (teamGiven.todoList.selfEvaluations) {
        num++
      }
      if (teamGiven.todoList.teamFeedback) {
        num++
      }
      if (teamGiven.feedbackRequest.p2p) {
        num++
      }

      if (teamGiven.todoList.subordinateFeedback || teamGiven.todoList.supervisorFeedback) {
        num++
      }
      if ((!teamGiven.todoList.subordinateFeedback && teamGiven.todoList.supervisorFeedback) || (teamGiven.todoList.subordinateFeedback && !teamGiven.todoList.supervisorFeedback)) {
        other = 1
      }
      if (teamGiven.todoList.subordinateFeedback && teamGiven.todoList.supervisorFeedback) {
        other = 2
      }

    }
    return {
      overall: num,
      other: other
    }
  }

  const todoC = todoCalc()

  return (
    <div className="content-wrapper">
      <div className="content">
        <div className="content-card">
          <div className="content-card-header">
            <p className="content-card-header-title">{t('appUsage:Appusage')}</p>
            <p className="content-card-header-text">{t('common:Havingaquestionaboutit')}{' '}{t('common:Click')}{' '}<button>{t('common:here')}</button></p>
          </div>

          <div className="content-card-plan">
            <div className="content-card-plan-info">
              <img src="/img/logo-only.png" alt="Company logo" className="content-card-plan-info-img" />
              <div className="content-card-plan-info-set">
                <p className="content-card-plan-info-set-title">{t('common:teamFeedback')}{' '}{t('common:Free')}</p>
                <button className="content-card-plan-info-set-text">{t('common:Seeotherplans')}</button>
              </div>
            </div>
            <button className="button button-green-primary">{t('common:Upgrade')}</button>
          </div>

          <div className="content-card-main">


            <div className="content-card-main-charts">
              <div className="chart">
                <div className="chart-header">
                  <p className="chart-header-title">{t('appUsage:Amountofgivenfeedbacklast30days')}</p>
                  <p className="chart-header-amount">{t('common:Given')}{' '}<span>{teamGiven?.teamGiven.total}</span>{' '}{t('common:of')}{' '}{teamGiven?.variables.feedbackLimit}</p>
                </div>
                <div className="chart-area">
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${(teamGiven.teamGiven.team / (teamGiven.variables.feedbackLimit / 4)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${(teamGiven.teamGiven.p2p / (teamGiven.variables.feedbackLimit / 4)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${(teamGiven.teamGiven.self / (teamGiven.variables.feedbackLimit / 4)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${((teamGiven.teamGiven.subordinate + teamGiven.teamGiven.supervisor) / (teamGiven.variables.feedbackLimit / 2)) * 25}%` : 0
                  }}></div>
                </div>
                <div className="chart-legend">
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:teamFeedback')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:p2pFeedback')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:Selfevaluation')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:Otherfeedback')}</p>
                  </div>
                </div>
              </div>

              <div className="chart">
                <div className="chart-header">
                  <p className="chart-header-title">{t('appUsage:Amountoffeedbackrequests')}</p>
                  <p className="chart-header-amount">{t('common:Used')}{' '}<span>{teamGiven?.feedbackRequest.total}</span>{' '}{t('common:Used')}{' '}{teamGiven?.variables.requestLimit}</p>
                </div>
                <div className="chart-area">
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${(teamGiven.feedbackRequest.teamFeedback / (teamGiven.variables.requestLimit / todoC.overall)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${(teamGiven.feedbackRequest.p2p / (teamGiven.variables.requestLimit / todoC.overall)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${(teamGiven.feedbackRequest.selfEvaluations / (teamGiven.variables.requestLimit / todoC.overall)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${(teamGiven.feedbackRequest.subordinateFeedback + teamGiven.feedbackRequest.supervisorFeedback / (teamGiven.variables.requestLimit / todoC.other)) * 25}%` : 0
                  }}></div>
                </div>
                <div className="chart-legend">
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:teamFeedback')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:p2pFeedback')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:Selfevaluation')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:Otherfeedback')}</p>
                  </div>
                </div>
              </div>

              <div className="chart">
                <div className="chart-header">
                  <p className="chart-header-title">{t('appUsage:Amountofteammembers')}</p>
                  <p className="chart-header-amount">{t('common:Given')}{' '}<span>{teamGiven?.teamMembers.total}</span>{' '}{t('common:of')}{' '}{teamGiven?.variables.memberLimit}</p>
                </div>
                <div className="chart-area">
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${((teamGiven.teamMembers.members) / (teamGiven.variables.memberLimit / 3)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${((teamGiven.teamMembers.viewing) / (teamGiven.variables.memberLimit / 3)) * 25}%` : 0
                  }}></div>
                  <div className="chart-area-item" style={{
                    width: teamGiven ? `${((teamGiven.teamMembers.admins) / (teamGiven.variables.memberLimit / 3)) * 25}%` : 0
                  }}></div>


                </div>
                <div className="chart-legend">
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:Teammembers')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:Viewingrights')}</p>
                  </div>
                  <div className="chart-legend-item">
                    <div className="chart-legend-item-box"></div>
                    <p className="chart-legend-item-text">{t('common:Adminrights')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connector(AppUsage)
