import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TeamCard from 'src/components/team/teamCard'
import { AppDispatch, RootState } from 'src/configureStore'
import GiveFeedbackProfileHolder from 'src/components/profile/giveFeedbackProfileHolderV3'
import GiveFeedbackToDo from 'src/components/profile/giveFeedbackTodoHolder'

import {
  deleteTeam,
  getMyTeams,
  getPendingInvites,
  getTeamById,
  quitTeam,
} from 'src/redux/teams/actions'
import { getMyTeamMembers, getMyToDo } from 'src/redux/feedback/actions'
import GiveFeedbackmodal from 'src/components/modals/givefeedback'
import { ToDo, User, UserTeam } from 'src/apiTypes'
import SelectTeam from 'src/components/modals/selectTeam'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useOnClickOutside from 'src/hooks/useOnClickOutside'
import ToDoContainer from 'src/components/toDo/toDoContainer'

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  myTeams: state.teamReducer.myTeams,
  selectedTeamInReducer: state.teamReducer.selectedTeam,
  myTeamMembers: state.feedbackReducer.myTeamMembers,
  todo: state.feedbackReducer.todo,
  pendingInvites: state.invitesReducer.invites,
  selectedTeamData: state.teamReducer.selectedTeamData
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getMyTeams: () => dispatch(getMyTeams()),
  deleteTeam: (teamId: string) => dispatch(deleteTeam(teamId)),
  quitTeam: (teamId: string) => dispatch(quitTeam(teamId)),
  getTeamById: (teamId: string) => dispatch(getTeamById(teamId)),
  getMyTeamMembers: () => dispatch(getMyTeamMembers()),
  getMyToDo: () => dispatch(getMyToDo()),
  getPendingInvites: (teamId: string) => dispatch(getPendingInvites(teamId, 'Team member'))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  getTeamId(teamId: string): void
}
interface ParamTypes {
  teamId?: string
}
const Home = (props: Props) => {
  const { t } = useTranslation(['giveFeedbackPage', 'common'])
  const params = useParams<ParamTypes>()
  const {
    myTeams,
    getMyTeams,
    user,
    getTeamById,
    getMyTeamMembers,
    myTeamMembers,
    getMyToDo,
    todo,
    getTeamId,
    getPendingInvites,
    selectedTeamInReducer,
    selectedTeamData
  } = props
  const { push } = useHistory()
  useEffect(() => {
    if (user) {
      getMyTeams()
      getMyTeamMembers()
      getMyToDo()
    }
  }, [user, getMyTeamMembers, getMyTeams, getMyToDo])


  const [selectedUser, selectUser] = useState<User | null>(null)
  const [selectedTeam, selectTeam] = useState<UserTeam | null>(null)

  useEffect(() => {
    if (selectedUser) {
      if (!!selectedUser?.team?.length && selectedUser.team.length < 2) {
        selectTeam(selectedUser.team[0])
      } else {
        if (!selectedTeam) {
          document.getElementById('feedbackTypeModal')?.classList.add('open')
        }
        
      }
    }
  }, [selectedUser])

  useEffect(() => {
    if (selectedTeam) {
      document.getElementById('peerToPeerFeedbackModal')?.classList.add('open')
    }
  }, [selectedTeam])


  useEffect(() => {
    if (selectedTeamInReducer) {
      getPendingInvites(selectedTeamInReducer?._id)
    }
  }, [selectedTeamInReducer])

  const [mode, setMode] = useState<'grid' | 'todo'>('grid')
  useEffect(() => {
    if (params.teamId) {
      getTeamId(params.teamId)
    }
  }, [params])


  const [filtered, setFiltered] = useState<User[]>([])

  useEffect(() => {
    if (myTeamMembers) {
      setFiltered(myTeamMembers.sort((a, b) => {
        if (a.firstname > b.firstname) {
          return 1
        }
        if (a.firstname < b.firstname) {
          return -1
        }
        return 0
      }))
    }
  }, [myTeamMembers])

  const filterTeammembers = (keyword: string) => {
    if (myTeamMembers) {
      setFiltered(myTeamMembers.filter(item => item.firstname.toLowerCase().includes(keyword.toLowerCase())))
    }

  }

  const [filteredTodo, setFilteredTodo] = useState<ToDo[]>([])

  useEffect(() => {
    if (todo) {
      setFilteredTodo(todo)
    }
  }, [todo])


  const filterToDo = (keyword: string) => {
    if (todo) {
      setFilteredTodo(todo.filter(item => {
        if (item.type === 'team') {
          return item.team.name.toLowerCase().includes(keyword.toLowerCase())
        } else {
          return item.target.firstname.toLowerCase().includes(keyword.toLowerCase())
        }

      }))
    }

  }

  const [open, setOpen] = useState(false)
  const drowdownEl = useRef(null)
  useOnClickOutside(drowdownEl, () => setOpen(false))

  function handleToggle() {
    setOpen(!open)
  }

  const [sortBy, setSortBy] = useState<'name' | 'jobtitle' | 'category'>('name')



  useEffect(() => {
    if (filtered.length) {
      if (sortBy === 'name') {
        setFiltered(filtered.sort((a, b) => {
          if (a.firstname > b.firstname) {
            return 1
          }
          if (a.firstname < b.firstname) {
            return -1
          }
          return 0
        }))
        setFilteredTodo(filteredTodo.sort((a, b) => {
          if (a.target && b.target && a.target.firstname > b.target.firstname) {
            return 1
          }
          if (a.target && b.target && a.target.firstname < b.target.firstname) {
            return -1
          }
          if (a.target && !b.target && a.target.firstname > b.team.name) {
            return 1
          }
          if (a.target && !b.target && a.target.firstname < b.team.name) {
            return -1
          }
          if (!a.target && b.target && a.team.name > b.target.firstname) {
            return 1
          }
          if (!a.target && b.target && a.team.name < b.target.firstname) {
            return -1
          }
          if (!a.target && !b.target && a.team.name > b.team.name) {
            return 1
          }
          if (!a.target && !b.target && a.team.name < b.team.name) {
            return -1
          }
          return 0
        }))
      }
      if (sortBy === 'jobtitle') {

        setFiltered(filtered.sort((a, b) => {
          if (a.jobtitle > b.jobtitle) {
            return 1
          }
          if (a.jobtitle < b.jobtitle) {
            return -1
          }
          return 0
        }))
      }
      if (sortBy === 'category') {
        setFilteredTodo(filteredTodo.sort((a, b) => {
          if (a.type > b.type) {
            return 1
          }
          if (a.type < b.type) {
            return -1
          }
          return 0
        }))
      }
    }
  }, [sortBy])

  // const todoRefs = useRef(new Array())
  // todoRefs.current = []

  // function addToRefs(el: any, ref: any) {
  //   if (el && !todoRefs.current.includes(el)) {
  //     todoRefs.current.push(el)
  //   }

  // }

  useEffect(() => {
    console.log(document.querySelector(".todo-list"), "TODO ITEMS")
    function styleTodoItems() {

      if (document.querySelector(".todo-list")) {
        // @ts-ignore
        const grid = Array.from(document.querySelector(".todo-list").children);
        // @ts-ignore
        const baseOffset = grid[0].offsetTop;
        // @ts-ignore
        const breakIndex = grid.findIndex(item => item.offsetTop > baseOffset);
        const numPerRow = (breakIndex === -1 ? grid.length : breakIndex);
        grid.forEach(e => {
          // @ts-ignore
          e.style.borderTop = 'none';
        })
        console.log(numPerRow)
        Array.from(Array(numPerRow).keys()).forEach((e, i) => {
          // @ts-ignore
          grid[i].style.borderTop = '1.5px solid #f0f0f0';
        })
      }

    }

    styleTodoItems();
    window.addEventListener('resize', styleTodoItems)
  })

  useEffect(() => {
    const cardResizeTriggers = document.querySelectorAll('.card-resize-trigger');
    const cardResizeContainer = document.querySelector('.card-resize');
    const currentMain = document.querySelector('#give-feedback-new');

    cardResizeTriggers.forEach(e => {
      e.addEventListener('click', function (e) {
        // @ts-ignore
        currentMain.classList.toggle('full-size');
        // @ts-ignore
        cardResizeContainer.classList.toggle('active');
      })
    })
  }, [])

  return (
    <div className="content-wrapper">
      <div className="content">
        <div className="card-resize card-resize-trigger">
          <i className="icon-fullscreen-enter"></i>
        </div>
        <div className="content-card">
          {!!todo && !!todo.length && (
            <>
              <div className="caption big">
                <p className="caption-title">{t('giveFeedbackPage:Todolist')}</p>
              </div>
              <ToDoContainer
                data={todo || []}
                selectUser={(u) => selectUser(u)}
                selectTeam={(team) => selectTeam(team)}
              />
            </>
          )}


          {/* <div className="options">
              <button
                className={`options-link ${mode === 'grid' ? 'active' : ''}`}
                onClick={() => setMode('grid')}
              >
                {t('common:Allteammembers')}
              </button>
              <span className="options-divider">I</span>
              {!!todo?.length && (
                <button
                  className={`options-link ${mode === 'todo' ? 'active' : ''}`}
                  onClick={() => setMode('todo')}
                >
                  {t('giveFeedbackPage:todoList')}{' '}
                  <span className="amount">{todo?.length || ''}</span>
                </button>
              )}
            </div> */}

          {/*<div className="content-card-header-container">
              <div className="option-buttons">
                <button className={`button ${mode === 'grid' ? 'active' : ''}`} onClick={() => setMode('grid')}><p text={`${t('common:Allteammembers')}`}>{t('common:Allteammembers')}</p></button>
                <button className={`button ${mode === 'todo' ? 'active' : ''}`} onClick={() => setMode('todo')}><p text={`${t('common:Mytodolist')}`}>{t('common:Mytodolist')}</p>{!!todo?.length && <span className="amount">{todo?.length || ''}</span>}</button>
              </div>

               <div className="content-card-header-buttons">
                <input type="text" placeholder={`${t('common:search')}...`} onChange={(e) => {
                  if (mode === 'grid') {
                    filterTeammembers(e.target.value)
                  } else {
                    filterToDo(e.target.value)
                  }

                }} ></input>
                <button className="button button-green-primary" onClick={() => {
                  document
                    .getElementById('inviteMemberNew')
                    ?.classList.add('open')

                }} >{t('common:inviteNewMembers')}</button>
                {mode === 'grid' ? 
                
              
                <div className={`exp-dropdown ${open && 'open'}`} ref={drowdownEl}>
                  <button className="button button-light-gradient exp-dropdown-trigger" onClick={handleToggle}>
                    <i className="icon-sort"></i>
                  </button>

                  <div className="exp-dropdown-menu">
                    <div className={`exp-dropdown-menu-item ${sortBy === 'name' ? 'active' : ''}`} onClick={() => setSortBy('name')}>
                      <p className="exp-dropdown-menu-item-text">Sort by name</p>
                      <i className="icon-check"></i>
                    </div>
                    <div className={`exp-dropdown-menu-item ${sortBy === 'jobtitle' ? 'active' : ''}`} onClick={() => setSortBy('jobtitle')}>
                      <p className="exp-dropdown-menu-item-text">Sort by job title</p>
                      <i className="icon-check"></i>
                    </div>
                  </div>
                  
                </div> : 
                <div className={`exp-dropdown ${open && 'open'}`} ref={drowdownEl}>
                <button className="button button-light-gradient exp-dropdown-trigger" onClick={handleToggle}>
                  <i className="icon-sort"></i>
                </button>

                <div className="exp-dropdown-menu">
                  <div className={`exp-dropdown-menu-item ${sortBy === 'name' ? 'active' : ''}`} onClick={() => setSortBy('name')}>
                    <p className="exp-dropdown-menu-item-text">Sort by name</p>
                    <i className="icon-check"></i>
                  </div>
                  <div className={`exp-dropdown-menu-item ${sortBy === 'category' ? 'active' : ''}`} onClick={() => setSortBy('category')}>
                    <p className="exp-dropdown-menu-item-text">Sort by category</p>
                    <i className="icon-check"></i>
                  </div>
                </div>
                
              </div>
                }
                
              </div> 
              
            </div>*/}


          {
            !myTeamMembers?.length && mode === 'grid' && (
              <div className="placeholder">
                <img src="/img/feedback-placeholder-img.png" alt="Clip art" className="placeholder-img"></img>
                <p className="placeholder-title">{t('giveFeedbackPage:Noteammembersyet')}</p>
                <p className="placeholder-text">{t('giveFeedbackPage:Thisteamdoesnthavemember')}.
                {t('common:Click')}{' '}<button>{t('common:here')}</button>{' '}{t('giveFeedbackPage:toinviteyourfirstteammembers')}.</p>
              </div>
            )
          }

          {
            !todo?.length && mode !== 'grid' && (
              <div className="placeholder">
                <img src="/img/feedback-placeholder-img.png" alt="Clip art" className="placeholder-img"></img>
                <p className="placeholder-title">{t('giveFeedbackPage:Noteammembersyet')}</p>
                <p className="placeholder-text">{t('giveFeedbackPage:Thisteamdoesnthavemember')}.
                {t('common:Click')}{' '}<button>{t('common:here')}</button>{' '}{t('giveFeedbackPage:toinviteyourfirstteammembers')}.</p>
              </div>
            )
          }


          {/* SEARCH PLACEHOLDER */}
          {mode === 'grid' && !filtered.length &&
            <div className="placeholder no-results">
              <img src="/img/table-placeholder-img-no-results.png" alt="Clip art" className="placeholder-img" />
              <p className="placeholder-title">No search results</p>
            </div>
          }
          {mode === 'todo' && !filteredTodo.length &&
            <div className="placeholder no-results">
              <img src="/img/table-placeholder-img-no-results.png" alt="Clip art" className="placeholder-img" />
              <p className="placeholder-title">No search results</p>
            </div>
          }

          {(myTeamMembers?.length || todo?.length) && mode === 'grid' ? (
            <GiveFeedbackProfileHolder
              data={filtered}
              selectedUser={selectedUser}
              selectUser={(u) => selectUser(u)}
              invites={selectedTeamData?.['Team member'] || []}
            />
          ) : (
            <GiveFeedbackToDo
              data={filteredTodo}
              selectedUser={selectedUser}
              selectUser={(u, t) => {
                selectUser(u)
                selectTeam(t)
              }}
            />
          )}
        </div>
      </div>
      {mode == 'grid' && <SelectTeam
        teams={selectedUser?.team}
        onSelect={(team) => selectTeam(team)}
      />}

      <GiveFeedbackmodal
        data={selectedUser}
        team={selectedTeam}
        clear={() => {
          selectUser(null)
          selectTeam(null)
          getMyToDo()
        }}
      />
    </div>
  )
}

export default connector(Home)
