import React, { FC } from 'react'
import ReactTooltip from 'react-tooltip'

interface Props {
    text: string
}

const tooltipIcon: FC<Props> = ({ text }) => {
    return (
        <>
            <span className="tooltip-icon" data-tip={`${text}`}><i className="icon-info"></i></span>
            <ReactTooltip
                effect="float"
                place={'bottom'}
                delayShow={250}
                className="custom-tooltip"
            />
        </>
    )
}

export default tooltipIcon
