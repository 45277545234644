import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    show: boolean
    onClose(): void
    onAccept(): void
}

const ComfirmationModal: FC<Props> = (props: Props) => {
    const { t } = useTranslation('common')
    const { show, onClose, onAccept } = props
    if (!show) return null
    return (
        <div className="custom-modal-wrapper open" id="resendCodeModal">
            <div className="custom-modal">
                <div className="custom-modal-body">
                    <div className="custom-modal-close" onClick={onClose}>
                        <i className="icon-close"></i>
                    </div>
                    <p className="custom-modal-body-title">{t('common:Didntreceivethecode')}</p>
                    <p className="custom-modal-body-text">{t('common:Firstpleasecheckyourspamfolder')}.</p>
                    <div className="custom-modal-body-buttons">
                        <button className="button button-primary" onClick={onAccept}>{t('common:Resendcode')}</button>
                    </div>
                </div>
            </div>
            <div className="custom-modal-backdrop"></div>
        </div>
    )
}

export default ComfirmationModal
