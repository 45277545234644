import React, { useEffect } from 'react'
import { Question } from 'src/apiTypes'

interface Props {
  data: Question
  onChange(text: string): void
  value: string
}

const TextInput = (props: Props) => {
  const { data, onChange, value } = props
  useEffect(() => {
    if (!value && document.getElementById(data._id)) {
      //@ts-ignore
      document.getElementById(data._id).innerHTML = ''
    }
  }, [value])

  return (
    <div className="feedback-item">
      <p className="feedback-item-name">{data.question}</p>
      {/* <input
        value={value}
        className="feedback-item-input"
        type="text"
        placeholder="Write here..."
        onChange={(e) => onChange(e.target.value)}
      /> */}

      {/* @ts-ignore */}
      <span type="text" className="feedback-item-input lang-en" id={data._id} role="textbox" onInput={(e) => onChange(e.currentTarget.textContent)} contentEditable></span>
    </div>
  )
}

export default TextInput
