import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RootState } from 'src/configureStore'
import { connect, ConnectedProps } from 'react-redux'




const mapStateToProps = (state: RootState) => ({
  company: state.companyReducer.company
})
const connector = connect(mapStateToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
interface Props { }
const HeaderCompanySubNav = (props: Props & PropsFromRedux) => {
  const { t } = useTranslation(['navigation', 'common'])
  const { push } = useHistory()
  const { pathname } = useLocation()
  const { company } = props
  useEffect(() => {
    let navItemActive = document.querySelector(
      '.header-nav-list-item.active'
    ) as HTMLElement
    let svgArrow = document.querySelector('.header-nav svg') as HTMLElement

    if (navItemActive) {
      let bodyRect = document.body.getBoundingClientRect()
      let elemRect = navItemActive.getBoundingClientRect()
      let offset = elemRect.left - bodyRect.left + navItemActive.clientWidth / 2

      svgArrow.style.left = `${offset}px`
    }
  })

  useEffect(() => {
    let header = document.querySelector('.header') as HTMLElement
    let headerMain = document.querySelector('.header-main') as HTMLElement
    let navDropdown = document.querySelector('.nav-dropdown') as HTMLElement
    const navDropdownMenuItems = document.querySelectorAll(
      '.nav-dropdown-menu-item-button'
    )
    let headerNavListItems = document.querySelectorAll('.header-nav-list-item')

    let headerNavListItemsWidth = 180
    let headerNavListItemsWidthTemp = 0
    headerNavListItems.forEach((e) => {

      headerNavListItemsWidth +=
        // @ts-ignore
        e.clientWidth + (window.clientWidth > 1100 ? 45 : 32)
    })

    let dropdownMenuListItemsWidth = 0

    navDropdownMenuItems.forEach((e) => {
      dropdownMenuListItemsWidth += e.clientWidth + 35
    })

    if (dropdownMenuListItemsWidth > headerMain.clientWidth) {
      navDropdown.classList.remove('medium')
    } else {
      navDropdown.classList.add('medium')
    }

    if (headerNavListItemsWidth > headerMain.clientWidth) {
      header.classList.add('show-dropdown')
    } else {
      header.classList.remove('show-dropdown')
    }

    window.addEventListener('resize', function () {
      if (headerNavListItemsWidth > headerMain.clientWidth) {
        header.classList.add('show-dropdown')
      } else {
        header.classList.remove('show-dropdown')
        navDropdown.classList.remove('open')
      }

      if (dropdownMenuListItemsWidth > headerMain.clientWidth) {
        navDropdown.classList.remove('medium')
      } else {
        navDropdown.classList.add('medium')
      }
    })
  })

  return (
    <>
      <div
        className="header-nav"
        onClick={() =>
          document.getElementById('mainDrawer')?.classList.remove('open')
        }
      >
        <ul className="header-nav-list">
          <li
            className={`header-nav-list-item ${pathname === '/company/overview' ? 'active' : ''
              }`}
          >
            <button
              className="header-nav-list-item-text"
              text={`${t('common:CompanyOverview')}`}
              onClick={() => push(`/company/${company?._id}/overview`)}
            >
              {t('common:CompanyOverview')}
            </button>
          </li>
          <li
            className={`header-nav-list-item ${pathname === '/company/employees' ? 'active' : ''
              }`}
          >
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Employees')}`}
              onClick={() => push(`/company/${company?._id}/employees`)}
            >
              {t('common:Employees')}
            </button>
          </li>
          <li
            className={`header-nav-list-item ${pathname === '/company/teams' ? 'active' : ''
              }`}
          >
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Teams')}`}
              onClick={() => push(`/company/${company?._id}/teams`)}
            >
              {t('common:Teams')}
            </button>
          </li>
          <li
            className={`header-nav-list-item ${pathname === '/company/admins' ? 'active' : ''
              }`}
          >
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Adminrights')}`}
              onClick={() => push(`/company/${company?._id}/admins`)}
            >
              {t('common:Adminrights')}
            </button>
          </li>
          <li className="header-nav-list-item">
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Generalsettings')}`}
            >
              {t('common:Generalsettings')}
            </button>
          </li>
          <li className="header-nav-list-item">
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Feedbacksettings')}`}
            >
              {t('common:Feedbacksettings')}
            </button>
          </li>
          <li className="header-nav-list-item">
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Billing')}`}
            >
              {t('common:Billing')}
            </button>
          </li>
          <li className="header-nav-list-item">
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Support')}`}
            >
              {t('common:Support')}
            </button>
          </li>
          <li className="header-nav-list-item">
            <button
              className="header-nav-list-item-text"
              text={`${t('common:Documentation')}`}
            >
              {t('common:Documentation')}
            </button>
          </li>
        </ul>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 13"><g transform="translate(-548 -124)"><g transform="translate(320)"><path d="M9.722.963a1,1,0,0,1,1.556,0l8.407,10.408A1,1,0,0,1,18.907,13H2.093a1,1,0,0,1-.778-1.628Z" transform="translate(228 124)" fill="#e4e6e9" /></g><g transform="translate(321.35 1.6)"><path d="M9.15,0,18.3,11.4H0Z" transform="translate(228 124)" fill="#fff" /></g></g></svg>

      </div>

      <div className="nav-dropdown medium">
        <div
          className="nav-dropdown-trigger"
          onClick={(e) =>
            e.currentTarget.parentElement?.classList.toggle('open')
          }
        >
          <p className="nav-dropdown-trigger-value">
            {pathname === '/company/overview' && 'Company overview'}
            {pathname === '/company/employees' && 'Employees'}
            {pathname === '/company/teams' && 'Teams'}
            {pathname === '/company/admins' && 'Admin rights'}
          </p>
          <i className="icon-chevron-down"></i>
        </div>
        <div className="nav-dropdown-menu">
          <div
            className={`nav-dropdown-menu-item ${pathname === '/company/overview' ? 'selected' : ''
              }`}
          >
            <button
              className="nav-dropdown-menu-item-button"
              onClick={() => push('/company/overview')}
            >
              {t('common:CompanyOverview')}
            </button>
          </div>
          <div
            className={`nav-dropdown-menu-item ${pathname === '/company/employees' ? 'selected' : ''
              }`}
          >
            <button
              className="nav-dropdown-menu-item-button"
              onClick={() => push('/company/employees')}
            >
              {t('common:Employees')}
            </button>
          </div>
          <div
            className={`nav-dropdown-menu-item ${pathname === '/company/teams' ? 'selected' : ''
              }`}
          >
            <button
              className="nav-dropdown-menu-item-button"
              onClick={() => push('/company/teams')}
            >
              {t('common:Teams')}
            </button>
          </div>
          <div
            className={`nav-dropdown-menu-item ${pathname === '/company/admins' ? 'selected' : ''
              }`}
          >
            <button
              className="nav-dropdown-menu-item-button"
              onClick={() => push('/company/admins')}
            >
              {t('common:Adminrights')}
            </button>
          </div>
          <div className={`nav-dropdown-menu-item`}>
            <button className="nav-dropdown-menu-item-button">
              {t('common:Generalsettings')}
            </button>
          </div>
          <div className={`nav-dropdown-menu-item`}>
            <button className="nav-dropdown-menu-item-button">
              {t('common:Feedbacksettings')}
            </button>
          </div>
          <div className={`nav-dropdown-menu-item`}>
            <button className="nav-dropdown-menu-item-button">
              {t('common:Billing')}
            </button>
          </div>
          <div className={`nav-dropdown-menu-item`}>
            <button className="nav-dropdown-menu-item-button">
              {t('common:Support')}
            </button>
          </div>
          <div className={`nav-dropdown-menu-item`}>
            <button className="nav-dropdown-menu-item-button">
              {t('common:Documentation')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default connector(HeaderCompanySubNav)
