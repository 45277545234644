import React, { useEffect, useRef, useState } from "react"

import { AppDispatch, RootState } from "src/configureStore"
import { sendEmailVerification, signUp, updateProfile, verifyEmail } from "src/redux/auth/actions"
import { connect, ConnectedProps } from "react-redux"
import { CreateTeamVariables, SignUpVariables, UpdateProfileVariables } from "src/apiTypes"
import Toaster from "src/components/common/toaster"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from 'react-router-dom'
import { createTeam, getTeamById, inviteUserSuccess } from "src/redux/teams/actions"
import { capitalize, validateEmail, validateName } from "src/utitlity"
import { acceptCompanyInvite, acceptInvite, getAllMyInvites, getCompanyInvites } from "src/redux/invites/actions"
import ComfirmationModal from 'src/components/modals/resendCode'
import moment from 'moment'
import Loader from 'src/components/loaders/signinLoader'


const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signup: (data: SignUpVariables) => dispatch(signUp(data)),
  getTeamById: (teamId: string) => dispatch(getTeamById(teamId)),
  sendEmailVerification: (email: string) =>
    dispatch(sendEmailVerification(email)),
  verifyEmail: (email: string, code: string) =>
    dispatch(verifyEmail(email, code)),
  createTeam: (data: CreateTeamVariables) => dispatch(createTeam(data)),
  acceptInvite: (inviteId: string) => dispatch(acceptInvite(inviteId)),
  acceptCompanyInvite: (inviteId: string, companyId: string) => dispatch(acceptCompanyInvite(inviteId, companyId)),
  getMyInvites: (email: string) => dispatch(getAllMyInvites(email)),
  getCompanyInvites: (email: string) => dispatch(getCompanyInvites(email)),
  updateProfile: (data: UpdateProfileVariables) =>
    dispatch(updateProfile(data)),

})

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  sessionRestored: state.authReducer.sessionRestored,
  error: state.authReducer.signinError,
  myTeams: state.teamReducer.myTeams,
  sigininin: state.authReducer.sigininin,
  emailVerificationSent: state.authReducer.emailVerificationSent,
  emailVerified: state.authReducer.emailVerified,
  companyInvites: state.invitesReducer.companyInvites,
  teamInvites: state.invitesReducer.invites,
  createdTeam: state.teamReducer.createdTeam,
  profileUpdated: state.authReducer.profileUpdated
})
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type ParamsTypes = {
  step: string
}
type Props = PropsFromRedux & {
}

const Signup = (props: Props) => {
  const { t } = useTranslation(["authPages", "common"])

  const params = useParams<ParamsTypes>()
  const {
    signup,
    user,
    error,
    sendEmailVerification,
    emailVerificationSent,
    verifyEmail,
    emailVerified,
    teamInvites,
    acceptInvite,
    companyInvites,
    acceptCompanyInvite,
    getCompanyInvites,
    getMyInvites,
    sigininin,
    profileUpdated,
    updateProfile
  } = props
  const [email, setEmail] = useState("")
  const [firstname, setFirstname] = useState("")
  const [lastname, setLastname] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)


  useEffect(() => {
    if (profileUpdated) {
      push('/')
    }
  }, [profileUpdated])
  const [verificationCode, setVerficationCode] = useState('')

  function handleShowPassword() {
    setShowPassword(!showPassword)
  }

  const { push } = useHistory()
  const companyInvite = companyInvites ? companyInvites[0] : undefined
  const teamInvite = teamInvites ? teamInvites[0] : undefined
  console.log(teamInvite);

  useEffect(() => {
    if (user && !profileUpdated) {
      //changeStep('')  
      push("/createAccount/step-03")

    }
  }, [user])

  useEffect(() => {
    if (companyInvite) {
      push('/createAccount/step-04')
    } else if (teamInvite) {
      push('/createAccount/step-05')
    }
  }, [companyInvite, teamInvite])

  const [emailValidated, validate] = useState<boolean | undefined>(undefined)
  const [firstnameValid, setFirstnameValid] = useState<boolean | undefined>(
    undefined
  )
  const [lastnameValid, setLastnameValid] = useState<boolean | undefined>(
    undefined
  )
  const firstnameRef = useRef(null)
  const lastnameRef = useRef(null)
  const passwordRef = useRef(null)

  const [selectedImage, selectImage] = useState<any>()
  const [code, setCode] = useState('')

  useEffect(() => {
    if (emailVerified) {
      signup({
        firstname,
        lastname,
        code,
        password,
        email
      })
      setCodeValidation(true)
    }
  }, [emailVerified])

  useEffect(() => {
    if (emailVerificationSent) {
      push("/createAccount/step-02")
      getAllMyInvites(user?.email || '')
      getCompanyInvites(user?.email || '')
    }
  }, [emailVerificationSent])
  const [toast, setToast] = useState('')
  const [codeValidation, setCodeValidation] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    if (error) {
      setToast(error)
      if (error.toLowerCase().includes('invalid code')) {
        setCodeValidation(false)
      }
    }

  }, [error])

  useEffect(() => {
    if (params?.step === 'step-03') {
      if (user) {
        getAllMyInvites(user?.email)
      }

    }
  }, [params])

  const [resendCode, setResendCode] = useState(false)

  const [lastCheck, setLastCheck] = useState<Date>(new Date())
  useEffect(() => {
    if (params?.step === 'step-06') {
      if (companyInvite) {
        push('/createAccount/step-04')
      } else if (teamInvite) {
        push('/createAccount/step-05')
      }
    }
  }, [params, teamInvite, companyInvite])
  const [successToast, setSuccessToast] = useState('')


  useEffect(() => {
    if (code.length === 4) {
      verifyEmail(email, code)
    }
  }, [code])

  const [jobtitle, setJobtitle] = useState('')

  return (

    <>
      <img
        src="/img/logo-only.png"
        alt="Team Feedback logo"
        className="auth-logo"
      />
      <div className="content-wrapper" style={{ maxHeight: '100%' }}>
        <div className="content">
          {params?.step === 'step-01' && (
            <div className="content-card card-10">
              <div className="content-card-header">
                <p className="content-card-header-title">{t('common:Getstarted')}</p>
                <p className="content-card-header-subtitle">
                  {t('authPages:Createyouraccuntwiththree')}.
              </p>
              </div>

              <div className="content-card-main">
                <p className="content-card-main-label">{t('common:Email')}</p>
                <div className={`input-container ${emailValidated === undefined ? '' : emailValidated === true ? 'pass' : 'fail'}`}>
                  <input type="text" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} onBlur={() => {
                    if (email) {
                      validate(validateEmail(email))
                    } else {
                      validate(false)
                    }
                  }} />
                  <span>
                    <i className="icon-feather-mail"></i>
                  </span>
                </div>

                <div className="input-container-wrapper">
                  <div className="input-container-double">
                    <p className="content-card-main-label">{t('common:Firstname')}</p>
                    <div className={`input-container ${firstnameValid === undefined ? '' : firstnameValid === true ? 'pass' : 'fail'}`}>
                      <input type="text" placeholder={`${t('common:Enterfirstname')}`} onChange={(e) => setFirstname(e.target.value)} onBlur={() => {
                        setFirstnameValid(validateName(firstname))
                      }} />
                    </div>
                  </div>

                  <div className="input-container-double">
                    <p className="content-card-main-label">{t('common:Lastname')}</p>
                    <div className={`input-container ${lastnameValid === undefined ? '' : lastnameValid === true ? 'pass' : 'fail'}`} onBlur={() => {
                      setLastnameValid(validateName(lastname))
                    }} >
                      <input type="text" placeholder={`${t('common:Enterlastname')}`} onChange={(e) => setLastname(e.target.value)} />
                    </div>
                  </div>
                </div>

                <p className="content-card-main-label">{t('authPages:Choosepassowrd')}</p>
                <div className={`input-container`}>
                  <input type={showPassword ? 'text' : 'password'} placeholder={`${t('authPages:Choosepassowrd')}`} onChange={(e) => setPassword(e.target.value)} />
                  <div className={`show-password ${showPassword && 'show'}`}>
                    <i className="icon-eye-open" onClick={handleShowPassword}></i>
                    <i className="icon-eye-closed" onClick={handleShowPassword}></i>
                  </div>
                </div>

                <p className="content-card-main-text-small">
                  {t('authPages:disclaimer')}{" "}
                  <button>{t('common:TermsofService')}</button> {t('authPages:andAcknowledge')}{" "}<button>{t('common:PrivacyPolicy')}</button>
                </p>

                <button
                  className="button button-green-primary"
                  onClick={() => {
                    if (validateEmail(email) && firstnameValid && lastnameValid && password) {
                      if (password.length < 8) {
                        setToast('Your password must contain atleast 8 characters.')
                        return
                      }
                      sendEmailVerification(email)
                    } else {
                      let toastMessage = ''
                      if (!email) {
                        toastMessage = toastMessage ? + toastMessage + ', please enter a valid email' : 'Please enter a valid email'
                      }
                      if (!firstname) {
                        toastMessage = toastMessage ? + toastMessage + ', please enter your first name' : 'Please enter your first name'
                      }
                      if (!lastname) {
                        toastMessage = toastMessage ? + toastMessage + ', please enter your last name' : 'Please enter your first name'
                      }
                      if (!password) {
                        toastMessage = toastMessage ? + toastMessage + ', please enter a password' : 'Please enter a password'
                      }
                      setToast(toastMessage)
                    }

                  }}
                >
                  {t('common:Getstartedforfree')} {sigininin && (<span style={{ marginLeft: '9px' }}><Loader /></span>)}
                </button>

                <p className="content-card-main-text-small">
                  {t('authPages:Alreadyhaveanaccount')} <button onClick={() => push('/')}>{t('common:Signin')}</button>
                </p>
              </div>
            </div>
          )}
          {params?.step === 'step-02' && (
            <div className="content-card card-10">
              <div className="content-card-header">
                <button className="arrow-back" onClick={() => push('/')}><i className="icon-arrow-left"></i></button>
                <p className="content-card-header-title">
                  {t('common:Pleaseverifyyouremail')}
                </p>
                <p className="content-card-header-subtitle">
                  {t('common:Wevesentyouaverification')}{' '}<button>{email}</button>
                </p>
              </div>

              <div className="content-card-main">
                <p className="content-card-main-label">{t('common:Confirmationcode')}</p>
                <div className={`input-container ${codeValidation === undefined ? '' : codeValidation === true ? 'pass' : 'fail'}`}>
                  <input type="text" placeholder="Enter verification code" onChange={(e) => setCode(e.target.value)} />
                  <span>
                    <i className="icon-material-lock"></i>
                  </span>
                </div>

                <button
                  className="button button-green-primary"
                  onClick={() => {
                    if (code) {
                      verifyEmail(email, code)
                    }
                  }}
                >
                  {t('common:Registernewteam')}{' '}{sigininin && (<span style={{ marginLeft: '9px' }}><Loader /></span>)}
                </button>
                <p className="content-card-main-text-small" onClick={() => setResendCode(true)} >
                  <button className="regular">{t('common:Resendcode')}</button>
                </p>
              </div>
            </div>
          )}

          {params?.step === 'step-03' && (
            <div className="content-card card-02">
              <div className="content-card-main">
                <p className="content-card-main-title">{t('createJoinCompany:Howwouldyouliketogetstarted')}</p>
                <p className="content-card-main-text">{t('createJoinCompany:Getstartedwiththreesimplesteps')}.</p>

                <div className="cards">
                  <div className="card">
                    <img src="/img/company-choose-01.png" alt="Clip art" className="card-img" />
                    <p className="card-title">{t('createJoinCompany:Joinaregisteredcompany')}</p>
                    <p className="card-text">{t('createJoinCompany:Invitationrequired')}</p>

                    <button className="button button-primary" onClick={() => {
                      if (companyInvites && companyInvites[0]) {
                        push('/createAccount/step-04')
                        //changeStep('step-04')
                      } else if (teamInvites && teamInvites[0]) {
                        push('/createAccount/step-05')
                        //changeStep('step-05')
                      } else {
                        push('/createAccount/step-06')
                        //changeStep('step-06')
                      }
                    }}>{t('common:Continue')}</button>
                  </div>

                  <div className="divider"></div>

                  <div className="card">
                    <img src="/img/company-choose-02.png" alt="Clip art" className="card-img" />
                    <p className="card-title">{t('createJoinCompany:Registeranewteam')}</p>
                    <p className="card-text">{t('createJoinCompany:Basicfeaturesfreeofcharge')}</p>

                    <button className="button button-primary" onClick={(e) => push('/createNewTeam?showSuccess=yes')}>{t('common:Continue')}</button>
                  </div>
                </div>
              </div>
            </div>
          )}



          {params?.step === 'step-06' && (
            <div className="content-card card-03">
              <div className="content-card-main">
                <p className="content-card-main-title">{t('createJoinCompany:Joinaregisteredteam')}</p>
                <p className="content-card-main-text">{t('createJoinCompany:Getstartedwiththreesimplesteps')}.</p>

                <img src="/img/join-company-img.png" alt="Clip art" className="content-card-main-img" />

                <button className="button button-primary modal-trigger" onClick={(e) => {
                  getMyInvites(user?.email || '')
                  setLastCheck(new Date())
                }}>{t('createJoinCompany:Recheckinvitationstatus')}</button>
                <p className="content-card-main-text-bottom" onClick={() => push('/createAccount/joinTeamSuccess')}>{moment(lastCheck).fromNow()}</p>
              </div>
            </div>
          )}

          {params?.step === 'step-04' && (
            <div className="content-card card-04">
              <div className="content-card-main">

                <p className="content-card-main-title">{t('createJoinCompany:Joinaregisteredteam')}</p>
                <p className="content-card-main-text">{t('createJoinCompany:Youhavebeeninvitedtoteam')}</p>

                <div className="content-card-main-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="72.737" height="75.245" viewBox="0 0 72.737 75.245">
                    <path d="M43.065,8H23v5.016H43.065V65.688H23V70.7H48.082V13.016A5.016,5.016,0,0,0,43.065,8Z"
                      transform="translate(24.655 4.541)" fill="#fff" />
                    <path
                      d="M43.83,3H9.317A5.317,5.317,0,0,0,4,8.317V78.245H49.147V8.317A5.317,5.317,0,0,0,43.83,3Zm.3,70.229H36.606V65.7H16.541v7.524H9.016V8.317a.3.3,0,0,1,.3-.3H43.83a.3.3,0,0,1,.3.3Z"
                      transform="translate(-4 -3)" fill="#fff" />
                    <path d="M8,8h5.016v5.016H8Z" transform="translate(2.033 4.541)" fill="#fff" />
                    <path d="M12,8h5.016v5.016H12Z" transform="translate(8.065 4.541)" fill="#fff" />
                    <path d="M16,8h5.016v5.016H16Z" transform="translate(14.098 4.541)" fill="#fff" />
                    <path d="M8,13h5.016v5.016H8Z" transform="translate(2.033 12.082)" fill="#fff" />
                    <path d="M12,13h5.016v5.016H12Z" transform="translate(8.065 12.082)" fill="#fff" />
                    <path d="M16,13h5.016v5.016H16Z" transform="translate(14.098 12.082)" fill="#fff" />
                    <path d="M8,18h5.016v5.016H8Z" transform="translate(2.033 19.622)" fill="#fff" />
                    <path d="M12,18h5.016v5.016H12Z" transform="translate(8.065 19.622)" fill="#fff" />
                    <path d="M16,18h5.016v5.016H16Z" transform="translate(14.098 19.622)" fill="#fff" />
                    <path d="M8,23h5.016v5.016H8Z" transform="translate(2.033 27.163)" fill="#fff" />
                    <path d="M12,23h5.016v5.016H12Z" transform="translate(8.065 27.163)" fill="#fff" />
                    <path d="M16,23h5.016v5.016H16Z" transform="translate(14.098 27.163)" fill="#fff" />
                    <path d="M23,13h5.016v5.016H23Z" transform="translate(24.655 12.082)" fill="#fff" />
                    <path d="M27,13h5.016v5.016H27Z" transform="translate(30.688 12.082)" fill="#fff" />
                    <path d="M23,18h5.016v5.016H23Z" transform="translate(24.655 19.622)" fill="#fff" />
                    <path d="M27,18h5.016v5.016H27Z" transform="translate(30.688 19.622)" fill="#fff" />
                    <path d="M23,23h5.016v5.016H23Z" transform="translate(24.655 27.163)" fill="#fff" />
                    <path d="M27,23h5.016v5.016H27Z" transform="translate(30.688 27.163)" fill="#fff" />
                  </svg>
                </div>

                <p className="content-card-main-company">{companyInvite?.company.name}</p>
                <p className="content-card-main-inviter">{t('createJoinCompany:Inviter')}: {companyInvite?.sender.firstname + ' ' + companyInvite?.sender.lastname}</p>

                <button className="button button-primary modal-trigger" onClick={(e) => {
                  acceptCompanyInvite(companyInvite?._id || '', companyInvite?.company._id || '')
                  push('/createAccount/joinTeamSuccess')

                }}>{t('createJoinCompany:Jointeam')}</button>
                <button className="content-card-main-text-bottom">{t('createJoinCompany:Denyinvitation')}</button>
              </div>
            </div>
          )}

          {params?.step === 'step-05' && (
            <div className="content-card card-04">
              <div className="content-card-main">

                <p className="content-card-main-title">{t('createJoinCompany:Joinaregisteredteam')}</p>
                <p className="content-card-main-text">{t('createJoinCompany:Youhavebeeninvitedtoteam')}</p>

                <div className="content-card-main-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="72.737" height="75.245" viewBox="0 0 72.737 75.245">
                    <path d="M43.065,8H23v5.016H43.065V65.688H23V70.7H48.082V13.016A5.016,5.016,0,0,0,43.065,8Z"
                      transform="translate(24.655 4.541)" fill="#fff" />
                    <path
                      d="M43.83,3H9.317A5.317,5.317,0,0,0,4,8.317V78.245H49.147V8.317A5.317,5.317,0,0,0,43.83,3Zm.3,70.229H36.606V65.7H16.541v7.524H9.016V8.317a.3.3,0,0,1,.3-.3H43.83a.3.3,0,0,1,.3.3Z"
                      transform="translate(-4 -3)" fill="#fff" />
                    <path d="M8,8h5.016v5.016H8Z" transform="translate(2.033 4.541)" fill="#fff" />
                    <path d="M12,8h5.016v5.016H12Z" transform="translate(8.065 4.541)" fill="#fff" />
                    <path d="M16,8h5.016v5.016H16Z" transform="translate(14.098 4.541)" fill="#fff" />
                    <path d="M8,13h5.016v5.016H8Z" transform="translate(2.033 12.082)" fill="#fff" />
                    <path d="M12,13h5.016v5.016H12Z" transform="translate(8.065 12.082)" fill="#fff" />
                    <path d="M16,13h5.016v5.016H16Z" transform="translate(14.098 12.082)" fill="#fff" />
                    <path d="M8,18h5.016v5.016H8Z" transform="translate(2.033 19.622)" fill="#fff" />
                    <path d="M12,18h5.016v5.016H12Z" transform="translate(8.065 19.622)" fill="#fff" />
                    <path d="M16,18h5.016v5.016H16Z" transform="translate(14.098 19.622)" fill="#fff" />
                    <path d="M8,23h5.016v5.016H8Z" transform="translate(2.033 27.163)" fill="#fff" />
                    <path d="M12,23h5.016v5.016H12Z" transform="translate(8.065 27.163)" fill="#fff" />
                    <path d="M16,23h5.016v5.016H16Z" transform="translate(14.098 27.163)" fill="#fff" />
                    <path d="M23,13h5.016v5.016H23Z" transform="translate(24.655 12.082)" fill="#fff" />
                    <path d="M27,13h5.016v5.016H27Z" transform="translate(30.688 12.082)" fill="#fff" />
                    <path d="M23,18h5.016v5.016H23Z" transform="translate(24.655 19.622)" fill="#fff" />
                    <path d="M27,18h5.016v5.016H27Z" transform="translate(30.688 19.622)" fill="#fff" />
                    <path d="M23,23h5.016v5.016H23Z" transform="translate(24.655 27.163)" fill="#fff" />
                    <path d="M27,23h5.016v5.016H27Z" transform="translate(30.688 27.163)" fill="#fff" />
                  </svg>
                </div>

                <p className="content-card-main-company">{teamInvite?.teamName}</p>
                <p className="content-card-main-inviter">{t('createJoinCompany:Inviter')}: {teamInvite?.sender.firstname + ' ' + teamInvite?.sender.firstname}</p>

                <button className="button button-primary modal-trigger" onClick={(e) => {
                  acceptInvite(teamInvite?._id || '')
                  //setTimeout(() => push('/'), 500)
                  push('/createAccount/joinTeamSuccess')
                }}>{t('createJoinCompany:Jointeam')}</button>
                <button className="content-card-main-text-bottom">{t('createJoinCompany:Denyinvitation')}</button>
              </div>
            </div>
          )}
          {params?.step === 'joinTeamSuccess' && <div className="content-card card-13">
            <div className="content-card-header">
              <p className="content-card-main-title">{t('common:Teamjoinedsuccesfully')}</p>
              <p className="content-card-main-text">{t('common:Yourteamsasksyoutoadd')}.</p>
            </div>
            <div className="content-card-main">



              {!selectedImage ? <div className="content-card-main-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="81.669" height="67.548" viewBox="0 0 81.669 67.548">
                  <g transform="translate(2 2)">
                    <path
                      d="M79.169,60.987a7.061,7.061,0,0,1-7.061,7.061H8.561A7.061,7.061,0,0,1,1.5,60.987V22.152a7.061,7.061,0,0,1,7.061-7.061H22.683L29.743,4.5H50.926l7.061,10.591H72.109a7.061,7.061,0,0,1,7.061,7.061Z"
                      transform="translate(-1.5 -4.5)" fill="none" stroke="#fff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="4" />
                    <path d="M40.243,27.622A14.122,14.122,0,1,1,26.122,13.5,14.122,14.122,0,0,1,40.243,27.622Z"
                      transform="translate(12.713 7.683)" fill="none" stroke="#fff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="4" />
                  </g>
                </svg>
              </div> :

                <img className="content-card-main-img" src={URL.createObjectURL(selectedImage)} alt="Team logo" />}

              <input type="file" accept="image/*" id="uploadImg" hidden onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  selectImage(e.target.files[0])
                }
              }} />
              <label htmlFor="uploadImg" className="content-card-main-upload">{t('common:Uploadimage')}</label>


              <p className="content-card-main-label">{t('common:Yourjobtitle')}</p>
              <div className="input-container">
                <input type="text" onChange={(e) => setJobtitle(e.target.value)} />
                <span>
                  <i className="icon-feather-briefcase"></i>
                </span>
              </div>

              <button className="button button-primary modal-trigger" onClick={() => {
                if (jobtitle && selectedImage) {
                  updateProfile({
                    image: selectedImage,
                    jobtitle
                  })
                } else {
                  window.alert('Select image and enter job title')
                }

              }}>{t('common:Confirm')}</button>
            </div>

          </div>}

        </div>
        <Toaster type={'error'} instance={!!toast} text={toast} clear={() => setToast('')} />
        <Toaster type={'success'} instance={!!successToast} text={successToast} clear={() => setSuccessToast('')} />
        <ComfirmationModal show={resendCode} onClose={() => setResendCode(false)} onAccept={() => {
          sendEmailVerification(email)
          setResendCode(false)
          setSuccessToast('Email resent.')
        }} />
      </div>
    </>
  )
}

export default connector(Signup)
