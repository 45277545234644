import React from 'react'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import Loader from 'react-loader-spinner'
const ButtonLoader = () => {
  return (
    <Loader
      type="TailSpin"
      color="#087af9"
      height={20}
      width={20}
      timeout={500} //3 secs
    />
  )
}

export default ButtonLoader
