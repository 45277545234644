
const en = {
  giveFeedbackPage: {
    giveFeedback: "Give Feedback",
    gridView: "Grid view",
    todoList: "Todo list",
    Noteammembersyet: "No team members yet",
    Thisteamdoesnthavemember: "This team doesn’t have any members at the moment",
    toinviteyourfirstteammembers: "to invite your first team members",
  },

  teamMembersPage: {
    teamMembers: "Team members",
    tableView: "Table view",
    gridView: "Grid view",
    averageFeedback: "Average feedback",
    developmentTrend: "Development trend",
    Accounttype: "Account type",
    Userswithviewingrightshaveaccess: "Users with viewing rights have access to the complete feedback data of this team",

    placeholder: {
      title: "No team members in this team",
      text: "to add your first team members",
      text02: "to invite new team members",
    }
  },

  feedbackChartsPage: {
    navigation: {
      teamFeedback: "Team Feedback",
      p2pFeedback: "P2P Feedback",
      selfEvaluation: "Self Evaluation",
      supervisoryFeedback: "Supervisory Feedback",
      allWidgets: "All widgets",
    }
  },

  teamFeedbackPage: {
    placeholder: {
      title: "No feedback for this question yet",
      text: "Please select an other question from the menu above",
    }
  },

  appUsage: {
    Appusage: "App usage",
    Amountofgivenfeedbacklast30days: "Amount of given feedback last 30 days",
    Amountoffeedbackrequests: "Amount of feedback requests",
    Amountofteammembers: "Amount of team members",
  },

  teamSettingsGeneralPage: {
    Generalsettings: "General settings",
    pageHeaderSubtitle: "Here you can add, manage and delete members of this team.",
    Teamname: "Team name",
    Teamdescription: "Team description",
    Teamimage: "Team image",
    Teamfeedbackfrequency: "Team feedback frequency",
    selectHowOften: "Select how often team feedback will be requested",
    Notlinkedyet: "Not linked yet",
    Linkteamtoacompany: "Link team to a company",
    Teamdescriptionmissing: "Team description missing",
    HowtoshowP2Pfeedbackstoreceiver: "How to show P2P feedbacks to receiver",
    Showonlycommentswithsender: "Show only comments with sender",
    Showalsogivenstarvalues: "Show also given star values with sender",
    Whatteamdatawillteammemberssee: "What team data will team members see",
  },

  teamSettingsMembersPage: {
    pageHeaderSubtitle: "Here you can add, manage and delete members of this team.",
    placeholderTitle: "This team doesn’t yet have any team members",
  },

  teamSettingsLeadPage: {
    pageHeaderSubtitle: "Here you can add and delete team leaders.",
    placeholderTitle: "This team doesn’t yet have any Team leaders",
  },

  teamSettingsViewPage: {
    pageHeaderSubtitle: "Users with viewing rights have access to view the feedback data of this team",
    placeholderTitle: "This team doesn’t yet have any Viewing rights",
    toaddviewingrights: "to add viewing rights",
  },

  teamSettingsAdminPage: {
    pageHeaderSubtitle: "Users with admin rights have full access to the feedback data and they can manage all team settings",
    placeholderTitle: "This team doesn’t yet have any team members",
  },

  feedbackInputPage: {
    Addanewquestion: "Add a new question",
    Hereyoucanadd: "Here you can add, edit and delete members of this team",
    Questions: "Questions",
    ADDNEWQUESTION: "ADD NEW QUESTION",
    Frequency: "Frequency",
    Selecthowoftentheteamfeedback: "Select how often the team feedback should be collected",
    Thistheplacetocreate: "This the place to create your feedback routines",
    Feedbackcategory: "Feedback category",
    Starquestions: "Star questions",
    Textquestions: "Text questions",
  },

  feedbackRequestPage: {
    pageHeaderSubtitle: "Please select a team member to manage his requests.",
    Noteammembersselected: "No team memeber selected",
    pleaseSelect: "Please select a team member from above to start addingrequests",
  },

  userSettingsGeneralPage: {
    pageHeaderSubtitle: "Having a question about these settings?",
    Accountlinktocompany: "Account link to company",
    Dashboardlanguage: "Dashboard language",
  },

  userSettingsTeamsPage: {
    pageHeaderSubtitle: "Here you see all your teams and you can quit them here",
  },

  companyOverviewPage: {
    Amountoffeedback: "Amount of feedback",
    Last30days: "Last 30 days",
    Last3months: "Last 3 months",
    Last12months: "Last 12 months",
    totalGivenFeedback: "Total given feedback",
    teamFeedbacks: "Team feedbacks",
    supervisorFeedbacks: "Supervisor feedbacks",
    selfEvaluations: "Self evaluations",
    subordinateFeedbacks: "Subordinate feedbacks",
    Youraccess: "Your access",
    completeCompanyAdminAccess: "Complete company admin access",
    Pendinginvitation: "Pending invitation",
    Mobileappsusers: "Mobile apps users",
    Dashboardusers: "Dashboard users",
    Signedinmobileapp: "Signed in mobile app",
    Signedindashboard: "Signed in dashboard",
    Totalmonthlyfeedbackamount: 'Total monthly feedback amount',
    Subordinatemonthlyfeedback: "Subordinate monthly feedback amount",
    Supervisormonthlyfeedback: "Supervisor monthly feedback amount",
    P2pmonthlyfeedback: "p2p monthly feedback amount",
    Selfevaluationmonthlyfeedback: "Monthly self evaluations amount",
    Teammonthlyfeedback: "Team monthly feedback amount",
    placeholderTitle: "No employees connected yet to this company",
    placeholderText: "to invite your first employees",
    Noemployeesarchivedyet: "No employees archived yet",
  },

  companyAdminPage: {
    pageHeaderSubtitle: "Users with company admin rights have full access to manage this company",
  },

  companyEmployeesPage: {
    Activeemployees: "Active employees",
    Archivedemployees: "Archived employees",
  },

  companyTeamsPage: {
    Activeteams: "Active teams",
    Archivedteams: "Archived teams",
    placeholderTitle: "No teams connected to this company yet",
    placeholderText: "to see how teams are connected",
  },

  feedbackSummaryBox: {

  },

  navigation: {
    wantstoinveteyouas: "wants to invite you as an",
    Billing: "Billing",
    Support: "Support",
    Documentation: "Documentation",
    Overallcharts: "Overall charts",
    Teammemberchart: "Team membercharts",
    Teamleadcharts: "Team lead charts",
    Addnewwidgets: "Add new widgets",
    Themecolor: "Theme color",
    notificationPlaceholderText: "You don’t have any notifications at the moment",
    Openusersettings: "Open user settings",
    Widgettest: "Widget test",
    Cleardatabase: "Clear database",
  },

  loader: {
    placeholderTitle: "Your dashboard is being set up",
    placeholderText: "Usually it takes just a few seconds",
  },

  addNewQuestionModal: {
    Addnewquestion: "Add new question",
    Selecttype: "Select type",
    Starrating: "Star rating",
    Textentry: "Text entry",
    Enterquestion: "Enter question",
    Addquestion: "Add question",
  },

  addWidgetModal: {

  },

  changePasswordModal: {
    Changepasswword: "Change passwod",
    Currentpasswword: "Current passwod",
    Chooseanewpasswword: "Choose a new passwod",
    Confirmnewpasswword: "Confirm new passwod",
    Enterpassword: "Enter password",
    Enternewpassword: "Enter new password",
    Confirmnewpassword: "Confirm new password",
  },

  customerSupportModal: {
    Contactcustomerservice: "Contact customer service",
    Selecttopic: "Select topic",
    Generalsupport: "General support",
    Featurerequest: "Feature request",
    Bugreport: "Bug report",
  },

  createCompanyModal: {
    Registeranewcompany: "Register a new company",
    modalSubtitle: "Team Feedback is free of charge for companies up to 100 employees",
    Wanttojoinregisteredcompany: "Want to join a registered company?",
  },

  createTeamModal: {
    Addteamname: "Add team name",
    Addteamdescription: "Add team description",
    Theteamnameshouldbecompact: "The team name should be compact for examples 'Sales' or 'Marketing'. Then add a short description to specify the team.",
    Youcanaddpeople: "You can add people who are already registered by search them with their name. If you want to add people who are not yet registered you have to enter their emails."
  },

  giveFeedbackModal: {
    Windowcolosesautomaticly: "Window closes automatically in 3 seconds",
  },

  firstTeamModal: {
    Createyoutfirstteam: "Create yout first team",
    Basicfeaturesfreeofcharge: "Basic features free of charge",
    Nameoftheteam: "Name of the team",
    Inviteteammembers: "Invite team members",
    Inviteyourpeopleandstart: " Invite your people and let’s start developing your teams",
    evenremotely: "even remotely",

  },

  imageModal: {
    Looksgood: "Looks good!",
    Uploadnew: "Upload new",
  },

  iviteEmployeesModals: {
    Enterheretheemailsofpeople: "Enter here the emails of the people who you want to invite",
  },

  inviteMembersModal: {
    SelectedPeople: "Selected people",
  },

  userFeedbackModal: {
    Nofeedbackdata: "No feedback data yet",
    Soonyouwillfind: "Soon you will find here the feedback data of",
    Summaryboxeslastmonths: "Summary boxes last three months",
    Feedbackfromcolleagues: "Feedback from colleagues",
    Feedbackfromsupervisors: "Feedback from supervisors",
  },

  addRightsModal: {
    Selecttypeofrights: "Select type of rights",
    Userswithviewingrights: "Users with viewing rights have full access to see the feedback data of this team"
  },

  createJoinCompany: {
    WelcometoTeamFeedback: "Welcome to Team Feedback",
    Averypowerfultool: "A very powerful tool to develop your teams",
    Howwouldyouliketogetstarted: "How would you like to get started",
    Getstartedwiththreesimplesteps: "Get started with three simple steps",
    Joinaregisteredcompany: "Join a registered company",
    Joinaregisteredteam: "Join a registered Team",
    Invitationrequired: "Invitation required",
    Registeranewteam: "Register a new team",
    Basicfeaturesfreeofcharge: "Basic features free of charge",
    Tojoinaregisteredcompany: "To join a registered company you have to be invited. Your email has not yet been invited.",
    Recheckinvitationstatus: "Recheck invitation status",
    Joinaregisteredteam: "Join a registered team",
    Tojoinaregisteredteam: "To join a registered team you have to be invited. Your email has not yet been invited.",
    Youhavebeeninvitedtocompany: "You have been invited to join the following company",
    Inviter: "Inviter",
    Joincompany: "Join company",
    Jointeam: "Join team",
    Youhavebeeninvitedtoteam: "You have been invited to join the following team",
    Denyinvitation: "Deny invitation",
    Makemeteamleaderofthisteam: "Make me team leader of this team",
    Everyteamneedsatleastoneteamleader: 'Every team needs at least one team leader. Keep this button enabled if you want to be the leader',
    TeamFeedbackisnothingwithoutateam: "Team Feedback is nothing without a team. Let's invite your first team members",
    Congratulationsyourfirstteam: "Congratulations your first team has been successfully registered",
    Wouldyoulikeustocreate: ">Would you like us to create a demo team for you that you can explore the dashboard before your own feedback data comes?",
    Yescrateademoteamforme: "Yes, crate a demo team for me",
    Idontneedademoteam: "I don't need a demo team",
  },

  authPages: {
    Createyouraccount: "Create your account",
    Chooseapassowrd: "Choose a password",
    Choosepassowrd: "Choose password",
    disclaimer: "By creating my account, I accept the Team Feedback",
    andAcknowledge: "and acknowledge the",
    Alreadyhaveanaccount: "Already have an account?",
    Signinto: "Sign in to",
    Forgottenpassword: "Forgotten your password?",
    Donthaveaccount: "Don’t have an account yet?",
    Createyoursnow: "Create yours now",
    Createyouraccuntwiththree: "Create your accunt with three simple steps",
    Startbyenteringyouremailinthefieldbelow: "Start by entering your email in the field below",
    Wehavesentyouverificationcode: "We have sent you verification code to you email",
    Setupnewpassword: "Setup new password",
    Newpasswordmustcontain: "New password must contain eight letters or more",
    Choosenewpassword: "Choose new password",
    Confirmnewpassword: "Confirm new password",
    Updatepassword: "Update password",
  },

  textFeedbackPages: {
    Textfeedbackquestions: "Text feedback questions",
    Addnewquestion: "Add new question",
    hasnotyetbeenansweredbyanyteammember: "has not yet been answered by any team member",
    Pleaseselectafeedback: "Please select a feedback to see here more informations about it",
    Fromteamleaders: "From team leaders",
    Therehasnotyetbeengiventeamleader: "There has not yet been given any feedback about the team leader",
    Allreviewsgivenanonymously: "All reviews given anonymously",
    Feedbackaboutteamleader: "Feedback about team leader",
    Therehasnotyetbeengivenaboutteamleader: "There has not yet been given any feedback about the team leader",
    Noselfevaluationyet: "hasn’t given any self evaluation yet",
    Addnewteamleaders: "Add new team leaders",
    Feedbackgivento: "Feedback given to",
  },

  common: {
    English: "English",
    Finnish: "Finnish",
    German: "German",
    Generalsettings: "General settings",
    Feedbacksettings: "General settings",
    search: "Search",
    inviteNewMembers: "Invite new members",
    teamFeedback: "Team Feedback",
    p2pFeedback: "P2P Feedback",
    p2pfeedback: "P2P feedback",
    p2pfeedbackthat: "P2P feedback that",
    hasnotreceivedanyp2pfeedbackyet: "has not received any p2p feedback yet",
    selfEvaluation: "Self Evaluation",
    supervisoryFeedback: "Supervisory Feedback",
    subordinateFeedback: "Subordinate Feedback",
    click: "click",
    Click: "Click",
    here: "here",
    noSearchResults: "No search results",
    toInviteNewTeamMembers: "to invite new team members",
    Name: "Name",
    Jobtitle: "Job title",
    Jobtitlemissing: "Job title missing",
    Average: "Average",
    feedback: "feedback",
    Openteam: "Open team",
    Openprofile: "Open profile",
    Givefeedback: "Give feedback",
    Deletefromteam: "Delete from team",
    Deletemember: "Delete member",
    Addjobtitle: "Add job title",
    Nodata: "No data",
    bad: "bad",
    poor: "poor",
    weak: "weak",
    okMinus: "okMinus",
    ok: "ok",
    okPlus: "okPlus",
    goodMinus: "goodMinus",
    good: "good",
    goodPlus: "goodPlus",
    great: "great",
    excellent: "excellent",
    amazing: "amazing",
    None: "None",
    Onceamonth: "Once a month",
    Everyweek: "Every week",
    Every2ndweek: "Every 2nd week",
    Every3rdweek: "Every 3rd week",
    Monthly: "Monthly",
    Teamname: "Team name",
    Teamdescription: "Team description",
    Teamimage: "Team image",
    Confirm: "Confirm",
    Edit: "Edit",
    Upload: "Upload",
    Link: "Link",
    Notlinkedyet: "Not linked yet",
    Linkteamtoacompany: "Link team to a company",
    teamOwner: "Team owner",
    Teammembers: "Team members",
    Addnew: "Add new",
    Email: "Email",
    Status: "Status",
    Enternameoremail: "Enter name or email",
    Usernotfound: "User not found",
    Pleaseentertheemailaddress: "Please enter the email address",
    Add: "Add",
    Cancel: "Cancel",
    Pending: "Pending",
    Delete: "Delete",
    Teamlead: "Team lead",
    Select: "Select",
    Viewingrights: "Viewing rights",
    Adminrights: "Admin rights",
    Questions: "Questions",
    Feedbackrequests: "Feedback requests",
    FeedbackRequests: "Feedback Requests",
    Feedbackinput: "Feedback settings",
    Selecttimeperiod: "Select time period",
    Weekly: "Weekly",
    Everysecondweek: "Every second week",
    Everysecondmonth: "Every second month",
    Requests: "Requests",
    Selectateammember: "Select a team member",
    Firstname: "First name",
    Lastname: "Last name",
    Enterjobtitle: "Enter job title",
    Password: "Password",
    Profilepicture: "Profile picture",
    Myteams: "My teams",
    Yourstatusintheteam: "Your status in the team",
    Deleteteam: "Delete team",
    Quitteam: "Quit team",
    Member: "Member",
    Admin: "Admin",
    Leader: "Leader",
    View: "View",
    Members: "Members",
    Clickhere: "Click here",
    CreateTeam: "Create Team",
    Createanewteam: "Create a new team",
    Companyadminarea: "Company admin area",
    Createnew: "Create new",
    Feedbackcharts: "Feedback charts",
    Teamsettings: "Team settings",
    SHORTCUTS: "SHORTCUTS",
    employee: "employee",
    employees: "employees",
    Employees: "Employees",
    oneDayago: "1 Day ago",
    Deny: "Deny",
    CompanyOverview: "Company Overview",
    Teams: "Teams",
    Adminrights: "Admin rights",
    Billing: "Billing",
    Support: "Support",
    Documentation: "Documentation",
    Settings: "Settings",
    Light: "Light",
    Default: "Default",
    Invitations: "Invitations",
    Rights: "Rights",
    Opencompany: "Open company",
    Createcompany: "Create company",
    Signout: "Sign out",
    Signin: "Sign in",
    Textfeedback: "Text feedback",
    Updated: "Updated",
    Peertopeer: "Peer to peer",
    Active: "Active",
    Viewprofile: "View profile",
    Restoreemployee: "Restore employee",
    Archiveemployee: "Archive employee",
    Deleteemployee: "Delete employee",
    Manage: "Manage",
    Invitenewemployees: "Invite new employees",
    Registeredemployees: "Registered employees",
    Last: "Last",
    Viewteam: "View team",
    Companyname: "Company name",
    Continue: "Continue",
    Enterhere: "Enter here",
    Addteammember: "Add team member",
    Readmore: "Read more",
    Givefeedbackto: "Give feedback to",
    Feedbacksent: "Feedback sent",
    Sendfeedback: "Send feedback",
    Sent: "Sent",
    Done: "Done",
    added: "added",
    Sendinvites: "Send invites",
    Addnewteammembers: "Add new team members",
    Searchbynameoremail: "Search by name or email",
    Selectfeedbacktype: "Select feedback type",
    Developmentcharts: "Development charts",
    Receivedfeedback: "Received feedback",
    Givenfeedback: "Given feedback",
    Selfevaluation: "Self evaluation",
    Closesection: "Close section",
    Opensection: "Open section",
    Lastmonth: "Last month",
    Last3month: "Last 3 month",
    Last6month: "Last 6 month",
    Closesection: "Close section",
    TermsofService: "Terms of Service",
    PrivacyPolicy: "Privacy Policy",
    Createaccount: "Create account",
    Enteremailandpassword: "Enter email and password",
    Toyourcolleagues: "To your colleagues",
    Toyoursubordinates: "To your subordinates",
    Toyoursupervisor: "To your supervisor",
    Toyourself: "To yourself",
    feedback: "feedback",
    Notifications: "Notifications",
    Clickheretoaddjobtitle: "Click here to add your job title",
    Feedbackaboutteamlead: "Feedback about team lead",
    selected: "selected",
    Thisquestionhasnodatayet: "This question has no data yet",
    Checkagainsoon: "Check again soon",
    Nodatayet: "No data yet",
    Backtofeedbackdata: "Back to feedback data",
    selfEvaluations: "Self evaluations",
    Allteammembers: "All team members",
    Feedbacktarget: "Feedback target",
    SixPermonth: "6 per month",
    EightPermonth: "8 per month",
    TenPermonth: "10 per month",
    TwelwePermonth: "12 per month",
    SixteenPermonth: "16per month",
    Mytodolist: "My todo list",
    Charts: "Charts",
    Thequestion: "The question",
    Nofeedbackselected: "No feedback selected",
    Feedbackgiven: "Feedback given",
    Text: "Text",
    Selectteammembers: "Select team members",
    Selectateammember: "Select a team member",
    Selectaquestion: "Select a question",
    Selectteamleader: "Select team leader",
    like: "like",
    Commented: "Commented",
    Liked: "Liked",
    Likethisfeedback: "Like this feedback",
    Teamleader: "Team leader",
    Givenby: "Given by",
    Teammember: "Team member",
    Nojobtitle: "No job title",
    hasreceived: "has received",
    Sent: "Sent",
    newfeedback: "new feedback",
    Nonewfeedback: "No new feedback",
    Theteammember: "The team member",
    Selfevaluations: "Self evaluations",
    Commentthisfeedback: "Comment this feedback",
    Help: "Help",
    Resetlayout: "Reset layout",
    Fullscreenmode: "Full screen mode",
    Doubleclickheader: "Double click header",
    Togoandexitfullscreen: "To go and exit full screen",
    Contentsize: "Content size",
    Noadjustment: "No adjustment",
    Appearance: "Appearance",
    Automatic: "Automatic",
    Recomended: "Recomended",
    Othersettings: "Other settings",
    Keepmesignedin: "Keep me signed in",
    Browsernotifications: "Browser notifications",
    Contactsupport: "Contact support",
    Searchforateam: "Search for a team",
    teammember: "team member",
    teamlead: "team lead",
    Enteremails: "Enter emails",
    Enteremail: "Enter email",
    Added: "Added",
    Nofeedbackgiveninthiscategoryyet: "No feedback given in this category yet",
    Checkagainsoon: "Check again soon",
    SelfEvaluations: "Self Evaluations",
    Letsstart: "Let's start",
    Lastcheck: "Last check",
    Sending: "Sending",
    Yourmessage: "Your message",
    permonth: "per month",
    NameYourTeam: "Name Your Team",
    AddMembers: "Add Members",
    Next: "Next",
    GoBack: "Go Back",
    Createteam: "Create team",
    Teamnameandshortdescription: "Team name and short description",
    Shortdescription: "Short description",
    Comment: "Comment",
    Twiceamonth: "Twice a month",
    Everythirdweek: "Every third week",
    Onemin: "1 min",
    Hereyoucanactivateteamfeedback: "Here you can activate or disable the team feedback",
    Disable: "Disable",
    Question: "Question",
    Shortform: "Short form",
    Enterquestion: "Enter question",
    Deletequestion: "Delete question",
    Savechanges: "Save changes",
    Subordinatefeedback: "Subordinate feedback",
    CommentedBy: "Commented by",
    Zoomlevel: "Zoom level",
    CreateJoincompany: "Create/Join company",
    Forgotpassword: "Forgot password",
    Wesentyouconfirmation: " We’ve sent you confirmation code to your email",
    Backtosignin: "Back to sign in",
    Donthaveanaccountyet: "Don’t have an account yet?",
    Pleaseenteryouremailaddress: "Please enter your email address",
    Pleaseenteryouremail: "Please enter your email",
    Pleaseselectanewpassword: "Please select a new password",
    Newpassword: "New password",
    Entercompanyname: "Enter company name",
    Appusage: "App usage",
    Sentteamfeedback: "Sent team feedback",
    Senttocolleagues: "Sent to colleagues",
    Senttoteamleader: "Sent to team leader",
    Sentselfevaluation: "Sent self evaluation",
    Whatwecandobetterinthisteam: "What we can do better in this team?",
    Receivedfromteammembers: "Received from team members",
    Receivedfromteamleader: "Received from team leader",
    Nofeedback: "No feedback",
    Youhaventreceivefeedbackyet: "You haven't receive any feedback yet",
    Feedbackgivenfromteammembertomember: "Feedback given from team member to member",
    Feedbackgivenfromteammembertohimself: "Feedback given from team member to himself",
    Feedbackabouttheteamleader: "Feedback about the team leader",
    Feedbackfromtheteammemberstotheteamleader: "Feedback from the team members to the team leader",
    Supervisorfeedback: "Supervisor feedback",
    Feedbackfromtheteamleadertotheteammembers: "Feedback from the team leader to the team members",
    Feedbackcollectedfromallteammembers: "Feedback collected from all team members",
    teammembers: "team members",
    Letsstart: "Let's start",
    Hereyoucanaddeditanddelete: "Here you can add, edit and delete team members and leaders.",
    Removeteamleader: "Remove team leader",
    Maketeamleader: "Make team leader",
    Giverights: "Give rights",
    Editjobtitle: "Edit job title",
    Deleteteammember: "Delete team member",
    Changetoadminrights: "Change to admin rights",
    Changetoviewingrights: "Change to viewing rights",
    Withdrawrights: "Withdraw rights",
    Donthaveanaccountyet: "Don't have an account yet?",
    Signupforfree: "Sign up for free",
    Sentteamfeedback: "Sent team feedback",
    Senttomyteamleader: "Sent to my team leader",
    Getstarted: "Get started",
    Enterfirstname: "Enter first name",
    Enterlastname: "Enter last name",
    Enterpassword: "Enter password",
    Getstartedforfree: "Get started for free",
    Pleaseverifyyouremail: "Please verify your email",
    Wevesentyouaverification: "We've sent you a verification code to your email",
    Confirmationcode: "Confirmation code",
    Registernewteam: " Register new team",
    Resendcode: "Resend code",
    Didntreceivecode: "Didn't receive code?",
    Teamjoinedsuccesfully: "Team joined succesfully",
    Yourteamsasksyoutoadd: "Your teams asks you to add a profile picture and a job title",
    Uploadimage: "Upload image",
    Yourjobtitle: "Your job title",
    Weeseeyouhaveaninvitation: "We see you have an invitation waiting but first please confirm your email",
    Createyourfirstteam: "Create your first team",
    Pleasegiveyourteamanameandandadescription: "Please give your team a name and and a description",
    Enterteamname: "Enter team name",
    Enterteamdescription: "Enter team description",
    Quitteamcreation: "Quit team creation",
    Addteammembers: "Add team members",
    Tothedashboard: "To the dashboard",
    Onemorething: "One more thing",
    Addnewrights: "Add new rights",
    Skipthisstepandinvitelater: "Skip this step and invite later",
    Addteamleaders: "Add team leaders",
    Havingaquestionaboutit: "Having a question about it?",
    Free: "Free",
    Seeotherplans: "See other plans",
    Upgrade: "Upgrade",
    Given: "Given",
    of: "of",
    Otherfeedback: "Other feedback",
    Used: "Used",
    Teammembers: "Team members",
    Momentcharts: "Moment charts",
    Membertable: "Member table",
    Searchorenteremail: "Search or enter email",
    Confirmation: "Confirmation",
    Areyousuretoremovethisteam: "Are you sure to remove this team?",
    Didntreceivethecode: "Didn't receive the code",
    Firstpleasecheckyourspamfolder: "First please check your spam folder. If you still can not find the code, click resend",
    optional: "optional",
    Myfeedback: "My feedback",
    Markallread: "Mark all read",
  }
}
export default en
