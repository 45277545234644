import React, { useState } from 'react'
import ImageUpload from 'src/components/modals/imageUpload'
import ComfirmationModal from 'src/components/modals/comfirmationModal'
import { AppDispatch, RootState } from 'src/configureStore'
import { connect, ConnectedProps } from 'react-redux'
import { useEffect } from 'react'
import { linkTeam, updateTeamProfile } from 'src/redux/teams/actions'
import { UpdateTeamProfileVariable } from 'src/apiTypes'
import { IMAGE_API_ROOT } from 'src/request'
import { clearTeamToaster, getCompanyByUSerId } from 'src/redux/teams/actions'
import { capitalize } from 'src/utitlity'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'


const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  selectedTeam: state.teamReducer.selectedTeam,
  toaster: state.teamReducer.toaster,
  company: state.teamReducer.userCompany
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateTeamProfile: (data: UpdateTeamProfileVariable) =>
    dispatch(updateTeamProfile(data)),
  clearToast: () => dispatch(clearTeamToaster()),
  getCompany: () => dispatch(getCompanyByUSerId()),
  linkTeam: (teamId: string) => dispatch(linkTeam(teamId))
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  getTeamId(teamId: string): void
}
interface ParamTypes {
  teamId?: string
}
const GeneralSettings = (props: Props) => {
  const { t } = useTranslation(['teamSettingsGeneralPage', 'common'])
  const {
    selectedTeam,
    updateTeamProfile,
    toaster,
    clearToast,
    getTeamId,
    user,
    getCompany,
    company,
    linkTeam
  } = props


  useEffect(() => {
    if (user) {
      console.log('user: ', user);

      getCompany()
    }
  }, [user])
  console.log('company: ', company);

  const [teamName, setTeamName] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (selectedTeam) {
      setTeamName(capitalize(selectedTeam.name))
      setDescription(capitalize(selectedTeam.description))
    }
  }, [selectedTeam])

  useEffect(() => {
    let editLinks = document.querySelectorAll(
      '.settings-table-row-column-03 .settings-table-link'
    )

    editLinks.forEach((e: any) => {
      e.addEventListener('click', function () {
        console.log('clicked')
        //@ts-ignore
        e.parentElement.parentElement.classList.toggle('edit')
      })
    })
  }, [])

  const [selectedImage, selectImage] = useState<any>(null)
  useEffect(() => {
    if (selectedImage) {
      document.getElementById('imageUploadModal')?.classList.add('open')
    }
  }, [selectedImage])
  const returnFreq = (fre?: number) => {
    if (fre === 1) {
      return t('common:Everyweek')
    }
    if (fre === 2) {
      return t('common:Every2ndweek')
    }
    if (fre === 3) {
      return t('common:Every3rdweek')
    }
    if (fre === 4) {
      return t('common:Monthly')
    }
    return t('common:Every2ndweek')
  }

  const freq = returnFreq(selectedTeam?.frequency)
  const tlFreq = returnFreq(selectedTeam?.tlFrequency)
  const params = useParams<ParamTypes>()
  const [showLink, setShowLink] = useState(false)

  useEffect(() => {
    if (params.teamId) {
      getTeamId(params.teamId)
    }
  }, [params])


  return (
    <div
      className="content-wrapper"
      onClick={() =>
        document.getElementById('mainDrawer')?.classList.remove('open')
      }
    >
      <div className="content">
        <div className="content-card">
          <div className="content-card-header">
            <div className="content-card-header-caption">
              <p className="content-card-header-caption-title">
                {t('teamSettingsGeneralPage:Generalsettings')}
              </p>
              <p className="content-card-header-caption-text">
                {t('teamSettingsGeneralPage:pageHeaderSubtitle')}
              </p>
            </div>
          </div>
          <div className="content-card-main">
            <div className="settings-table">
              <div className="settings-table-row row-01 editable">
                <div className="settings-table-row-column-01">
                  <p className="settings-table-text">{t('common:Teamname')}</p>
                </div>

                <div className="settings-table-row-column-02 edit">
                  <input
                    type="text"
                    name="teamName"
                    value={teamName}
                    id="teamname"
                    placeholder={`${t('common:Teamname')}...`}
                    className="settings-input"
                    onChange={(e) => {
                      if (selectedTeam) {
                        setTeamName(e.target.value)
                        updateTeamProfile({
                          name: e.target.value,
                          teamId: selectedTeam._id,
                        })
                      }
                    }}
                  />
                </div>

                <div className="settings-table-row-column-03 edit">
                  <button className="settings-table-link">
                    {t('common:Confirm')}
                  </button>
                </div>

                <div className="settings-table-row-column-02">
                  <p className="settings-table-text">{teamName}</p>
                </div>

                <div className="settings-table-row-column-03">
                  <button className="settings-table-link">
                    {t('common:Edit')}
                  </button>
                </div>
              </div>

              <div className="settings-table-row row-02 editable">
                <div className="settings-table-row-column-01">
                  <p className="settings-table-text">
                    {t('common:Teamdescription')}
                  </p>
                </div>

                <div className="settings-table-row-column-02 edit">
                  <input
                    type="text"
                    name="teamDescription"
                    id="teamDescription"
                    defaultValue={selectedTeam?.description}
                    placeholder={`${t('common:Teamdescription')}...`}
                    className="settings-input desc "
                    onChange={(e) => {
                      if (selectedTeam) {
                        updateTeamProfile({
                          description: e.target.value,
                          teamId: selectedTeam._id,
                        })
                      }
                    }}
                  />
                </div>

                <div className="settings-table-row-column-03 edit">
                  <button className="settings-table-link">
                    {t('common:Confirm')}
                  </button>
                </div>

                <div className="settings-table-row-column-02">
                  <p className="settings-table-text">
                    {selectedTeam?.description ||
                      t('teamSettingsGeneralPage:Teamdescriptionmissing')}
                  </p>
                </div>

                <div className="settings-table-row-column-03">
                  <button className="settings-table-link">
                    {t('common:Edit')}
                  </button>
                </div>
              </div>

              <div className="settings-table-row row-03">
                <div className="settings-table-row-column-01">
                  <p className="settings-table-text">{t('common:Teamimage')}</p>
                </div>

                <div className="settings-table-row-column-02">
                  <div className="settings-table-icon">
                    {selectedTeam?.profilePic ? (
                      <img
                        src={IMAGE_API_ROOT + selectedTeam.profilePic}
                        className="settings-table-img"
                      />
                    ) : (
                      <i className="icon-users-circle"></i>
                    )}
                  </div>
                </div>

                <div className="settings-table-row-column-03">
                  <label
                    className="settings-table-link additional-info-img"
                    htmlFor="uploadImage"
                  >
                    {t('common:Upload')}
                  </label>
                  <input
                    type="file"
                    name="uploadImage"
                    id="uploadImage"
                    hidden
                    onChange={(e) => {
                      if (selectedTeam && e.target.files && e.target.files[0]) {
                        selectImage(e.target.files[0])
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          image: e.target.files[0],
                        })
                      }
                    }}
                  />
                </div>
              </div>

              <div className="settings-table-row row-04">
                <div className="settings-table-row-column-01">
                  <div className="settings-table-info">
                    <p className="settings-table-info-title">
                      {t('common:Feedbacktarget')}
                    </p>
                    <p className="settings-table-info-subtitle">
                      {t('teamSettingsGeneralPage:selectHowOften')}
                    </p>
                  </div>
                </div>

                <div className="settings-table-row-column-03">
                  <div className="custom-dropdown">
                    <div
                      className="custom-dropdown-trigger"
                      tabIndex={-1}
                      onClick={(e) => e.currentTarget.focus()}
                    >
                      <p className="value">{selectedTeam?.feedbackTarget + ` ${t('common:permonth')}`}</p>
                      <i className="icon-caret-down"></i>
                    </div>
                    <div className="custom-dropdown-menu">
                      <div className="custom-dropdown-menu-item" onClick={() => {
                        if (selectedTeam) {
                          updateTeamProfile({
                            feedbackTarget: 6,
                            teamId: selectedTeam._id,
                          })
                        }
                      }}>
                        <button

                        >
                          {t('common:SixPermonth')}
                        </button>
                      </div>
                      <div className="custom-dropdown-menu-item" onClick={() => {
                        if (selectedTeam) {
                          updateTeamProfile({
                            feedbackTarget: 8,
                            teamId: selectedTeam._id,
                          })
                        }
                      }}>
                        <button

                        >
                          {t('common:EightPermonth')}
                        </button>
                      </div>
                      <div className="custom-dropdown-menu-item" onClick={() => {
                        if (selectedTeam) {
                          updateTeamProfile({
                            feedbackTarget: 10,
                            teamId: selectedTeam._id,
                          })
                        }
                      }}>
                        <button
                        >
                          {t('common:TenPermonth')}
                        </button>
                      </div>
                      <div className="custom-dropdown-menu-item" onClick={() => {
                        if (selectedTeam) {
                          updateTeamProfile({
                            feedbackTarget: 40,
                            teamId: selectedTeam._id,
                          })
                        }
                      }}>
                        <button

                        >
                          {t('common:TwelwePermonth')}
                        </button>
                      </div>
                      <div className="custom-dropdown-menu-item" onClick={() => {
                        if (selectedTeam) {
                          updateTeamProfile({
                            feedbackTarget: 16,
                            teamId: selectedTeam._id,
                          })
                        }
                      }}>
                        <button

                        >
                          {t('common:SixteenPermonth')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="settings-table-row row-05">
                  <div className="settings-table-row-column-01">
                    <div className="settings-table-info">
                      <p className="settings-table-info-title">
                        {t("teamSettingsGeneralPage:Teamleaderfeedbackfrequency")}
                      </p>
                      <p className="settings-table-info-subtitle">
                      {t("teamSettingsGeneralPage:selectHowOftenLead")}
                      </p>
                    </div>
                  </div>
                  <div className="settings-table-row-column-03">
                    <div className="custom-dropdown">
                      <div
                        className="custom-dropdown-trigger"
                        tabIndex={-1}
                        onClick={(e) => e.currentTarget.focus()}
                      >
                        <p className="value">{tlFreq}</p>
                        <i className="icon-caret-down"></i>
                      </div>
                      <div className="custom-dropdown-menu">
                        <div className="custom-dropdown-menu-item">
                          <button onClick={() => {
                            if (selectedTeam) {
                              updateTeamProfile({
                                tlFrequency: 1,
                                teamId: selectedTeam._id
                              })
                            }
                          }  }>{t("common:Onceamonth")}</button>
                        </div>
                        <div className="custom-dropdown-menu-item">
                        <button onClick={() => {
                            if (selectedTeam) {
                              updateTeamProfile({
                                tlFrequency: 2,
                                teamId: selectedTeam._id
                              })
                            }
                          }  }>{t("common:Every2ndweek")}</button>
                        </div>
                        <div className="custom-dropdown-menu-item">
                        <button onClick={() => {
                            if (selectedTeam) {
                              updateTeamProfile({
                                tlFrequency: 3,
                                teamId: selectedTeam._id
                              })
                            }
                          }  }>{t("common:Every3rdweek")}</button>
                        </div>
                        <div className="custom-dropdown-menu-item">
                        <button onClick={() => {
                            if (selectedTeam) {
                              updateTeamProfile({
                                tlFrequency: 4,
                                teamId: selectedTeam._id
                              })
                            }
                          }  }>{t("common:Monthly")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

              <div className="settings-table-row row-06">
                <div className="settings-table-row-column-01">
                  <p className="settings-table-text">
                    {t('common:Linkteamtoacompany')}
                  </p>
                </div>
                {selectedTeam?.company && company && user?.company ? <div>
                  {company.name}
                </div>
                  : <div className="settings-table-row-column-02">
                    <p className="settings-table-text">
                      {t('common:Notlinkedyet')}
                    </p>
                  </div>
                }


                {!user?.company && <div className="settings-table-row-column-03" onClick={() => setShowLink(true)}>
                  <button className="settings-table-link">
                    {t('common:Link')}
                  </button>
                </div>}
              </div>

              <div className="settings-table-row row-08">
                <div className="settings-table-row-column-01">
                  <div className="settings-table-info">
                    <p className="settings-table-info-title">{t('teamSettingsGeneralPage:HowtoshowP2Pfeedbackstoreceiver')}</p>
                    <p className="settings-table-info-subtitle">
                      This is a line to give extra information about this element
                    </p>
                  </div>
                </div>

                <div className="settings-table-row-column-03">
                  <div className="radio-wrapper" onClick={() => {
                    if (selectedTeam) {
                      updateTeamProfile({
                        teamId: selectedTeam._id,
                        howToShow: 'text only'
                      })
                    }
                  }}>
                    <div className="pretty p-default p-round">
                      <input type="radio" name="radio1" checked={!selectedTeam?.howToShow || selectedTeam.howToShow === 'text only'} />
                      <div className="state p-primary-o">
                        <label>&nbsp;</label>
                      </div>
                    </div>
                    <p className="radio-wrapper-label">{t('teamSettingsGeneralPage:Showonlycommentswithsender')}<svg
                      xmlns="http://www.w3.org/2000/svg" width="12.5" height="12.5" viewBox="0 0 12.5 12.5">
                      <path
                        d="M8.039,5.639a.588.588,0,0,1,.586-.586h.391a.588.588,0,0,1,.586.586V6.03a.588.588,0,0,1-.586.586H8.625a.588.588,0,0,1-.586-.586ZM10.383,11.3H7.258v-.781h.781V8.178H7.258V7.4H9.6v3.125h.781ZM8.821,1.928a6.25,6.25,0,1,0,6.25,6.25,6.25,6.25,0,0,0-6.25-6.25Zm0,11.328A5.078,5.078,0,1,1,13.9,8.178,5.078,5.078,0,0,1,8.821,13.256Z"
                        transform="translate(-2.571 -1.928)" opacity="0.697" />
                    </svg></p>
                  </div>

                  <div className="radio-wrapper" onClick={() => {
                    if (selectedTeam) {
                      updateTeamProfile({
                        teamId: selectedTeam._id,
                        howToShow: 'valueAndText'
                      })
                    }
                  }}>
                    <div className="pretty p-default p-round">
                      <input type="radio" name="radio1" checked={selectedTeam?.howToShow === 'valueAndText'} />
                      <div className="state p-primary-o">
                        <label>&nbsp;</label>
                      </div>

                    </div>
                    <p className="radio-wrapper-label">{t('teamSettingsGeneralPage:Showalsogivenstarvalues')}</p>
                  </div>
                </div>
              </div>

              <div className="settings-table-row row-09">
                <div className="settings-table-row-column-01">
                  <div className="settings-table-info">
                    <p className="settings-table-info-title">{t('teamSettingsGeneralPage:Whatteamdatawillteammemberssee')}</p>
                    <p className="settings-table-info-subtitle">
                      This is a line to give extra information about this element
                    </p>
                  </div>
                </div>

                <div className="settings-table-row-column-03">
                  <div className="radio-wrapper" onClick={() => {
                    if (selectedTeam) {
                      if (selectedTeam.memberAccess) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          memberAccess: Object.assign(selectedTeam.memberAccess, {
                            momentCharts: !selectedTeam.memberAccess.momentCharts
                          })
                        })
                      } else {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          memberAccess: {
                            memberTable: false,
                            developmentCharts: false,
                            momentCharts: true
                          }

                        })
                      }
                    }
                  }} >
                    <div className="pretty p-svg p-curve">
                      <input type="checkbox" checked={selectedTeam?.memberAccess?.momentCharts} />
                      <div className="state p-primary">

                        <svg className="svg svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                            style={{ stroke: 'white', fill: 'white' }}></path>
                        </svg>
                        <label>&nbsp;</label>
                      </div>
                    </div>
                    <p className="radio-wrapper-label">{t('common:Momentcharts')}{' '}<svg xmlns="http://www.w3.org/2000/svg" width="12.5"
                      height="12.5" viewBox="0 0 12.5 12.5">
                      <path
                        d="M8.039,5.639a.588.588,0,0,1,.586-.586h.391a.588.588,0,0,1,.586.586V6.03a.588.588,0,0,1-.586.586H8.625a.588.588,0,0,1-.586-.586ZM10.383,11.3H7.258v-.781h.781V8.178H7.258V7.4H9.6v3.125h.781ZM8.821,1.928a6.25,6.25,0,1,0,6.25,6.25,6.25,6.25,0,0,0-6.25-6.25Zm0,11.328A5.078,5.078,0,1,1,13.9,8.178,5.078,5.078,0,0,1,8.821,13.256Z"
                        transform="translate(-2.571 -1.928)" opacity="0.697" />
                    </svg></p>
                  </div>

                  <div className="radio-wrapper" onClick={() => {
                    if (selectedTeam) {
                      if (selectedTeam.memberAccess) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          memberAccess: Object.assign(selectedTeam.memberAccess, {
                            developmentCharts: !selectedTeam.memberAccess.developmentCharts
                          })
                        })
                      } else {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          memberAccess: {
                            memberTable: false,
                            developmentCharts: true,
                            momentCharts: false
                          }

                        })
                      }
                    }
                  }} >
                    <div className="pretty p-svg p-curve">
                      <input type="checkbox" checked={selectedTeam?.memberAccess?.developmentCharts} />
                      <div className="state p-primary">

                        <svg className="svg svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                            style={{ stroke: 'white', fill: 'white' }}></path>
                        </svg>
                        <label>&nbsp;</label>
                      </div>
                    </div>
                    <p className="radio-wrapper-label">{t('common:Developmentcharts')}{' '}<svg xmlns="http://www.w3.org/2000/svg"
                      width="12.5" height="12.5" viewBox="0 0 12.5 12.5">
                      <path
                        d="M8.039,5.639a.588.588,0,0,1,.586-.586h.391a.588.588,0,0,1,.586.586V6.03a.588.588,0,0,1-.586.586H8.625a.588.588,0,0,1-.586-.586ZM10.383,11.3H7.258v-.781h.781V8.178H7.258V7.4H9.6v3.125h.781ZM8.821,1.928a6.25,6.25,0,1,0,6.25,6.25,6.25,6.25,0,0,0-6.25-6.25Zm0,11.328A5.078,5.078,0,1,1,13.9,8.178,5.078,5.078,0,0,1,8.821,13.256Z"
                        transform="translate(-2.571 -1.928)" opacity="0.697" />
                    </svg></p>
                  </div>

                  <div className="radio-wrapper" onClick={() => {
                    if (selectedTeam) {
                      if (selectedTeam.memberAccess) {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          memberAccess: Object.assign(selectedTeam.memberAccess, {
                            memberTable: !selectedTeam.memberAccess.memberTable
                          })
                        })
                      } else {
                        updateTeamProfile({
                          teamId: selectedTeam._id,
                          memberAccess: {
                            memberTable: true,
                            developmentCharts: false,
                            momentCharts: false
                          }

                        })
                      }
                    }
                  }}  >
                    <div className="pretty p-svg p-curve">
                      <input type="checkbox" checked={selectedTeam?.memberAccess?.memberTable} />
                      <div className="state p-primary">

                        <svg className="svg svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                            style={{ stroke: 'white', fill: 'white' }}></path>
                        </svg>
                        <label>&nbsp;</label>
                      </div>
                    </div>
                    <p className="radio-wrapper-label">{t('common:Membertable')}{' '}<svg xmlns="http://www.w3.org/2000/svg" width="12.5"
                      height="12.5" viewBox="0 0 12.5 12.5">
                      <path
                        d="M8.039,5.639a.588.588,0,0,1,.586-.586h.391a.588.588,0,0,1,.586.586V6.03a.588.588,0,0,1-.586.586H8.625a.588.588,0,0,1-.586-.586ZM10.383,11.3H7.258v-.781h.781V8.178H7.258V7.4H9.6v3.125h.781ZM8.821,1.928a6.25,6.25,0,1,0,6.25,6.25,6.25,6.25,0,0,0-6.25-6.25Zm0,11.328A5.078,5.078,0,1,1,13.9,8.178,5.078,5.078,0,0,1,8.821,13.256Z"
                        transform="translate(-2.571 -1.928)" opacity="0.697" />
                    </svg></p>
                  </div>

                  {/* <div className="radio-wrapper">
                    <div className="pretty p-svg p-curve">
                      <input type="checkbox" />
                      <div className="state p-primary">

                        <svg className="svg svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                            style={{ stroke: 'white', fill: 'white' }}></path>
                        </svg>
                        <label>&nbsp;</label>
                      </div>
                    </div>
                    <p className="radio-wrapper-label">Amount of given feedback <svg xmlns="http://www.w3.org/2000/svg"
                      width="12.5" height="12.5" viewBox="0 0 12.5 12.5">
                      <path
                        d="M8.039,5.639a.588.588,0,0,1,.586-.586h.391a.588.588,0,0,1,.586.586V6.03a.588.588,0,0,1-.586.586H8.625a.588.588,0,0,1-.586-.586ZM10.383,11.3H7.258v-.781h.781V8.178H7.258V7.4H9.6v3.125h.781ZM8.821,1.928a6.25,6.25,0,1,0,6.25,6.25,6.25,6.25,0,0,0-6.25-6.25Zm0,11.328A5.078,5.078,0,1,1,13.9,8.178,5.078,5.078,0,0,1,8.821,13.256Z"
                        transform="translate(-2.571 -1.928)" opacity="0.697" />
                    </svg></p>
                  </div> */}

                </div>
              </div>

              {/* <div className="settings-table-row row-07">
                  <div className="settings-table-row-column-01">
                    <div className="settings-table-info">
                      <p className="settings-table-info-title">{t("common:teamOwner")}</p>
                      <p className="settings-table-info-subtitle">
                        {t("teamSettingsGeneralPage:Thisteamisownedby")} Nikhil Bhatia
                      </p>
                    </div>
                  </div>
                  <div className="settings-table-row-column-03">
                    <button
                      className="button button-white modal-trigger"
                      target-modal="modal-01"
                    >
                      {t("teamSettingsGeneralPage:Transferownership")}
                    </button>
                  </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      <ImageUpload image={selectedImage} />
      {!!user && <ComfirmationModal show={showLink} onClose={() => setShowLink(false)} onAccept={() => {
        if (company && selectedTeam) {
          linkTeam(selectedTeam._id)
        }
      }} caption={company ? 'Link this team to ' + company.name : 'You need to be in company to link this team.'} />}

    </div>
  )
}

export default connector(GeneralSettings)