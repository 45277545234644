
const fi = {
  giveFeedbackPage: {
    giveFeedback: "TEST",
    gridView: "TEST",
    todoList: "TEST",
    Noteammembersyet: "TEST",
    Thisteamdoesnthavemember: "TEST",
    toinviteyourfirstteammembers: "TEST",
  },

  teamMembersPage: {
    teamMembers: "TEST",
    tableView: "TEST",
    gridView: "TEST",
    averageFeedback: "TEST",
    developmentTrend: "TEST",
    Accounttype: "TEST",
    Userswithviewingrightshaveaccess: "TEST",

    placeholder: {
      title: "TEST",
      text: "TEST",
      text02: "TEST",
    }
  },

  feedbackChartsPage: {
    navigation: {
      teamFeedback: "TEST",
      p2pFeedback: "TEST",
      selfEvaluation: "TEST",
      supervisoryFeedback: "TEST",
      allWidgets: "TEST",
    }
  },

  teamFeedbackPage: {
    placeholder: {
      title: "TEST",
      text: "TEST",
    }
  },

  appUsage: {
    Appusage: "TEST",
    Amountofgivenfeedbacklast30days: "TEST",
    Amountoffeedbackrequests: "TEST",
    Amountofteammembers: "TEST",
  },

  teamSettingsGeneralPage: {
    Generalsettings: "TEST",
    pageHeaderSubtitle: "TEST",
    Teamname: "TEST",
    Teamdescription: "TEST",
    Teamimage: "TEST",
    Teamfeedbackfrequency: "TEST",
    selectHowOften: "TEST",
    Notlinkedyet: "TEST",
    Linkteamtoacompany: "TEST",
    Teamdescriptionmissing: "TEST",
    HowtoshowP2Pfeedbackstoreceiver: "TEST",
    Showonlycommentswithsender: "TEST",
    Showalsogivenstarvalues: "TEST",
    Whatteamdatawillteammemberssee: "TEST",
  },

  teamSettingsMembersPage: {
    pageHeaderSubtitle: "TEST",
    placeholderTitle: "TEST",
  },

  teamSettingsLeadPage: {
    pageHeaderSubtitle: "TEST",
    placeholderTitle: "TEST",
  },

  teamSettingsViewPage: {
    pageHeaderSubtitle: "TEST",
    placeholderTitle: "TEST",
    toaddviewingrights: "TEST",
  },

  teamSettingsAdminPage: {
    pageHeaderSubtitle: "TEST",
    placeholderTitle: "TEST",
  },

  feedbackInputPage: {
    Addanewquestion: "TEST",
    Hereyoucanadd: "TEST",
    Questions: "TEST",
    ADDNEWQUESTION: "TEST",
    Frequency: "TEST",
    Selecthowoftentheteamfeedback: "TEST",
    Thistheplacetocreate: "TEST",
    Feedbackcategory: "TEST",
    Starquestions: "TEST",
    Textquestions: "TEST",
  },

  feedbackRequestPage: {
    pageHeaderSubtitle: "TEST",
    Noteammembersselected: "TEST",
    pleaseSelect: "TEST",
  },

  userSettingsGeneralPage: {
    pageHeaderSubtitle: "TEST",
    Accountlinktocompany: "TEST",
    Dashboardlanguage: "TEST",
  },

  userSettingsTeamsPage: {
    pageHeaderSubtitle: "TEST",
  },

  companyOverviewPage: {
    Amountoffeedback: "TEST",
    Last30days: "TEST",
    Last3months: "TEST",
    Last12months: "TEST",
    totalGivenFeedback: "TEST",
    teamFeedbacks: "TEST",
    supervisorFeedbacks: "TEST",
    selfEvaluations: "TEST",
    subordinateFeedbacks: "TEST",
    Youraccess: "TEST",
    completeCompanyAdminAccess: "TEST",
    Pendinginvitation: "TEST",
    Mobileappsusers: "TEST",
    Dashboardusers: "TEST",
    Signedinmobileapp: "TEST",
    Signedindashboard: "TEST",
    Totalmonthlyfeedbackamount: 'TEST',
    Subordinatemonthlyfeedback: "TEST",
    Supervisormonthlyfeedback: "TEST",
    P2pmonthlyfeedback: "TEST",
    Selfevaluationmonthlyfeedback: "TEST",
    Teammonthlyfeedback: "TEST",
    placeholderTitle: "TEST",
    placeholderText: "TEST",
    Noemployeesarchivedyet: "TEST",
  },

  companyAdminPage: {
    pageHeaderSubtitle: "TEST",
  },

  companyEmployeesPage: {
    Activeemployees: "TEST",
    Archivedemployees: "TEST",
  },

  companyTeamsPage: {
    Activeteams: "TEST",
    Archivedteams: "TEST",
    placeholderTitle: "TEST",
    placeholderText: "TEST",
  },

  feedbackSummaryBox: {

  },

  navigation: {
    wantstoinveteyouas: "TEST",
    Billing: "TEST",
    Support: "TEST",
    Documentation: "TEST",
    Overallcharts: "TEST",
    Teammemberchart: "TEST",
    Teamleadcharts: "TEST",
    Addnewwidgets: "TEST",
    Themecolor: "TEST",
    notificationPlaceholderText: "TEST",
    Openusersettings: "TEST",
    Widgettest: "TEST",
    Cleardatabase: "TEST",
  },

  loader: {
    placeholderTitle: "TEST",
    placeholderText: "TEST",
  },

  addNewQuestionModal: {
    Addnewquestion: "TEST",
    Selecttype: "TEST",
    Starrating: "TEST",
    Textentry: "TEST",
    Enterquestion: "TEST",
    Addquestion: "TEST",
  },

  addWidgetModal: {

  },

  changePasswordModal: {
    Changepasswword: "TEST",
    Currentpasswword: "TEST",
    Chooseanewpasswword: "TEST",
    Confirmnewpasswword: "TEST",
    Enterpassword: "TEST",
    Enternewpassword: "TEST",
    Confirmnewpassword: "TEST",
  },

  customerSupportModal: {
    Contactcustomerservice: "TEST",
    Selecttopic: "TEST",
    Generalsupport: "TEST",
    Featurerequest: "TEST",
    Bugreport: "TEST",
  },

  createCompanyModal: {
    Registeranewcompany: "TEST",
    modalSubtitle: "TEST",
    Wanttojoinregisteredcompany: "TEST",
  },

  createTeamModal: {
    Addteamname: "TEST",
    Addteamdescription: "TEST",
    Theteamnameshouldbecompact: "TEST",
    Youcanaddpeople: "TEST",
  },

  giveFeedbackModal: {
    Windowcolosesautomaticly: "TEST",
  },

  firstTeamModal: {
    Createyoutfirstteam: "TEST",
    Basicfeaturesfreeofcharge: "TEST",
    Nameoftheteam: "TEST",
    Inviteteammembers: "TEST",
    Inviteyourpeopleandstart: "TEST",
    evenremotely: "TEST",

  },

  imageModal: {
    Looksgood: "TEST",
    Uploadnew: "TEST",
  },

  iviteEmployeesModals: {
    Enterheretheemailsofpeople: "TEST",
  },

  inviteMembersModal: {
    SelectedPeople: "TEST",
  },

  userFeedbackModal: {
    Nofeedbackdata: "TEST",
    Soonyouwillfind: "TEST",
    Summaryboxeslastmonths: "TEST",
    Feedbackfromcolleagues: "TEST",
    Feedbackfromsupervisors: "TEST",
  },

  addRightsModal: {
    Selecttypeofrights: "TEST",
    Userswithviewingrights: "TEST",
  },

  createJoinCompany: {
    WelcometoTeamFeedback: "TEST",
    Averypowerfultool: "TEST",
    Howwouldyouliketogetstarted: "TEST",
    Getstartedwiththreesimplesteps: "TEST",
    Joinaregisteredcompany: "TEST",
    Joinaregisteredteam: "TEST",
    Invitationrequired: "TEST",
    Registeranewteam: "TEST",
    Basicfeaturesfreeofcharge: "TEST",
    Tojoinaregisteredcompany: "TEST",
    Recheckinvitationstatus: "TEST",
    Joinaregisteredteam: "TEST",
    Tojoinaregisteredteam: "TEST",
    Youhavebeeninvitedtocompany: "TEST",
    Inviter: "TEST",
    Joincompany: "TEST",
    Jointeam: "TEST",
    Youhavebeeninvitedtoteam: "TEST",
    Denyinvitation: "TEST",
    Makemeteamleaderofthisteam: "TEST",
    Everyteamneedsatleastoneteamleader: 'TEST',
    TeamFeedbackisnothingwithoutateam: "TEST",
    Congratulationsyourfirstteam: "TEST",
    Wouldyoulikeustocreate: "TEST",
    Yescrateademoteamforme: "TEST",
    Idontneedademoteam: "TEST",
  },

  authPages: {
    Createyouraccount: "TEST",
    Chooseapassowrd: "TEST",
    Choosepassowrd: "TEST",
    disclaimer: "TEST",
    andAcknowledge: "TEST",
    Alreadyhaveanaccount: "TEST",
    Signinto: "TEST",
    Forgottenpassword: "TEST",
    Donthaveaccount: "TEST",
    Createyoursnow: "TEST",
    Createyouraccuntwiththree: "TEST",
    Startbyenteringyouremailinthefieldbelow: "TEST",
    Wehavesentyouverificationcode: "TEST",
    Setupnewpassword: "TEST",
    Newpasswordmustcontain: "TEST",
    Choosenewpassword: "TEST",
    Confirmnewpassword: "TEST",
    Updatepassword: "TEST",
  },

  textFeedbackPages: {
    Textfeedbackquestions: "TEST",
    Addnewquestion: "TEST",
    hasnotyetbeenansweredbyanyteammember: "TEST",
    Pleaseselectafeedback: "TEST",
    Fromteamleaders: "TEST",
    Therehasnotyetbeengiventeamleader: "TEST",
    Allreviewsgivenanonymously: "TEST",
    Feedbackaboutteamleader: "TEST",
    Therehasnotyetbeengivenaboutteamleader: "TEST",
    Noselfevaluationyet: "TEST",
    Addnewteamleaders: "TEST",
    Feedbackgivento: "TEST",
  },

  common: {
    English: "TEST",
    Finnish: "TEST",
    German: "TEST",
    Generalsettings: "TEST",
    Feedbacksettings: "TEST",
    search: "TEST",
    inviteNewMembers: "TEST",
    teamFeedback: "TEST",
    p2pFeedback: "TEST",
    p2pfeedback: "TEST",
    p2pfeedbackthat: "TEST",
    hasnotreceivedanyp2pfeedbackyet: "TEST",
    selfEvaluation: "TEST",
    supervisoryFeedback: "TEST",
    subordinateFeedback: "TEST",
    click: "TEST",
    Click: "TEST",
    here: "TEST",
    noSearchResults: "TEST",
    toInviteNewTeamMembers: "TEST",
    Name: "TEST",
    Jobtitle: "TEST",
    Jobtitlemissing: "TEST",
    Average: "TEST",
    feedback: "TEST",
    Openteam: "TEST",
    Openprofile: "TEST",
    Givefeedback: "TEST",
    Deletefromteam: "TEST",
    Deletemember: "TEST",
    Addjobtitle: "TEST",
    Nodata: "TEST",
    bad: "TEST",
    poor: "TEST",
    weak: "TEST",
    okMinus: "TEST",
    ok: "TEST",
    okPlus: "TEST",
    goodMinus: "TEST",
    good: "TEST",
    goodPlus: "TEST",
    great: "TEST",
    excellent: "TEST",
    amazing: "TEST",
    None: "TEST",
    Onceamonth: "TEST",
    Everyweek: "TEST",
    Every2ndweek: "TEST",
    Every3rdweek: "TEST",
    Monthly: "TEST",
    Teamname: "TEST",
    Teamdescription: "TEST",
    Teamimage: "TEST",
    Confirm: "TEST",
    Edit: "TEST",
    Upload: "TEST",
    Link: "TEST",
    Notlinkedyet: "TEST",
    Linkteamtoacompany: "TEST",
    teamOwner: "TEST",
    Teammembers: "TEST",
    Addnew: "TEST",
    Email: "TEST",
    Status: "TEST",
    Enternameoremail: "TEST",
    Usernotfound: "TEST",
    Pleaseentertheemailaddress: "TEST",
    Add: "TEST",
    Cancel: "TEST",
    Pending: "TEST",
    Delete: "TEST",
    Teamlead: "TEST",
    Select: "TEST",
    Viewingrights: "TEST",
    Adminrights: "TEST",
    Questions: "TEST",
    Feedbackrequests: "TEST",
    FeedbackRequests: "TEST",
    Feedbackinput: "TEST",
    Selecttimeperiod: "TEST",
    Weekly: "TEST",
    Everysecondweek: "TEST",
    Everysecondmonth: "TEST",
    Requests: "TEST",
    Selectateammember: "TEST",
    Firstname: "TEST",
    Lastname: "TEST",
    Enterjobtitle: "TEST",
    Password: "TEST",
    Profilepicture: "TEST",
    Myteams: "TEST",
    Yourstatusintheteam: "TEST",
    Deleteteam: "TEST",
    Quitteam: "TEST",
    Member: "TEST",
    Admin: "TEST",
    Leader: "TEST",
    View: "TEST",
    Members: "TEST",
    Clickhere: "TEST",
    CreateTeam: "TEST",
    Createanewteam: "TEST",
    Companyadminarea: "TEST",
    Createnew: "TEST",
    Feedbackcharts: "TEST",
    Teamsettings: "TEST",
    SHORTCUTS: "TEST",
    employee: "TEST",
    employees: "TEST",
    Employees: "TEST",
    oneDayago: "TEST",
    Deny: "TEST",
    CompanyOverview: "TEST",
    Teams: "TEST",
    Adminrights: "TEST",
    Billing: "TEST",
    Support: "TEST",
    Documentation: "TEST",
    Settings: "TEST",
    Light: "TEST",
    Default: "TEST",
    Invitations: "TEST",
    Rights: "TEST",
    Opencompany: "TEST",
    Createcompany: "TEST",
    Signout: "TEST",
    Signin: "TEST",
    Textfeedback: "TEST",
    Updated: "TEST",
    Peertopeer: "TEST",
    Active: "TEST",
    Viewprofile: "TEST",
    Restoreemployee: "TEST",
    Archiveemployee: "TEST",
    Deleteemployee: "TEST",
    Manage: "TEST",
    Invitenewemployees: "TEST",
    Registeredemployees: "TEST",
    Last: "TEST",
    Viewteam: "TEST",
    Companyname: "TEST",
    Continue: "TEST",
    Enterhere: "TEST",
    Addteammember: "TEST",
    Readmore: "TEST",
    Givefeedbackto: "TEST",
    Feedbacksent: "TEST",
    Sendfeedback: "TEST",
    Sent: "TEST",
    Done: "TEST",
    added: "TEST",
    Sendinvites: "TEST",
    Addnewteammembers: "TEST",
    Searchbynameoremail: "TEST",
    Selectfeedbacktype: "TEST",
    Developmentcharts: "TEST",
    Receivedfeedback: "TEST",
    Givenfeedback: "TEST",
    Selfevaluation: "TEST",
    Closesection: "TEST",
    Opensection: "TEST",
    Lastmonth: "TEST",
    Last3month: "TEST",
    Last6month: "TEST",
    Closesection: "TEST",
    TermsofService: "TEST",
    PrivacyPolicy: "TEST",
    Createaccount: "TEST",
    Enteremailandpassword: "TEST",
    Toyourcolleagues: "TEST",
    Toyoursubordinates: "TEST",
    Toyoursupervisor: "TEST",
    Toyourself: "TEST",
    feedback: "TEST",
    Notifications: "TEST",
    Clickheretoaddjobtitle: "TEST",
    Feedbackaboutteamlead: "TEST",
    selected: "TEST",
    Thisquestionhasnodatayet: "TEST",
    Checkagainsoon: "TEST",
    Nodatayet: "TEST",
    Backtofeedbackdata: "TEST",
    selfEvaluations: "TEST",
    Allteammembers: "TEST",
    Feedbacktarget: "TEST",
    SixPermonth: "TEST",
    EightPermonth: "TEST",
    TenPermonth: "TEST",
    TwelwePermonth: "TEST",
    SixteenPermonth: "TEST",
    Mytodolist: "TEST",
    Charts: "TEST",
    Thequestion: "TEST",
    Nofeedbackselected: "TEST",
    Feedbackgiven: "TEST",
    Text: "TEST",
    Selectteammembers: "TEST",
    Selectateammember: "TEST",
    Selectaquestion: "TEST",
    Selectteamleader: "TEST",
    like: "TEST",
    Commented: "TEST",
    Liked: "TEST",
    Likethisfeedback: "TEST",
    Teamleader: "TEST",
    Givenby: "TEST",
    Teammember: "TEST",
    Nojobtitle: "TEST",
    hasreceived: "TEST",
    Sent: "TEST",
    newfeedback: "TEST",
    Nonewfeedback: "TEST",
    Theteammember: "TEST",
    Selfevaluations: "TEST",
    Commentthisfeedback: "TEST",
    Help: "TEST",
    Resetlayout: "TEST",
    Fullscreenmode: "TEST",
    Doubleclickheader: "TEST",
    Togoandexitfullscreen: "TEST",
    Contentsize: "TEST",
    Noadjustment: "TEST",
    Appearance: "TEST",
    Automatic: "TEST",
    Recomended: "TEST",
    Othersettings: "TEST",
    Keepmesignedin: "TEST",
    Browsernotifications: "TEST",
    Contactsupport: "TEST",
    Searchforateam: "TEST",
    teammember: "TEST",
    teamlead: "TEST",
    Enteremails: "TEST",
    Enteremail: "TEST",
    Added: "TEST",
    Nofeedbackgiveninthiscategoryyet: "TEST",
    Checkagainsoon: "TEST",
    SelfEvaluations: "TEST",
    Letsstart: "TEST",
    Lastcheck: "TEST",
    Sending: "TEST",
    Yourmessage: "TEST",
    permonth: "TEST",
    NameYourTeam: "TEST",
    AddMembers: "TEST",
    Next: "TEST",
    GoBack: "TEST",
    Createteam: "TEST",
    Teamnameandshortdescription: "TEST",
    Shortdescription: "TEST",
    Comment: "TEST",
    Twiceamonth: "TEST",
    Everythirdweek: "TEST",
    Onemin: "TEST",
    Hereyoucanactivateteamfeedback: "TEST",
    Disable: "TEST",
    Question: "TEST",
    Shortform: "TEST",
    Enterquestion: "TEST",
    Deletequestion: "TEST",
    Savechanges: "TEST",
    Subordinatefeedback: "TEST",
    CommentedBy: "TEST",
    Zoomlevel: "TEST",
    CreateJoincompany: "TEST",
    Forgotpassword: "TEST",
    Wesentyouconfirmation: "TEST",
    Backtosignin: "TEST",
    Donthaveanaccountyet: "TEST",
    Pleaseenteryouremailaddress: "TEST",
    Pleaseenteryouremail: "TEST",
    Pleaseselectanewpassword: "TEST",
    Newpassword: "TEST",
    Entercompanyname: "TEST",
    Appusage: "TEST",
    Sentteamfeedback: "TEST",
    Senttocolleagues: "TEST",
    Senttoteamleader: "TEST",
    Sentselfevaluation: "TEST",
    Whatwecandobetterinthisteam: "TEST",
    Receivedfromteammembers: "TEST",
    Receivedfromteamleader: "TEST",
    Nofeedback: "TEST",
    Youhaventreceivefeedbackyet: "TEST",
    Feedbackgivenfromteammembertomember: "TEST",
    Feedbackgivenfromteammembertohimself: "TEST",
    Feedbackabouttheteamleader: "TEST",
    Feedbackfromtheteammemberstotheteamleader: "TEST",
    Supervisorfeedback: "TEST",
    Feedbackfromtheteamleadertotheteammembers: "TEST",
    Feedbackcollectedfromallteammembers: "TEST",
    teammembers: "TEST",
    Letsstart: "TEST",
    Hereyoucanaddeditanddelete: "TEST",
    Removeteamleader: "TEST",
    Maketeamleader: "TEST",
    Giverights: "TEST",
    Editjobtitle: "TEST",
    Deleteteammember: "TEST",
    Changetoadminrights: "TEST",
    Changetoviewingrights: "TEST",
    Withdrawrights: "TEST",
    Donthaveanaccountyet: "TEST",
    Signupforfree: "TEST",
    Sentteamfeedback: "TEST",
    Senttomyteamleader: "TEST",
    Getstarted: "TEST",
    Enterfirstname: "TEST",
    Enterlastname: "TEST",
    Enterpassword: "TEST",
    Getstartedforfree: "TEST",
    Pleaseverifyyouremail: "TEST",
    Wevesentyouaverification: "TEST",
    Confirmationcode: "TEST",
    Registernewteam: "TEST",
    Resendcode: "TEST",
    Didntreceivecode: "TEST",
    Teamjoinedsuccesfully: "TEST",
    Yourteamsasksyoutoadd: "TEST",
    Uploadimage: "TEST",
    Yourjobtitle: "TEST",
    Weeseeyouhaveaninvitation: "TEST",
    Createyourfirstteam: "TEST",
    Pleasegiveyourteamanameandandadescription: "TEST",
    Enterteamname: "TEST",
    Enterteamdescription: "TEST",
    Quitteamcreation: "TEST",
    Addteammembers: "TEST",
    Tothedashboard: "TEST",
    Onemorething: "TEST",
    Addnewrights: "TEST",
    Skipthisstepandinvitelater: "TEST",
    Addteamleaders: "TEST",
    Havingaquestionaboutit: "TEST",
    Free: "TEST",
    Seeotherplans: "TEST",
    Upgrade: "TEST",
    Given: "TEST",
    of: "TEST",
    Otherfeedback: "TEST",
    Used: "TEST",
    Teammembers: "TEST",
    Momentcharts: "TEST",
    Membertable: "TEST",
    Searchorenteremail: "TEST",
    Confirmation: "TEST",
    Areyousuretoremovethisteam: "TEST",
    Didntreceivethecode: "TEST",
    Firstpleasecheckyourspamfolder: "TEST",
    optional: "TEST",
    Myfeedback: "TEST",
    Markallread: "TEST",
  }
}
export default fi
