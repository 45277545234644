import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppDispatch, RootState } from 'src/configureStore'
import { clearSingnUp, signIn } from 'src/redux/auth/actions'
import { connect, ConnectedProps } from 'react-redux'
import { SignInVariables } from 'src/apiTypes'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { validateEmail } from 'src/utitlity'
import Toaster from 'src/components/common/toaster'
import { useTranslation } from 'react-i18next'
import { getMyTeams, getTeamById } from 'src/redux/teams/actions'
import Loader from 'src/components/loaders/signinLoader'
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signin: (data: SignInVariables) => dispatch(signIn(data)),
  getMyTeams: () => dispatch(getMyTeams()),
  getTeamById: (teamId: string) => dispatch(getTeamById(teamId)),
  clearSignup: () => dispatch(clearSingnUp()),
})

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  sessionRestored: state.authReducer.sessionRestored,
  error: state.authReducer.signinError,
  myTeams: state.teamReducer.myTeams,
  sigininin: state.authReducer.sigininin,
  passwordReseted: state.authReducer.passwordReseted
})
const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {}

const Signin = (props: Props) => {
  const { t } = useTranslation(['authPages', 'common'])
  const {
    signin,
    user,
    sessionRestored,
    error,
    getMyTeams,
    myTeams,
    getTeamById,
    sigininin,
    passwordReseted,
    clearSignup
  } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { push } = useHistory()


  useEffect(() => {
    if (sessionRestored) {
      if (user && user._id) {
        getMyTeams()
      } else {
        //push('/')
      }
    }
  }, [user, sessionRestored])

  useEffect(() => {
    if (myTeams && user) {
      if (myTeams[0]) {
        push(`/team/${myTeams[0]._id}/data/teamFeedback`)
        getTeamById(myTeams[0]._id)
      } else {
        push('/teamfeedback/myFeedback/feedbackArchive')
      }
    }
  }, [myTeams])
  const inputRef = useRef(null)
  return (
    <>
      <img
        src="img/logo-only.png"
        alt="Team Feedback logo"
        className="auth-logo"
      />
      <div className="content-wrapper" style={{ maxHeight: "100%", height: "100vh" }}>
        <div className="content">
          <div className="content-card card-00">
            <div className="content-card-header">
              <p className="content-card-header-title">{t('common:Signin')}</p>
              <p className="content-card-header-subtitle" onClick={() => push('/createAccount/step-01')} >{t('common:Donthaveanaccountyet')}{' '}<button>{t('common:Signupforfree')}</button></p>
            </div>

            <div className="content-card-main">
              <p className="content-card-main-label">{t('common:Email')}</p>
              <div className={`input-container`}>
                <input
                  type="email"
                  placeholder={`${t('common:Email')}`}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      //@ts-ignore
                      inputRef?.current?.focus()
                    }
                  }}

                />
                <span>
                  <i className="icon-feather-mail"></i>
                </span>
              </div>

              <p className="content-card-main-label">{t('common:Password')}</p>
              <div className={`input-container`}>
                <input
                  type="password"
                  ref={inputRef}
                  placeholder={`${t('common:Password')}`}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      if (email && password) {
                        signin({
                          email,
                          password,
                        })
                      } else {
                        window.alert(t('common:Enteremailandpassword'))
                      }
                    }
                  }}
                />
                <span>
                  <i className="icon-material-lock"></i>
                </span>
              </div>

              <button
                className={`button button-green-primary ${sigininin && 'active-effect'}`}

                onClick={() => {
                  if (email && password) {
                    signin({
                      email,
                      password,
                    })
                  } else {
                    window.alert(t('common:Enteremailandpassword'))
                  }
                }}
              >
                {t('common:Signin')}{' '}{sigininin && (<span style={{ marginLeft: '9px' }}><Loader /></span>)}
              </button>

              <p className="content-card-main-text-small">
                <button className="auth-card-link" onClick={() => push('/resetPassword')}>
                  {t('authPages:Forgottenpassword')}
                </button>
              </p>
            </div>

          </div>

        </div>
      </div>
      <Toaster
        type="error"
        instance={!!error}
        clear={() => 5000}
        text={error || ''}
      />
      <Toaster
        type="success"
        instance={!!passwordReseted}
        clear={() => clearSignup()}
        text={'Password reset successfull. Login with your new password'}
      />
    </>
  )
}

export default connector(Signin)
