import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  send(message: string, subject: string): void
}

const ContactSupport = (props: Props) => {
  const { t } = useTranslation(['customerSupportModal', 'common'])
  const [topic, setTopic] = useState(t('customerSupportModal:Generalsupport'))
  const [message, setMessage] = useState('')
  const { send } = props

  useEffect(() => {
    let modalCloseBtns = document.querySelectorAll("[close-modal]");

    modalCloseBtns.forEach((e) => {
      e.addEventListener("click", function () {
        let modal = document.getElementById(`${e.getAttribute("close-modal")}`);
        modal?.classList.remove("open");
      });
    });
  }, [])

  return (
    <div className="custom-modal-wrapper" id="contactSupportModal">
      <div className="custom-modal">
        <div className="custom-modal-body">
          <div className="custom-modal-close" close-modal="contactSupportModal">
            <i className="icon-close"></i>
          </div>
          <p className="custom-modal-body-title">{t('customerSupportModal:Contactcustomerservice')}</p>

          <p className="custom-modal-body-label">{t('customerSupportModal:Selecttopic')}</p>
          <div className="custom-dropdown">
            <button className="custom-dropdown-trigger" tabIndex={-1} onClick={(e) => e.currentTarget.focus()} >
              <p className="custom-dropdown-trigger-text">{topic}</p>
              <div className="custom-dropdown-trigger-icon"><i className="icon-chevron-down"></i></div>
            </button>
            <ul className="custom-dropdown-menu">
              <li className="custom-dropdown-menu-item" onClick={() => setTopic(t('customerSupportModal:Generalsupport'))}>
                <p className="custom-dropdown-menu-item-text">{t('customerSupportModal:Generalsupport')}</p>
              </li>
              <li className="custom-dropdown-menu-item" onClick={() => setTopic(t('customerSupportModal:Featurerequest'))}>
                <p className="custom-dropdown-menu-item-text">{t('customerSupportModal:Featurerequest')}</p>
              </li>
              <li className="custom-dropdown-menu-item" onClick={() => setTopic(t('customerSupportModal:Bugreport'))}>
                <p className="custom-dropdown-menu-item-text">{t('customerSupportModal:Bugreport')}</p>
              </li>
            </ul>
          </div>

          <p className="custom-modal-body-label">{t('common:Yourmessage')}</p>
          <textarea className="custom-modal-body-textarea" placeholder={`${t('common:Enterhere')}...`} onChange={(e) => setMessage(e.target.value)} ></textarea>

        </div>

        <div className="custom-modal-footer">
          <button className="button button-green-primary" onClick={() => {
            if (message) {
              // @ts-ignore
              send(message, topic)
              document.getElementById('contactSupportModal')?.classList.remove('open')
            } else {
              window.alert('no message')
            }
          }} >{t('common:Send')}</button>
          <button className="button button-light-gradient" close-modal="contactSupportModal">{t('common:Cancel')}</button>

        </div>
      </div>
      <div className="custom-modal-backdrop"></div>
    </div>
  )
}

export default ContactSupport