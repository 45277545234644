import React, { useState } from 'react'
import { AppDispatch, RootState } from 'src/configureStore'
import { connect, ConnectedProps } from 'react-redux'
import { useEffect } from 'react'
import {
  getActiveTeamUsers,
  getPendingInvites,
  removeMember,
  updateTeamProfile,
  getUserSuggestion,
  addToTeam,
  updateJobTitle,
} from 'src/redux/teams/actions'
import { deleteInvite } from 'src/redux/invites/actions'
import { TeamUserRank, User, UserRights } from 'src/apiTypes'
import InviteMembers from 'src/components/modals/inviteMember'
import AddNewRight from 'src/components/modals/addNewRight'
import TeamTable from './viewingTable'
import { getTeamUserRanks } from 'src/redux/feedback/actions'
import { useHistory } from 'react-router-dom'
import GiveFeedbackmodal from 'src/components/modals/givefeedback'
import { selectUser } from 'src/redux/auth/actions'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const mapStateToProps = (state: RootState) => ({
  user: state.authReducer.user,
  selectedTeam: state.teamReducer.selectedTeam,
  selectedTeamData: state.teamReducer.selectedTeamData,
  userSuggestions: state.teamReducer.userSuggestions,
  userRank: state.feedbackReducer.usersRank,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getActiveUsers: (teamId: string, memberType: string) =>
    dispatch(getActiveTeamUsers(teamId, memberType)),
  selectUserInReducer: (user: User) => dispatch(selectUser(user)),
  deleteInvite: (data: { inviteId: string; inviteType: string }) =>
    dispatch(deleteInvite(data)),
  removeMember: (data: { teamId: string; userId: string; type: string }) =>
    dispatch(removeMember(data)),
  getPendingInvites: (
    teamId: string,
    memberType:
      | 'Team member'
      | 'Viewing rights'
      | 'Admin rights'
      | 'Team leader'
  ) => dispatch(getPendingInvites(teamId, memberType)),
  getUserSuggestion: (data: { teamId: string; keyword: string }) =>
    dispatch(getUserSuggestion(data)),
  getTeamUserRank: (teamId: string) => dispatch(getTeamUserRanks(teamId)),
  updateJobTitle: (userId: string, jobtitle: string) =>
    dispatch(updateJobTitle(userId, jobtitle)),
  addToTeam: (
    data: {
      teamId: string
      userId: string
      type: UserRights
    },
    user: User
  ) => dispatch(addToTeam(data, user)),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
interface ParamTypes {
  teamId?: string
}
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
  getTeamId(teamId: string): void
}

const GeneralSettings = (props: Props) => {
  const { t } = useTranslation(['teamMembersPage', 'common'])
  const {
    selectedTeam,
    selectedTeamData,
    getActiveUsers,
    getPendingInvites,
    removeMember,
    getTeamUserRank,
    userRank,
    updateJobTitle,
    getTeamId,
    addToTeam,
    deleteInvite
  } = props
  useEffect(() => {
    if (selectedTeam) {
      getActiveUsers(selectedTeam._id, 'viewingRights')
      getPendingInvites(selectedTeam._id, 'Viewing rights')
      setTimeout(() => {
        getActiveUsers(selectedTeam._id, 'admins')
        getPendingInvites(selectedTeam._id, 'Admin rights')
      }, 1000)
    }
  }, [getActiveUsers, getPendingInvites, selectedTeam])


  useEffect(() => {
    if (selectedTeam) {
      getTeamUserRank(selectedTeam._id)
    }
  }, [selectedTeam])


  const [filteredUser, setFilteredUsers] = useState<TeamUserRank[]>([])

  useEffect(() => {
    if (userRank) {
      setFilteredUsers(userRank)
    }
  }, [userRank])

  const [keyword, setKeyword] = useState('')
  const params = useParams<ParamTypes>()
  useEffect(() => {
    if (params.teamId) {
      getTeamId(params.teamId)
    }
  }, [params])

  const [showInvite, setShowInvite] = useState(false)
  const filtered = (key: string) => {
    setKeyword(key)
    const filter =
      userRank?.filter((member) => {
        if (member.user.firstname.toLowerCase().includes(key.toLowerCase())) {
          return true
        }
        if (member.user.lastname.toLowerCase().includes(key.toLowerCase())) {
          return true
        }
        //    if (member.user.email.toLowerCase().includes(keyword.toLowerCase())) {
        //       return true
        //  }
      }) || []
    setFilteredUsers(filter)
  }


  const [selectedUser, selectUser] = useState<User | null>(null)

  const [filterKeyword, setFilterKeyword] = useState<string>('')
  return (
    <div
      className="content-wrapper"
      onClick={() =>
        document.getElementById('mainDrawer')?.classList.remove('open')
      }
    >
      <div className="content">
        <div
          className={
            'content-card ' +
            (userRank && !userRank.length ? ' show-placeholder' : '')
          }
        >
          <div className="fake-card fake-card-team-members-table"></div>
          <div className="content-card-header">
            <div className="content-card-header-caption">
              <p className="content-card-header-title">
                {t('common:Rights')}
              </p>
              <p className="content-card-header-text">{t('teamMembersPage:Userswithviewingrightshaveaccess')}</p>
            </div>
            <div className="content-card-header-bottom">
              <div className="content-card-input-container">
                <input
                  type="text"
                  placeholder={`${t('common:search')}`}
                  onChange={e => setFilterKeyword(e.target.value)}
                />
                <i className="icon-search"></i>
              </div>
              <button
                className="button button-primary modal-trigger"
                target-modal="inviteMembersModal"
                onClick={() =>
                  setShowInvite(true)
                }
              >
                {t('common:Addnewrights')}
              </button>
            </div>
          </div>
          <div className="content-card-main">
            <div className="placeholder">
              <img
                src="/img/feedback-placeholder-img.png"
                alt="Clip art "
                className="placeholder-img"
              />
              <p className="placeholder-title">
                {t('teamMembersPage:placeholder:title')}
              </p>
              <p className="placeholder-text">
                {t('common:click')} <button>{t('common:here')}</button>{' '}
                {t('teamMembersPage:placeholder:text')}
              </p>
            </div>

            <TeamTable
              keyword={filterKeyword}
              deleteInvite={(inviteId, type) => deleteInvite({
                inviteId,
                inviteType: type
              })}
              onSelect={(user) => {
                /* selectUserInReducer(user)
                document
                  .getElementById('userFeedbacksHighOrder')
                  ?.classList.add('open') */
              }}
              addToTeam={(user, type) => {
                if (selectedTeam) {
                  addToTeam({
                    userId: user._id,
                    teamId: selectedTeam._id,
                    type
                  }, user)
                }
              }}
              data={selectedTeamData || undefined}
              removeMember={(userId, type) =>
                removeMember({
                  teamId: selectedTeam ? selectedTeam._id : '',
                  userId: userId,
                  type: type,
                })
              }
              updateJobTitle={(userId: string, jobtitle: string) =>
                updateJobTitle(userId, jobtitle)
              }
            />
          </div>
        </div>
      </div>
      <InviteMembers />
      <GiveFeedbackmodal
        data={selectedUser}
        team={{
          _id: selectedTeam ? selectedTeam._id : '',
          name: selectedTeam ? selectedTeam.name : '',
          category: 'subordinate',
        }}
        clear={() => {
          selectUser(null)
        }}
      />
      <AddNewRight show={showInvite} onClose={() => setShowInvite(false)} />
    </div>
  )
}

export default connector(GeneralSettings)
